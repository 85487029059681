<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="navigation">
      <img class="logo" src="../img/home/logo1.png" alt="" />
      <el-menu
        :default-active="activeIndex2"
        mode="horizontal"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">二手房</el-menu-item>
        <el-menu-item index="3">新房</el-menu-item>
        <el-menu-item index="4">租房</el-menu-item>
        <el-menu-item index="5">海外房产</el-menu-item>
        <el-menu-item index="6">查楼盘</el-menu-item>
        <el-menu-item index="7">我要卖房</el-menu-item>
        <el-menu-item index="8">我要找房</el-menu-item>
        <el-menu-item index="9">企业介绍</el-menu-item>
      </el-menu>
    </div>
    <div class="Box">
      <div class="Header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/OverseasHouse' }"
            >海外房产列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>海外房源详情页</el-breadcrumb-item>
        </el-breadcrumb>
        <div>
          <div
            class="phone"
            @mouseenter="getContactSee(1)"
            @mouseleave="getLeavesSee(0)"
          >
            <img src="../img/phone.png" alt="" />手机看
          </div>
          <div class="seePhone" :class="{ activePhone: phoneindex == 1 }">
            <img :src="houseInfo.wxHomeImg" alt="" />
          </div>
        </div>
      </div>
      <div class="parametersBox">
        <div class="swiperDiv">
          <div class="thumb-example">
            <!-- 大轮播图 -->
            <swiper
              class="swiper gallery-top"
              :options="swiperOptionTop"
              ref="swiperTop"
            >
              <swiper-slide
                class="slide"
                v-for="(item, index) in swiperPictures"
                :key="index"
              >
                <img :src="item" alt="" />
                <!-- 图片放大 -->
                <div class="demo-image__preview">
                  <el-image
                    style="
                      width: %;
                      height: 394px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: ;
                    "
                    :src="item"
                    :preview-src-list="swiperPictures"
                  >
                  </el-image>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-next">
              <i class="left"></i>
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="right"></i>
            </div>
            <div class="swiperBox">
              <div class="btnLeft" @click.stop="lefts"></div>
              <div class="tab_content">
                <div
                  class="tab_item"
                  v-for="(item, index) in swiperList"
                  :key="index"
                  @click.stop="handleChangeTab(index, item)"
                >
                  <div class="imgBox">
                    <img :src="item.imgarr[0]" alt="" />
                  </div>
                  <p style="color: #000">
                    {{ item.name }}({{ item.imgarr.length }})
                  </p>
                </div>
              </div>
              <div class="btnRight" @click.stop="rights"></div>
            </div>
            <!-- 小缩略图 -->
            <!-- <swiper
              class="swiper gallery-thumbs"
              :options="swiperOptionThumbs"
              ref="swiperThumbs"
            >
              <swiper-slide
                class="slide"
                v-for="(item, index) in houseInfo.itemPictures"
                :key="index"
              >
                <img :src="item" alt=""
              /></swiper-slide>
            </swiper> -->
          </div>
        </div>
        <div class="infoBox">
          <p class="name">{{ houseInfo.name }}</p>
          <div class="newHouse">
            <p class="price">
              <span style="font-size: 38px; font-weight: 500; color: #da9525">
                {{ houseInfo.price1 }} - {{ houseInfo.price2 }}万{{
                  houseInfo.priceUnit
                }}<span
                  style="
                    display: inline-block;
                    font-size: 24px;
                    font-weight: 500;
                    color: #da9525;
                  "
                  >起</span
                >
              </span>
              <span
                style="
                  font-size: 20px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.6);
                  line-height: 28px;
                "
              >
                约：{{ houseInfo.minPriceCNY1 }}-{{
                  houseInfo.maxPriceCNY2
                }}万人民币起
              </span>
            </p>
          </div>
          <div class="detailedInfo">
            <div>
              <p>
                <span class="duan"
                  >所在城市: {{ cityName ? cityName : "-" }}</span
                >
              </p>
              <p>
                <span>项目面积：</span>
                <span
                  >{{ houseInfo.minBuildArea }}~{{
                    houseInfo.maxBuildArea
                  }}m²</span
                >
              </p>
              <p>
                <span>交房时间：</span>
                <span>{{
                  houseInfo.endTimes.slice(0, 7)
                    ? houseInfo.endTimes.slice(0, 7)
                    : "-"
                }}</span>
              </p>
              <p class="address">
                开发商: {{ houseInfo.developer ? houseInfo.developer : "-" }}
              </p>
              <div class="itemBox">
                <img class="icon" :src="agentRecommend.icon" alt="" />
                <div class="adescBox">
                  <p class="names">
                    {{ agentRecommend.name
                    }}<span style="color: #e9ac33; margin-left: 16px"
                      >{{ agentRecommend.count }}分</span
                    >
                  </p>
                  <p class="department">
                    {{ agentRecommend.departmentName }}
                  </p>
                  <div class="label">
                    <span class="leftbtn">{{ agentRecommend.job }}</span>
                    <span
                      class="code"
                      @mouseenter="getContacts()"
                      @mouseleave="getLeaves()"
                      >扫码联系
                      <img class="codeImg" src="../img/home/code.png" alt=""
                    /></span>
                  </div>
                  <div v-if="isShowcode" class="contactCodes">
                    <img :src="agentRecommend.wxHomeImg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="title">基础信息</p>
      <div class="propertyBox">
        <div>
          <p>项目名称: {{ houseInfo.name ? houseInfo.name : "-" }}</p>
          <p>开发商: {{ houseInfo.developer ? houseInfo.developer : "-" }}</p>
          <p>
            占地面积:
            {{ houseInfo.coverArea ? houseInfo.coverArea + "m²" : "-" }}
          </p>
          <p>
            建筑面积:
            {{ houseInfo.buildArea ? houseInfo.buildArea + "m²" : "-" }}
          </p>
        </div>
        <div>
          <p>项目业主: {{ houseInfo.owner ? houseInfo.owner : "-" }}</p>
          <p>总栋数: {{ houseInfo.buildingNum }}</p>
          <p>规划户数: {{ houseInfo.planNum ? houseInfo.planNum : "-" }}</p>
        </div>
        <div>
          <p>竣工时间: {{ houseInfo.finishedTimes.slice(0, 7) }}</p>
          <p>用途: {{ houseInfo.purpose ? houseInfo.purpose : "-" }}</p>
          <p>
            产权年限:
            {{ houseInfo.propertyYear ? houseInfo.propertyYear : "-" }}
          </p>
        </div>
        <div>
          <p>
            首付比例:
            {{ houseInfo.paymentRate ? houseInfo.paymentRate + "%" : "-" }}
          </p>
          <p>交付标准: {{ houseInfo.standard1 ? houseInfo.standard1 : "-" }}</p>
          <p>是否贷款: {{ houseInfo.loans ? houseInfo.loans : "-" }}</p>
        </div>
      </div>
      <p class="title">在售户型</p>
      <div v-if="this.tabList.length != 0">
        <p class="roomTypes">
          <span
            v-for="(item, index) in roomList"
            :key="index"
            @click="changeType(index, item.room)"
            :class="isclick == index ? 'isclick' : ''"
            >{{ item.rooms }}({{ item.num }})</span
          >
        </p>
        <div class="tab">
          <div class="btnLeft" @click.stop="left"></div>
          <div class="tab_content">
            <div class="tab_item" v-for="(item, index) in tab" :key="index">
              <div class="imgBox">
                <img :src="item.imgs[0]" alt="" />
                <!-- 图片放大 -->
                <div class="demo-image__preview">
                  <el-image
                    style="
                      width: 100%;
                      height: 196px;
                      position: absolute;
                      top: -10px;
                      left: 0;
                      z-index: 0;
                    "
                    :src="item.imgs[0]"
                    :preview-src-list="arr"
                  >
                  </el-image>
                </div>
              </div>
              <p>{{ item.room }}室{{ item.hall }}厅{{ item.toilet }}卫</p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  color: rgba(0, 0, 0, 0.6);
                "
              >
                {{ item.area }}m²
                <span style="color: #da9525">{{ item.price }}万</span>
              </p>
            </div>
          </div>
          <div class="btnRight" @click.stop="right"></div>
        </div>
      </div>
      <div class="noHouseType" v-else>暂无房型图</div>

      <p class="title" v-if="houseInfo.projectIntroduce">项目介绍</p>
      <div v-if="houseInfo.projectIntroduce" class="descBox">
        {{ houseInfo.projectIntroduce }}
      </div>
      <p class="title" v-if="houseInfo.cityIntroduce">城市介绍</p>
      <div v-if="houseInfo.cityIntroduce" class="descBox">
        {{ houseInfo.cityIntroduce }}
      </div>
      <p class="title">推荐顾问</p>
      <div class="agentBox">
        <div
          class="itemBox"
          v-for="(item, index) in agentRecommends"
          :key="index"
        >
          <img class="icon" :src="item.icon" alt="" />
          <div class="adescBox">
            <p class="name">
              {{ item.name }}
              <span style="color: #e9ac33; margin-left: 16px"
                >{{ item.count }}分</span
              >
            </p>
            <p class="department">{{ item.departmentName }}</p>
            <div class="label">
              <span class="leftbtn">合伙经纪人</span>
              <span
                class="code"
                @mouseenter="getContact(index)"
                @mouseleave="getLeave()"
                >扫码联系
                <img class="codeImg" src="../img/home/code.png" alt=""
              /></span>
            </div>
            <div
              class="contactCode"
              :class="{ activePhone: index == currentIndex }"
            >
              <img :src="item.wxHomeImg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <p class="title">综合概述</p>
      <div class="overviewBox">
        <p>
          <span
            :class="isclicks == index ? 'isclick' : ''"
            @click="changeTypes(index)"
            v-for="(item, index) in overviewList"
            :key="index"
            >{{ item }}</span
          >
        </p>
        <pre class="overviewInfo">{{ overviewInfo }}</pre>
      </div>
      <p class="title">海外新房</p>
      <div class="moreOverHouse">
        <div
          class="itemMhouse"
          v-for="(item, index) in houseInfo.overseaHouseRecommends"
          :key="index"
          @click="getMoreOverseaDetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <div>
            <p class="name">
              <span style="font-size: 18px">{{ item.name }}</span>
              <span
                ><img src="../img/home/place.png" alt="" />{{
                  item.city.name
                }}</span
              >
            </p>
            <p class="area">
              {{ item.minBuildArea }} - {{ item.maxBuildArea }}m²
            </p>
            <p class="price">{{ item.price1 }}万 - {{ item.price2 }}万</p>
          </div>
        </div>
      </div>
      <p class="title">沪上新房</p>
      <div class="moreNewHouse">
        <div
          class="itemMhouse"
          v-for="(item, index) in houseInfo.newHouseRecommends"
          :key="index"
          @click="getNewHouseDetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <div>
            <p class="name">{{ item.name }}</p>
            <p class="area">
              {{ item.minBuildArea }} - {{ item.maxBuildArea }}m²
            </p>
            <p class="price">{{ item.price1 }}万 - {{ item.price2 }}万</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import js from "@/utils/utils";
import Footer from "@/components/Footer.vue";
import { getLoadOverhouse, getOverModelList } from "../api/api";
export default {
  name: "OverSeaHouseDetails",
  components: { Footer },
  data() {
    return {
      loading: true,
      activeIndex2: "0",
      houseInfo: "",
      agentRecommend: "",
      agentRecommends: "",
      cityName: "",
      swiperOptionTop: {
        loop: false,
        loopedSlides: 0,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 0,
        spaceBetween: 10,
        // centeredSlides: true,
        slidesPerView: "auto",
        // touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      swiperPictures: [],
      swiperList: [],
      swiper: [],
      numIndexs: 3,
      numIndex: 3,
      tabList: [],
      tab: [],
      roomList: [],
      isclick: 0,
      overviewList: ["楼盘卖点", "周边介绍", "置业理由", "教育资源"],
      isclicks: 0,
      overviewInfo: "",
      currentIndex: -1,
      isShowcode: false,
      arr: [],
      phoneindex: -1,
    };
  },
  mounted() {
    this.getOverseaDetails();
    this.agentList = JSON.parse(localStorage.getItem("agentList"));
  },
  methods: {
    getContact(index) {
      this.currentIndex = index;
    },
    getLeave() {
      this.currentIndex = -1;
    },
    getContacts() {
      this.isShowcode = true;
    },
    getLeaves() {
      this.isShowcode = false;
    },
    getContactSee() {
      this.phoneindex = 1;
    },
    getLeavesSee() {
      this.phoneindex = 0;
    },
    // 跳转新房详情页
    getNewHouseDetails(id) {
      localStorage.setItem("ID", id);
      this.$router.push({ name: "NewHouseDetails", params: { id } });
    },
    //左箭头按钮
    lefts() {
      if (this.numIndexs > 3) {
        if (this.swiper[0].id == this.swiperList[0].id) {
          this.swiperList.unshift(this.swiper[this.numIndexs - 4]); //前面添加一个
          this.swiperList.pop(this.swiperList[this.numIndexs]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
          this.numIndexs--;
        } else {
          return;
        }
      }
    },
    //右箭头按钮
    rights() {
      if (
        this.swiper[this.swiper.length - 1] ==
        this.swiperList[this.swiperList.length - 1]
      ) {
        return;
      } else {
        this.swiperList.shift(this.swiperList[this.numIndexs - 3]); //前面删除一个
        this.swiperList.push(this.swiper[this.numIndexs + 1]); //后面添加一个
        this.numIndexs++;
      }
    },
    handleChangeTab(index, item) {
      this.swiperPictures = item.imgarr;
    },
    handleGetTabList() {
      this.tabList = this.houseInfo.models;
      if (Array.isArray(this.tabList) && this.tabList.length > 4) {
        this.tab = this.tabList.slice(0, 4);
      } else {
        this.tab = this.tabList;
      }
      this.tab.forEach((item, index) => {
        this.arr.push(item.imgs[0]);
      });
    },
    //左箭头按钮
    left() {
      if (this.numIndex > 3) {
        if (this.tabList[0].id == this.tab[0].id) {
          return;
        } else {
          this.tab.unshift(this.tabList[this.numIndex - 4]); //前面添加一个
          this.tab.pop(this.tab[this.numIndex]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
          this.numIndex--;
        }
      }
    },
    //右箭头按钮
    right() {
      if (
        this.tabList[this.tabList.length - 1].id ==
        this.tab[this.tab.length - 1].id
      ) {
        return;
      } else {
        this.tab.shift(this.tab[this.numIndex - 3]); //前面删除一个
        this.tab.push(this.tabList[this.numIndex + 1]); //后面添加一个
        this.numIndex++;
      }
    },
    changeType(index, room) {
      this.isclick = index;
      getOverModelList({
        ohid: this.houseInfo.id,
        room: room.slice(0, room.length - 1),
      }).then((res) => {
        // console.log(res);
        this.tabList = res;
        if (Array.isArray(this.tabList) && this.tabList.length > 4) {
          this.tab = this.tabList.slice(0, 4);
        } else {
          this.tab = this.tabList;
        }
      });
    },
    changeTypes(index) {
      this.isclicks = index;
      if (index == 0) {
        this.overviewInfo = this.houseInfo.sellPoint;
      } else if (index == 1) {
        this.overviewInfo = this.houseInfo.roundIntroduct;
      } else if (index == 2) {
        this.overviewInfo = this.houseInfo.buyReason;
      } else {
        this.overviewInfo = this.houseInfo.educational;
      }
    },
    getMoreOverseaDetails(id) {
      localStorage.setItem("ID", id);
      this.getOverseaDetails();
    },
    getOverseaDetails() {
      document.documentElement.scrollTop = 0;
      getLoadOverhouse({ id: localStorage.getItem("ID") }).then((res) => {
        console.log(res);
        this.houseInfo = res.data;
        this.overviewInfo = this.houseInfo.sellPoint;
        res.data.roomList.forEach((item) => {
          if (item.room == "1房") {
            item.rooms = "一居室";
          } else if (item.room == "2房") {
            item.rooms = "二居室";
          } else if (item.room == "3房") {
            item.rooms = "三居室";
          } else if (item.room == "4房") {
            item.rooms = "四居室";
          } else if (item.room == "5房") {
            item.rooms = "五居室";
          } else if (item.room == "6房") {
            item.rooms = "六居室";
          } else if (item.room == "7房") {
            item.rooms = "七居室";
          } else if (item.room == "8房") {
            item.rooms = "八居室";
          } else if (item.room == "9房") {
            item.rooms = "九居室";
          } else if (item.room == "10房") {
            item.rooms = "十居室";
          } else {
            item.rooms = "更多居室";
          }
        });
        this.roomList = res.data.roomList;
        this.roomList.unshift({
          num: res.data.models.length,
          rooms: "全部户型",
          room: "",
        });
        let swipers = [
          { name: "效果图", imgarr: res.data.floorPlans },
          { name: "实景图", imgarr: res.data.realPictures },
          { name: "样板间", imgarr: res.data.exampleRooms },
          { name: "小区配套", imgarr: res.data.communitySet },
        ];
        var length = "";
        swipers.forEach((item) => {
          if (item.imgarr.length != 0) {
            length = Number(item.imgarr.length) + 1;
            this.swiper.push(item);
            this.swiperPictures = this.swiper[0].imgarr;
          }
        });
        if (Number(length) <= 1) {
          this.swiperPictures.push(this.houseInfo.cover);
        }
        if (Array.isArray(this.swiper) && this.swiper.length > 4) {
          this.swiperList = this.swiper.slice(0, 4);
        } else {
          this.swiperList = this.swiper;
        }
        this.agentRecommends = res.data.agentRecommends;
        this.agentRecommend = res.data.agentRecommend;
        this.cityName = res.data.city.name;
        res.data.finishedTimes = js.formatDateTime(res.data.finishedTime);
        res.data.endTimes = js.formatDateTime(res.data.endTime);
        res.data.price1 = js.formatNumber(res.data.minPrice);
        res.data.price2 = js.formatNumber(res.data.maxPrice);
        res.data.minPriceCNY1 = js.formatNumber(res.data.minPriceCNY);
        res.data.maxPriceCNY2 = js.formatNumber(res.data.maxPriceCNY);
        res.data.standard == 1
          ? (res.data.standard1 = "毛坯")
          : (res.data.standard1 = "带装修");
        // loan 1是 2否
        switch (res.data.loan) {
          case 1:
            res.data.loans = "是";
            break;
          case 2:
            res.data.loans = "否";
            break;
          case 0:
            res.data.loans = "";
            break;
        }
        // 更多新房
        res.data.newHouseRecommends.forEach((item) => {
          item.price1 = js.formatNumber(item.minPrice);
          item.price2 = js.formatNumber(item.maxPrice);
        });
        // 海外新房
        res.data.overseaHouseRecommends.forEach((item) => {
          item.price1 = js.formatNumber(item.minPriceCNY);
          item.price2 = js.formatNumber(item.maxPriceCNY);
        });
        // this.swiperOptionTop.loopedSlides = res.data.itemPictures.length;
        // this.swiperOptionThumbs.loopedSlides = res.data.itemPictures.length;
        this.handleGetTabList();
        this.loading = false;
      });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
  },
};
</script>
<style lang="less" scoped>
i {
  /* 用border值来控制箭头粗细 */
  border: 5px solid #fff;
  /* 上、右、下、左  四个边框的宽度 */
  border-width: 0px 1px 1px 0px;
  display: inline-block;
  /* padding值控制箭头大小 */
  padding: 4px;
}
// 右箭头
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
// 左箭头
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.navigation {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: #3d3333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.Box {
  padding: 30px 160px;
  box-sizing: border-box;
  background-color: #fff;
  .Header {
    display: flex;
    justify-content: space-between;
    position: relative;
    .phone {
      cursor: pointer;
      padding: 0 13px;
      line-height: 32px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #da9525;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #da9525;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }
    .seePhone {
      border-radius: 1px;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #ccc;
      display: none;
      position: absolute;
      right: -45px;
      top: 40px;
      img {
        width: 130px;
        height: 130px;
      }
    }
    .activePhone {
      display: block;
    }
  }
}
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 16px;
}
.parametersBox {
  display: flex;
  padding-top: 32px;
  box-sizing: border-box;
  width: 100%;
  .swiperDiv {
    width: 55%;
    position: relative;
    margin-right: 24px;
    .swiperBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;
      .btnLeft {
        width: 8px;
        height: 8px;
        border-width: 1px;
        border-color: #3d3d3d #3d3d3d transparent transparent;
        border-style: solid;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-top: 40px;
        margin-right: 20px;
        cursor: pointer;
      }
      .btnRight {
        width: 8px;
        height: 8px;
        border-width: 1px;
        border-color: #3d3d3d #3d3d3d transparent transparent;
        border-style: solid;
        transform: matrix(0.75, 0.75, -0.75, 0.75, 0, 0);
        margin-top: 40px;
        margin-left: 20px;
        cursor: pointer;
      }
      .tab_content {
        width: 100%;
        font-size: 12px;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: flex-start;
        flex-wrap: no-wrap;
        overflow: hidden;
        .tab_item {
          width: 23%;
          font-size: 16px;
          text-align: center;
          margin-right: 8px;
          cursor: pointer;
          .imgBox {
            position: relative;
            text-align: center;
            margin-bottom: 8px;
            img {
              width: 100%;
              height: 96px;
              object-fit: cover;
            }
          }
          p {
            line-height: 25px;
            margin-left: 12px;
            font-weight: 500;
          }
        }
      }
    }
    .swiper-button-prev {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
      cursor: pointer;
    }
    .swiper-button-next {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
      cursor: pointer;
    }
    .thumb-example {
      width: 100%;
      height: 580px;
      background-color: transparent;
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
      img {
        width: 100%;
        height: 394px;
        margin-bottom: 10px;
      }
    }
    .gallery-top {
      width: 100%;
    }
    .gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    .gallery-thumbs .swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.4;
      img {
        width: 150px;
        height: 100%;
      }
    }
    .gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }
  .infoBox {
    width: 50%;
    .newHouse {
      display: flex;
      justify-content: space-between;
      p {
        span {
          display: block;
        }
      }
    }
    .name {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 32px;
    }
    .price {
      font-size: 38px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 500;
      color: #da9525;
      margin-bottom: 28px;
    }
    .area {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 20px;
    }
    .detailedInfo {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 20px;
      box-sizing: border-box;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 32px;
      }
      .itemBox {
        width: 360px;
        height: 148px;
        display: flex;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        position: relative;
        .icon {
          width: 130px;
          height: 130px;
          margin-right: 16px;
        }
        .adescBox {
          .name {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            padding-bottom: 16px;
            span {
              font-weight: 400;
              color: #e9ac33;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
            }
          }
          .department {
            padding-bottom: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .label {
            display: flex;
            align-items: center;
            .leftbtn {
              display: inline-block;
              height: 32px;
              line-height: 32px;
              background: rgb(245, 226, 189);
              border-radius: 2px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e9ac33;
              padding-left: 7px;
              padding-right: 7px;
              box-sizing: border-box;
              margin-right: 10px;
            }
            .code {
              display: inline-block;
              width: 88px;
              height: 32px;
              line-height: 32px;
              background: #f5f7fa;
              border-radius: 2px;
              border: 1px solid #979797;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              cursor: pointer;
              .codeImg {
                width: 16px;
                height: 16px;
                margin-left: 5px;
              }
            }
          }
          .contactCodes {
            border-radius: 1px;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid #ccc;
            position: absolute;
            right: 5px;
            top: -50px;
            .el-icon-close {
              float: right;
            }
            img {
              width: 130px;
              height: 130px;
            }
          }
        }
      }
    }
    .address {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 40px;
      padding-bottom: 10px;
    }
  }
}
.propertyBox {
  width: 100%;
  height: 176px;
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
  padding-left: 24px;
  box-sizing: border-box;
  margin-bottom: 56px;
  border-radius: 3px;
  p {
    line-height: 32px;
  }
}
.roomTypes {
  padding-left: 40px;
  padding-bottom: 25px;
  padding-top: 10px;
  box-sizing: border-box;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  span {
    margin-right: 56px;
    cursor: pointer;
  }
  .isclick {
    color: #da9525;
    font-weight: 500;
  }
}
.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  .btnLeft {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #000 #000 transparent transparent;
    border-style: solid;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-right: 26px;
    cursor: pointer;
  }
  .btnRight {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #000 #000 transparent transparent;
    border-style: solid;
    transform: matrix(0.75, 0.75, -0.75, 0.75, 0, 0);
    cursor: pointer;
  }
  .tab_content {
    width: 100%;
    font-size: 12px;
    color: rgb(255, 255, 255);
    display: flex;
    flex-wrap: no-wrap;
    overflow: hidden;
    .tab_item {
      width: 22%;
      height: 285px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 26px;
      font-size: 16px;
      padding-top: 16px;
      box-sizing: border-box;
      .imgBox {
        text-align: center;
        height: 196px;
        line-height: 196px;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 8px;
        position: relative;
        img {
          width: 100%;
          height: 165px;
          object-fit: cover;
        }
      }
      p {
        line-height: 25px;
        margin-left: 12px;
        margin-right: 12px;
        font-weight: 500;
      }
    }
  }
}
.agentBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 56px;
  box-sizing: border-box;
  .itemBox {
    display: flex;
    width: 360px;
    height: 146px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .adescBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
          color: #e9ac33;
          margin-right: 10px;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          line-height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          cursor: pointer;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
.descBox {
  width: 100%;
  padding: 22px 24px;
  box-sizing: border-box;
  background-color: #f9fafe;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 30px;
  margin-bottom: 56px;
  white-space: pre-wrap;
}
.moreNewHouse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  .itemMhouse {
    width: 30%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    img {
      width: 100%;
      height: 213px;
      margin-bottom: 12px;
    }
    div {
      padding-left: 16px;
      box-sizing: border-box;
      .name {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
      .area {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 10px;
      }
      .price {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #da9525;
        padding-bottom: 15px;
      }
    }
  }
}
.overviewBox {
  p {
    margin-bottom: 16px;
    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      padding-right: 56px;
      cursor: pointer;
    }
    .isclick {
      color: #da9525;
      font-weight: 600;
    }
  }
}
.overviewInfo {
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 26px;
  margin-bottom: 32px;
}
.noHouseType {
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.moreOverHouse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  .itemMhouse {
    width: 30%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    img {
      width: 100%;
      height: 213px;
      margin-bottom: 12px;
    }
    div {
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      .name {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: space-between;
        img {
          width: 13px;
          height: 13px;
          margin-right: 3px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .area {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 10px;
      }
      .price {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #da9525;
        padding-bottom: 15px;
      }
    }
  }
}
</style>
