import { getAreaInfo } from "../api/api"; //获取区域公共方法
export default {
    // 房源现状
    getActuality(actua) {
      var actuality = ''
      switch(actua) {
        case 1:
          actuality = '业主自住'
          break;
        case 2:
          actuality = '空置'
          break;
        case 3:
          actuality = '客租住'
          break;
        case 4:
          actuality = '已售出'
          break;
        case 5,6: 
          actuality = '其他'
          break
      }
      return actuality
    },
    //时间戳
    formatDateTime(timeStamp) {
      var date = new Date();
      date.setTime(timeStamp);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      let time = y + '-' + m + '-' + d;
      return time
    },
    // 获取方向
    getDirection(actuality) {
      var direction = ''
      switch(actuality) {
        case 1:
          direction = '东'
          break;
        case 2:
          direction = '南'
          break;
        case 3:
          direction = '西'
          break;
        case 4:
          direction = '北'
          break;
        case 5: 
          direction = '东南'
          break
        case 6:
          direction = '东北'
          break;
        case 7:
          direction = '西南'
          break;
        case 8:
          direction = '西北'
          break;
        case 9: 
          direction = '南北'
          break;
        case 10: 
          direction = '东西'
          break;
      }
      return direction
    },
    // 装修类型
    getDecorate(decorates) {
        var decorate = ''
        switch(decorates) {
          case 1:
          decorate = '毛坯'
            break;
          case 2:
            decorate = '简装'
            break;
          case 4:
            decorate = '精装'
            break;
          case 6:
            decorate = '豪装'
            break;
        }
        return decorate
    },
    // 价格转换万元
    formatNumber(num) {
      num = Number(num);
      if (num == 0) {
        return num + "";
      } else {
        if ((num / 10000).toFixed(2) == 0) {
          //小于100的保留4位
          // return (num / 10000).toFixed(4);
          return parseInt(num / 10000)
        } else {
        //大于100的保留2位
          // return (num / 10000).toFixed(2);
          return parseInt(num / 10000);
        }
      }
    },
    // 售价查询
    getPrice(item) {
            let minPrice=''
            let maxPrice=''
            if (item == "1000万以下") {
              minPrice = 0;
              maxPrice = 1000;
            } else if (item == "1000~2000万") {
              minPrice = 1000;
              maxPrice = 2000;
            } else if (item == "2000~3000万") {
              minPrice = 2000;
              maxPrice = 3000;
            } else if (item == "3000~5000万") {
              minPrice = 3000;
              maxPrice = 5000;
            } else if (item == "5000~8000万") {
              minPrice = 5000;
              maxPrice = 8000;
            } else if (item == "8000万以上") {
              minPrice = 8000;
              maxPrice = 0;
            } else {
              minPrice = "";
              maxPrice = "";
            }
            return minPrice, maxPrice
          },
    // 房型选择
    getRoom(item) {
        let room = ''
        if (item == "一室") {
          room = 1;
        } else if (item == "二室") {
          room = 2;
        } else if (item == "三室") {
          room = 3;
        } else if (item == "四室") {
          room = 4;
        } else if (item == "五室以上") {
          room = 5;
        } else {
          room = "";
        }
        return room
      },
     // 面积查询
    //  getArea(item) {
    //     console.log(item);
    //     if (item == "100m²以下") {
    //       this.minArea = 0;
    //       this.maxArea = 100;
    //     } else if (item == "100~150m²") {
    //       this.minArea = 100;
    //       this.maxArea = 150;
    //     } else if (item == "150~200m²") {
    //       this.minArea = 150;
    //       this.maxArea = 200;
    //     } else if (item == "200~250m²") {
    //       this.minArea = 200;
    //       this.maxArea = 250;
    //     } else if (item == "250~300m²") {
    //       this.minArea = 250;
    //       this.maxArea = 300;
    //     } else if (item == "300m²") {
    //       this.minArea = 300;
    //       this.maxArea = 0;
    //     } else {
    //       this.minArea = "";
    //       this.maxArea = "";
    //     }
    //   },
    // 区域查询
    getAreaInfo(type) {
        var arr = []
        getAreaInfo({ type: type }).then((res) => {
        });
    },
    // 数字过万的处理
    formartNum (val) {
        let num = 0
        if (val > 9999) {
            num = Math.round(val / 10000 * 10) / 10 + '万'
        } else {
            num = val
        }

        return num
    },
    // 时间毫秒格式化处理 2020-10-30 09:30:00
    formartDate (originVal, fmt) {
        const dt = new Date(originVal)
        const opt = {
            yyyy: dt.getFullYear(),
            MM: (dt.getMonth() + 1 + '').padStart(2, '0'),
            dd: (dt.getDate() + '').padStart(2, '0'),
            HH: (dt.getHours() + '').padStart(2, '0'),
            mm: (dt.getMinutes() + '').padStart(2, '0'),
            ss: (dt.getSeconds() + '').padStart(2, '0')
        }

        for (const k in opt) {
            const ret = new RegExp('(' + k + ')').exec(fmt)
            if (ret) {
                fmt = fmt.replace(ret[1], opt[k])
            }
        }

        return fmt
    },
    // 歌曲毫秒格式化处理 03:30
    formatSongTime (duration = 0) {
        duration = duration / 1000
        const m = (Math.floor(duration / 60) + '').padStart(2, '0')
        const s = (Math.floor(duration % 60) + '').padStart(2, '0')
        return `${m}:${s}`
    },
    // 评论时间格式化处理
    formatMsgTime (duration) {
        let result = ''
        const NOW = new Date()
        const PAST = new Date(duration)

        // 判断是当天的时间 显示格式 10：30
        if (NOW.toDateString() === PAST.toDateString()) {
            result = this.formartDate(duration, 'HH:mm')
        // 时间为当年 显示月日 时间戳
        } else if (PAST.getFullYear() === NOW.getFullYear()) {
            result = this.formartDate(duration, 'MM月dd日 HH:mm')
        } else {
            result = this.formartDate(duration, 'yyyy年MM月dd日')
        }

        return result
    },
    // 添加歌曲到播放列表，过滤重复的歌曲
    concatPlayList (newList = [], oldList = []) {
        const arr = [...oldList, ...newList]
        const map = new Map()

        for (const item of arr) {
            if (!map.has(item.id)) {
                map.set(item.id, item)
            }
        }

        return [...map.values()]
    }
}
