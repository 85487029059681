<template>
  <div
    class="box"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="navigation">
      <img class="logo" src="../img/home/logo1.png" alt="" />
      <el-menu
        :default-active="activeIndex2"
        mode="horizontal"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">二手房</el-menu-item>
        <el-menu-item index="3">新房</el-menu-item>
        <el-menu-item index="4">租房</el-menu-item>
        <el-menu-item index="5">海外房产</el-menu-item>
        <el-menu-item index="6">查楼盘</el-menu-item>
        <el-menu-item index="7">我要卖房</el-menu-item>
        <el-menu-item index="8">我要找房</el-menu-item>
        <el-menu-item index="9">企业介绍</el-menu-item>
      </el-menu>
    </div>
    <div class="containbox">
      <div class="areaSearch">
        <div class="searchbox">
          <input
            class="searchinput"
            v-model="word"
            @keyup.enter="getSearchItems"
            @input="getSearchItems"
          />
          <span class="searchbtn" @click="getSearchItems()">搜索</span>
          <el-card v-if="searchItems.length" class="box-card">
            <div
              v-for="item in searchItems"
              :key="item"
              @click="clickItem(item)"
              class="searchitem"
            >
              {{ item }}
            </div>
          </el-card>
        </div>
        <div>
          <div class="row">
            <div class="name">区域：</div>
            <div class="content">
              <div
                v-for="(item, index) in PlatformList"
                :key="index"
                @click="getPlace(item)"
                :class="['item', PlatformVar == item.name ? 'actvCss' : '']"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="name">均价：</div>
            <div class="content">
              <div
                v-for="(item, index) in SellingPrice"
                :key="index"
                @click="getPrice(item)"
                :class="['item', SellingPriceVal == item ? 'actvCss' : '']"
              >
                {{ item }}
              </div>
              <el-form style="display: flex; align-items: center" size="mini">
                <el-form-item style="color: #ccc">
                  <el-input v-model="minPrices" width="50px"></el-input
                  ><span style="#666;margin-left: 5px;">万</span>
                </el-form-item>
                <span
                  class="line"
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 2px;
                    background: #ccc;
                    margin-left: 5px;
                    margin-right: 5px;
                  "
                ></span>
                <el-form-item>
                  <el-input v-model.lazy="maxPrices"></el-input
                  ><span style="#666;margin-left: 5px;">万</span>
                </el-form-item>
                <span
                  style="
                    display: inline-block;
                    margin-left: 10px;
                    width: 48px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    background: #e9c562;
                    border-radius: 2px;
                    color: #fff;
                    font-size: 14px;
                    cursor: pointer;
                  "
                  @click="getWritePrice"
                  >确定</span
                >
              </el-form>
              <!-- v-for和v--if 同时使用 -->
              <!-- <template v-for="item in DataList">
                <li v-if="DataList.length >= 10">
                  {{ item.name }}
                </li>
              </template> -->
            </div>
          </div>
        </div>
      </div>
      <div class="dataListBox" v-if="DataList">
        <div
          class="list"
          v-for="(item, index) in DataList"
          :key="index"
          @click="getCommunityDetails(item.id)"
        >
          <img class="imgleft" :src="item.cover" alt="" />
          <div style="width: 70%">
            <p class="title">{{ item.name }}</p>
            <div class="areabox">
              <img
                style="width: 16px; height: 16px"
                src="../img/home/house.png"
                alt=""
              />&nbsp; <span>上月网签套数:{{ item.lastMonthDeal }}套</span>
            </div>
            <div class="dressbox">
              <div style="width: 70%; display: flex; align-items: center">
                <img
                  style="width: 16px; height: 16px"
                  src="../img/home/place.png"
                  alt=""
                />&nbsp;
                <div>
                  <span>{{ item.districtName }}</span>
                  <span v-if="item.businessArea"
                    >·{{ item.businessArea.name }}</span
                  >
                </div>
              </div>
              <div class="prices">
                <p>挂牌均价</p>
                <span v-if="item.averagePrice"
                  >{{ item.averagePrice }}元/m²</span
                >
                <span v-else>暂无数据</span>
              </div>
            </div>
            <div>
              <span class="insell">在售:{{ item.sellNum }}套</span>
              <span class="inrent">在租:{{ item.rentNum }}套</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="nullCss">暂无相关楼盘~</div>
      <scrollTop></scrollTop>
      <el-pagination
        style="float: right"
        v-if="DataList.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="20"
        layout=" prev, pager, next"
        :total="total"
        background
      >
      </el-pagination>
      <div style="clear: both"></div>
      <div class="agentBox">
        <div class="itemBox" v-for="(item, index) in agentList" :key="index">
          <img class="icon" :src="item.icon" alt="" />
          <div class="descBox">
            <p class="name">
              {{ item.name }} <span>{{ item.count }}分</span>
            </p>
            <p class="department">{{ item.departmentName }}</p>
            <div class="label">
              <span class="leftbtn">{{ item.job }}</span>
              <span
                class="code"
                @mouseenter="getContact(index)"
                @mouseleave="getLeave()"
                >扫码联系
                <img class="codeImg" src="../img/home/code.png" alt=""
              /></span>
            </div>
            <div
              class="contactCode"
              :class="{ activePhone: index == currentIndex }"
            >
              <img :src="item.wxHomeImg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import SelectType from "@/components/SelectType.vue";
import scrollTop from "@/components/Scrolltop.vue";
import js from "@/utils/utils";
import pagination from "@/components/Pagination.vue";
import Footer from "@/components/Footer.vue";
import {
  getAreaInfo,
  getsearchThinkItems,
  getCommunityPager,
} from "../api/api";
export default {
  name: "SearchFloortray",
  components: { SelectType, pagination, Footer, scrollTop },
  data() {
    return {
      activeIndex2: "6",
      word: "",
      searchItems: [],
      regionId: 0,
      minPrice: "",
      maxPrice: "",
      minPrices: "",
      maxPrices: "",
      PlatformVar: "不限", // 表示当前选中的区域
      SellingPriceVal: "不限", // 表示当前选中的价格
      searchWord: "",
      PlatformList: [], // 区域
      SellingPrice: [
        "不限",
        "5万以下",
        "5万~8万",
        "8万~10万",
        "10~13万",
        "13万~15万",
        "15万~18万",
        "18万以上",
      ], // 售价
      DataList: [], //列表数据
      loading: true,
      total: 0, //总条目数
      currentPage: 1, //当前页数
      pageNumber: 1, // 当前页 默认设值1
      pageSize: 20, // 每页显示条目 默认设置5
      dynamic: 0,
      agentList: "",
      isShow: true,
      isShowCard: true,
      ruleForm: {
        name: "",
        sex: "",
        phone: "",
        code: "",
        type: "",
        beat: "",
        village: "",
        area: "",
        apartment: "",
        price: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        code: [{ required: true, message: "请输入校验码", trigger: "blur" }],
        type: [
          { required: true, message: "请选择您的需求类型", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请输入您的意向面积", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入您的预算价格", trigger: "blur" },
        ],
      },
      show: true,
      timer: null,
      count: "",
      currentIndex: -1,
    };
  },
  mounted() {
    this.getAreaInfo();
    this.getSellPager(1);
    this.agentList = JSON.parse(localStorage.getItem("agentList"));
  },
  methods: {
    getContact(index) {
      this.currentIndex = index;
    },
    getLeave() {
      this.currentIndex = -1;
    },
    // 区域查询
    getPlace(item) {
      this.regionId = item.id;
      this.PlatformVar = item.name;
      this.getSellPager(1);
    },
    getWritePrice() {
      this.loading = true;
      this.minPrice = this.minPrices;
      this.maxPrice = this.maxPrices;
      this.SellingPriceVal = "";
      this.getSellPager(1);
    },
    // 售价查询
    getPrice(item) {
      if (item == "5万以下") {
        this.minPrice = 0;
        this.maxPrice = 5;
      } else if (item == "5万~8万") {
        this.minPrice = 5;
        this.maxPrice = 8;
      } else if (item == "8万~10万") {
        this.minPrice = 8;
        this.maxPrice = 10;
      } else if (item == "10万~13万") {
        this.minPrice = 10;
        this.maxPrice = 13;
      } else if (item == "13万~15万") {
        this.minPrice = 13;
        this.maxPrice = 15;
      } else if (item == "15万~18万") {
        this.minPrice = 15;
        this.maxPrice = 18;
      } else if (item == "18万以上") {
        this.minPrice = 18;
        this.maxPrice = 0;
      } else {
        this.minPrice = "";
        this.maxPrice = "";
      }
      this.SellingPriceVal = item;
      this.minPrices = "";
      this.maxPrices = "";
      this.getSellPager(1);
    },

    // 区域获取
    getAreaInfo() {
      getAreaInfo({ type: 5 }).then((res) => {
        this.PlatformList = res.data;
      });
    },
    // 模糊搜索
    getSearchItems(e) {
      if (this.word == "") {
        this.searchItems = [];
        this.getSellPager(1);
      } else {
        getsearchThinkItems({ name: this.word }).then((res) => {
          this.searchItems = res.data;
          if (res.data.length == 0) {
            this.getSellPager(1);
          }
        });
      }
    },
    clickItem(item) {
      this.word = item;
      this.searchItems = [];
      this.getSellPager(1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSellPager(this.pageNumber);
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getSellPager(this.pageNumber);
    },
    getSellPager(pageNumber) {
      getCommunityPager({
        searchWord: this.word,
        pageNumber: pageNumber,
        regionId: this.regionId,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      }).then((res) => {
        // console.log(res);
        this.loading = false;
        this.DataList = res.data.data;
        this.total = res.data.totalData;
      });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
    // 跳转小区详情页
    getCommunityDetails(id) {
      localStorage.setItem("ID", id);
      this.$router.push({ name: "CommunityDetails", params: { id } });
    },
  },
  destroyed() {
    window.onscroll = null;
  },
};
</script>

<style lang="less" scoped>
.box {
  box-sizing: border-box;
}
.navigation {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #3d3333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.containbox {
  padding: 0 11%;
  box-sizing: border-box;
  .areaSearch {
    width: 100%;
    background: #fbfbfb;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    padding: 25px 55px;
    box-sizing: border-box;
    margin-top: 50px;
  }
  .dataListBox {
    padding-bottom: 15px;
    .list {
      display: flex;
      padding: 32px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      .imgleft {
        width: 241px;
        height: 176px;
        margin-right: 24px;
        cursor: pointer;
      }
      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 550;
        color: #000000;
        padding-bottom: 16px;
      }
      .areabox {
        display: flex;
        align-items: center;
        box-sizing: border-box;
      }
      .dressbox {
        display: flex;
        align-items: center;
        padding-bottom: 35px;
        box-sizing: border-box;
      }
      .prices {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #e49700;
        span {
          font-size: 24px;
          color: #e49700;
          font-weight: 500;
        }
      }
      .insell {
        display: inline-block;
        width: 96px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #fce2de;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff2700;
        margin-right: 16px;
      }
      .inrent {
        display: inline-block;
        width: 96px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #dae5fa;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0052d9;
      }
    }
  }
}
.searchbox {
  float: right;
  width: 560px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  .searchinput {
    outline: none;
    border: none;
    width: 465px;
    height: 38px;
    margin-right: 10px;
    padding-left: 5px;
    box-sizing: border-box;
  }
  .searchbtn {
    display: inline-block;
    width: 82px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    background: #e9c562;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
  }
  .el-card {
    width: 559px;
    position: absolute;
    top: 40px;
    z-index: 99;
    .searchitem {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}
.row {
  height: 100%;
  padding: 10px;
}
.row .content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
}
.row .name {
  font-size: 14px;
  font-weight: 600;
}
.row .content .item {
  background: none;
  font-size: 14px;
}
.row .content .actvCss {
  background: none;
  color: #dbb03a;
  font-weight: 600;
  font-size: 14px;
}
.el-input {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.el-form-item--mini.el-form-item {
  margin-bottom: 0;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #e9ac33;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e9ac33;
  color: #fff;
}
.agentBox {
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 56px;
  box-sizing: border-box;
  .itemBox {
    width: 360px;
    height: 146px;
    display: flex;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #f9fafe;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .descBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e9ac33;
          margin-right: 10px;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
</style>
