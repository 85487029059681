<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="navigation">
      <img class="logo" src="../img/home/logo1.png" alt="" />
      <el-menu
        :default-active="activeIndex2"
        mode="horizontal"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">二手房</el-menu-item>
        <el-menu-item index="3">新房</el-menu-item>
        <el-menu-item index="4">租房</el-menu-item>
        <el-menu-item index="5">海外房产</el-menu-item>
        <el-menu-item index="6">查楼盘</el-menu-item>
        <el-menu-item index="7">我要卖房</el-menu-item>
        <el-menu-item index="8">我要找房</el-menu-item>
        <el-menu-item index="9">企业介绍</el-menu-item>
      </el-menu>
    </div>
    <div class="Box">
      <div class="Header">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Home' }"
              >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/RentHouse' }"
              >租房列表</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/RentHouse' }"
              >租房详情</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div>
          <div
            class="phone"
            @mouseenter="getContactSee(1)"
            @mouseleave="getLeavesSee(0)"
          >
            <img src="../img/phone.png" alt="" />手机看
          </div>
          <div class="seePhone" :class="{ activePhone: phoneindex == 1 }">
            <img :src="houseInfo.wxHomeImg" alt="" />
          </div>
        </div>
      </div>
      <div class="parametersBox">
        <div class="swiperDiv">
          <div class="thumb-example">
            <!-- 大轮播图 -->
            <swiper
              class="swiper gallery-top"
              :options="swiperOptionTop"
              ref="swiperTop"
            >
              <swiper-slide
                class="slide"
                v-for="(item, index) in swiperPictures"
                :key="index"
              >
                <img :src="item" alt="" />
                <!-- 图片放大 -->
                <div class="demo-image__preview">
                  <el-image
                    style="
                      width: 100%;
                      height: 394px;
                      position: absolute;
                      top: 0;
                      left: 0;
                    "
                    :src="item"
                    :preview-src-list="swiperPictures"
                  >
                  </el-image>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-next">
              <i class="left"></i>
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="right"></i>
            </div>
            <!-- <div
              class="swiperBox"
            > -->
            <div
              class="swiperBox"
              v-if="
                swiperPictures[0] !=
                'https://bthz.oss-cn-beijing.aliyuncs.com/houseIconDefault.png?versionId=CAEQIxiBgMCF6tKe6BciIDQ4Mjg0OTAyNzRkNTQwMTJiNTkxNjkxOTI0MzU2MDJm'
              "
            >
              <div class="btnLeft" @click.stop="lefts"></div>
              <div class="tab_content">
                <div
                  class="tab_item"
                  v-for="(item, index) in swiperList"
                  :key="index"
                  @click.stop="handleChangeTab(index, item)"
                >
                  <div class="imgBox">
                    <img :src="item.imgarr[0]" alt="" />
                  </div>
                  <p style="color: #000">
                    {{ item.name }}({{ item.imgarr.length }})
                  </p>
                </div>
              </div>
              <div class="btnRight" @click.stop="rights"></div>
            </div>

            <!-- 小缩略图 -->
            <!-- <swiper
              class="swiper gallery-thumbs"
              :options="swiperOptionThumbs"
              ref="swiperThumbs"
            >
              <swiper-slide
                class="slide"
                v-for="(item, index) in houseInfo.hallPictures"
                :key="index"
              >
                <img :src="item" alt=""
              /></swiper-slide>
            </swiper> -->
          </div>
        </div>
        <div class="infoBox">
          <p class="name">{{ houseInfo.name }}</p>
          <div class="infoBoxs">
            <div>
              <p class="price">{{ houseInfo.rent }}元/月</p>
              <p class="area">
                {{ houseInfo.area }}m² &nbsp;&nbsp; {{ houseInfo.room }}房{{
                  houseInfo.hall
                }}厅{{ houseInfo.toilet }}卫
              </p>
            </div>
            <div class="labelBox">
              <div class="collect">
                收藏人数: {{ houseInfo.inspectionNum }}
                <img src="../img/collect.png" alt="" />
              </div>
              <div class="look">
                近7日带看: {{ houseInfo.focusNum }}
                <img src="../img/look.png" alt="" />
              </div>
            </div>
          </div>
          <div class="detailedInfo">
            <div>
              <p>
                <span class="duan">装修：</span>
                <span>{{
                  houseInfo.decorates1 ? houseInfo.decorates1 : "-"
                }}</span>
              </p>
              <p>
                <span>朝向：</span>
                <span>{{
                  houseInfo.orientation1 ? houseInfo.orientation1 : "-"
                }}</span>
              </p>
              <p>
                <span>房源编号：</span>
                <span>{{
                  houseInfo.propertyNo ? houseInfo.propertyNo : "-"
                }}</span>
              </p>
              <p>
                <span>建成年代：</span>
                <span>{{ houseInfo.age ? houseInfo.age + "年" : "-" }}</span>
              </p>
              <p>
                <span>发布日期：</span>
                <span>{{
                  houseInfo.showTimes ? houseInfo.showTimes : "-"
                }}</span>
              </p>
              <p>
                <span>所属楼盘：</span>
                <span
                  class="property"
                  @click="getCommunityDetails(houseInfo.cid)"
                  >{{
                    houseInfo.communityName ? houseInfo.communityName : "-"
                  }}</span
                >
              </p>
            </div>
            <div>
              <p>
                <span>电梯：</span>
                <span>{{ houseInfo.left ? houseInfo.left : "-" }}</span>
              </p>
              <p>
                <span>用途：</span>
                <span>{{ houseInfo.ways ? houseInfo.ways : "-" }}</span>
              </p>
              <p>
                <span>楼层：</span>
                <span
                  >{{ houseInfo.floorText }}/{{ houseInfo.totalFloor }}</span
                >
              </p>
              <p>
                <span>房屋状态：</span>
                <span>{{
                  houseInfo.actualitys ? houseInfo.actualitys : "-"
                }}</span>
              </p>
            </div>
          </div>
          <p class="address">
            小区地址：{{ houseInfo.address ? houseInfo.address : "-" }}
          </p>
        </div>
      </div>
      <p
        class="title"
        v-if="
          houseInfo.sellPoint ||
          houseInfo.houseIntroduct ||
          houseInfo.roundIntroduct ||
          houseInfo.ownerMentality
        "
      >
        房源介绍
      </p>
      <div
        class="houseDesc"
        v-if="
          houseInfo.sellPoint ||
          houseInfo.houseIntroduct ||
          houseInfo.roundIntroduct ||
          houseInfo.ownerMentality
        "
      >
        <div class="introduce">
          <div class="leftDesc">
            <p class="list">
              <span class="typeTit">核心卖点</span>
              <span>{{ houseInfo.sellPoint }}</span>
            </p>
            <p class="list">
              <span class="typeTit">房型介绍</span>
              <span>{{ houseInfo.houseIntroduct }}</span>
            </p>
            <p class="list">
              <span class="typeTit">小区介绍</span>
              <span>{{ houseInfo.roundIntroduct }}</span>
            </p>
            <p class="list">
              <span class="typeTit">售房心态</span>
              <span>{{ houseInfo.ownerMentality }}</span>
            </p>
          </div>
        </div>
      </div>
      <p class="title" v-if="houseInfo.modelSet != ''">户型图</p>
      <div class="modelSet" v-if="houseInfo.modelSet != ''">
        <img :src="modelImg" alt="" />
        <!-- 图片放大 -->
        <div class="demo-image__preview">
          <el-image
            style="
              height: 320px;
              position: absolute;
              top: 0;
              left: 0;
              z-index: ;
            "
            :src="modelImg"
            :preview-src-list="modelArr"
          >
          </el-image>
        </div>
      </div>
      <p class="title">推荐顾问</p>
      <div class="agentBox">
        <div
          class="itemBox"
          v-for="(item, index) in houseInfo.agentRecommends"
          :key="index"
        >
          <img class="icon" :src="item.icon" alt="" />
          <div class="descBox">
            <p class="name">
              {{ item.name }} <span>{{ item.count }}分</span>
            </p>
            <p class="department">{{ item.departmentName }}</p>
            <div class="label">
              <span class="leftbtn">{{ item.job }}</span>
              <span
                class="code"
                @mouseenter="getContact(index)"
                @mouseleave="getLeave()"
                >扫码联系
                <img class="codeImg" src="../img/home/code.png" alt=""
              /></span>
            </div>
            <div
              class="contactCode"
              :class="{ activePhone: index == currentIndexs }"
            >
              <img :src="item.wxHomeImg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <p class="title">小区周边</p>
      <div class="community">
        <img :src="community.cover" alt="" />
        <div style="width: 65%">
          <div class="titleCom">
            <div>
              <p class="name">{{ community.name }}</p>
              <p class="adress">{{ community.address }}</p>
            </div>
            <div>
              <p class="listing">挂牌参考价</p>
              <p class="price">{{ community.guidePrice }}元/m²</p>
            </div>
            <p
              style="cursor: pointer"
              @click="getCommunityDetails(community.id)"
              class="moreDetails"
            >
              小区详情<span class="btnRight"></span>
            </p>
          </div>
          <div class="communityInfo">
            <div>
              <p>
                在售房源:
                {{ community.sellNum ? community.sellNum + "套" : "-" }}
              </p>
              <p>
                建筑年代: {{ community.buildTime ? community.buildTime : "-" }}
              </p>
              <p>
                占地面积:
                {{ community.coverArea ? community.coverArea + "m²" : "-" }}
              </p>
              <p>
                绿化率:
                {{
                  community.greeningRate ? community.greeningRate + "%" : "-"
                }}
              </p>
              <p>
                物业公司:
                {{
                  community.propertyCompany ? community.propertyCompany : "-"
                }}
              </p>
              <p>
                开发商: {{ community.developer ? community.developer : "-" }}
              </p>
            </div>
            <div>
              <p>
                在租房源:
                {{ community.rentNum ? community.rentNum + "套" : "-" }}
              </p>
              <p>
                楼栋总数:
                {{ community.buildingNum ? community.buildingNum : "-" }}
              </p>
              <p>
                建筑面积:
                {{ community.buildArea ? community.buildArea + "m²" : "-" }}
              </p>
              <p>
                容积率: {{ community.plotRatio ? community.plotRatio : "-" }}
              </p>
              <p>
                物业费:
                {{
                  community.propertyFee
                    ? community.propertyFee + "元/m²/月"
                    : "-"
                }}
              </p>
              <p>
                车位配比: {{ community.carRatio ? community.carRatio : "-" }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mapBox">
        <baidu-map
          class="bm-view"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="false"
          @ready="handler"
        >
          <bm-marker
            :position="{ lng: center.lng, lat: center.lat }"
            :dragging="true"
          >
          </bm-marker>
          <bm-local-search
            :keyword="keyword"
            :nearby="{ center, radius: 3000 }"
            :auto-viewport="true"
            :panel="false"
            @searchcomplete="search"
          ></bm-local-search>
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
          ></bm-geolocation>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation
        ></baidu-map>
        <div class="mapType">
          <div class="typeTitle">
            <span
              :class="{ 'tab-current': index == currentIndex }"
              v-for="(item, index) in typeList"
              :key="index"
              @click="clickTab(index, item)"
              >{{ item }}</span
            >
          </div>
          <div
            v-for="(item, index) in infoList"
            :key="index"
            class="infocontent"
          >
            <div>
              <p class="leftName">{{ item.title }}</p>
              <p class="leftBom">
                {{ item.address }}
              </p>
            </div>
            <p
              style="
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
              "
            >
              {{ item.long }}KM
            </p>
          </div>
        </div>
      </div>
      <p class="title">更多豪宅</p>
      <div class="recommendHouse">
        <div
          class="itemHouse"
          v-for="(item, index) in houseInfo.usedHouseCommonCommunities"
          :key="index"
          @click="getMoreSecondDetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <p class="title">{{ item.name }}</p>
          <p
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span class="area"
              >{{ item.area }}m² | {{ item.room }}房{{ item.hall }}厅{{
                item.toilet
              }}卫
            </span>
            <span>
              <span class="price">{{ item.rent }}</span
              ><span class="unit">元/月</span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import js from "@/utils/utils";
import Footer from "@/components/Footer.vue";
import { getLoadRent } from "../api/api";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import {
  BmGeolocation,
  BmLocalSearch,
  BmNavigation,
  BmMarker,
} from "vue-baidu-map";
export default {
  name: "RentHouseDetails",
  components: {
    Footer,
    BaiduMap,
    // 手动定位控件
    BmGeolocation,
    // 检索控件
    BmLocalSearch,
    // 地图放大缩小控件
    BmNavigation,
    // marker控件
    BmMarker,
  },
  data() {
    return {
      loading: true,
      activeIndex2: "0",
      houseInfo: "",
      community: "",
      swiperOptionTop: {
        loop: false,
        loopedSlides: 0,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 0,
        spaceBetween: 10,
        // centeredSlides: true,
        slidesPerView: "auto",
        // touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      swiperPictures: [],
      swiperList: [],
      swiper: [],
      numIndexs: 3,
      center: { lng: 0, lat: 0 },
      zoom: 10,
      map: null,
      BMap: null,
      keyword: "地铁",
      typeList: ["地铁", "商场", "医疗", "休闲", "教育"],
      currentIndex: 0,
      infoList: [],
      modelImg: "",
      modelArr: [],
      currentIndexs: -1,
      phoneindex: -1,
      showTab: true,
    };
  },
  mounted() {
    this.getSecondDetails(this.$route.params.id);
    this.agentList = JSON.parse(localStorage.getItem("agentList"));
    setTimeout(() => {
      this.infoList = JSON.parse(localStorage.getItem("arrList"));
      // console.log(this.infoList);
    }, 2000);
  },
  watch: {
    // 被侦听的变量keyword
    keyword() {
      setTimeout(() => {
        this.infoList = JSON.parse(localStorage.getItem("arrList"));
      }, 1000);
    },
  },
  methods: {
    getContact(index) {
      this.currentIndexs = index;
    },
    getLeave() {
      this.currentIndexs = -1;
    },
    getContactSee() {
      this.phoneindex = 1;
    },
    getLeavesSee() {
      this.phoneindex = 0;
    },
    // 计算距离
    GetDistance(lat1, lng1, lat2, lng2) {
      let EARTH_RADIUS = 6378.137;
      let radLat1 = this.rad(lat1);
      let radLat2 = this.rad(lat2);
      let a = radLat1 - radLat2;
      let b = this.rad(lng1) - this.rad(lng2);
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * EARTH_RADIUS;
      //s = Math.round(s * 10000d) / 10000d;
      s = Math.round(s * 10000) / 10000;
      //乘以1000是换算成米
      return s;
    },
    rad(d) {
      return (d * Math.PI) / 180.0;
    },
    // 搜索房源周围信息
    search(arr) {
      let arrList = arr.Yr.slice(0, 5);
      arrList.forEach((item) => {
        let lng = item.point.lat;
        let lat = item.point.lng;
        item.long = this.GetDistance(
          Number(lng),
          Number(lat),
          Number(this.houseInfo.latitude),
          Number(this.houseInfo.longitude)
        ).toFixed(1);
      });
      // console.log(arrList);
      localStorage.setItem("arrList", JSON.stringify(arrList));
    },

    clickTab(index, name) {
      this.keyword = name;
      this.currentIndex = index;
    },
    handler({ BMap, map, lat, lng }) {
      this.map = map;
      this.BMap = BMap;
      this.center.lng = this.houseInfo.longitude;
      this.center.lat = this.houseInfo.latitude;
      this.zoom = 15;
    },
    // 小区详情
    getCommunityDetails(id) {
      localStorage.setItem("ID", id);
      this.$router.push({ name: "CommunityDetails", params: { id } });
    },
    //左箭头按钮
    lefts() {
      if (this.numIndexs > 3) {
        if (this.swiper[0].id == this.swiperList[0].id) {
          this.swiperList.unshift(this.swiper[this.numIndexs - 4]); //前面添加一个
          this.swiperList.pop(this.swiperList[this.numIndexs]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
          this.numIndexs--;
        } else {
          return;
        }
      }
    },
    //右箭头按钮
    rights() {
      if (
        this.swiper[this.swiper.length - 1] ==
        this.swiperList[this.swiperList.length - 1]
      ) {
        return;
      } else {
        this.swiperList.shift(this.swiperList[this.numIndexs - 3]); //前面删除一个
        this.swiperList.push(this.swiper[this.numIndexs + 1]); //后面添加一个
        this.numIndexs++;
      }
    },
    handleChangeTab(index, item) {
      this.swiperPictures = item.imgarr;
    },
    getMoreSecondDetails(id) {
      localStorage.setItem("ID", id);
      this.getSecondDetails();
      setTimeout(() => {
        this.infoList = JSON.parse(localStorage.getItem("arrList"));
      }, 2000);
    },
    getSecondDetails(id) {
      document.documentElement.scrollTop = 0; //返回页面顶部
      getLoadRent({ id: localStorage.getItem("ID") }).then((res) => {
        // console.log(res);
        this.houseInfo = res.data;
        // 用途
        if (res.data.way == 1) {
          res.data.ways = "住宅";
        } else if (res.data.way == 2) {
          res.data.ways = "别墅";
        } else if (res.data.way == 3) {
          res.data.ways = "商住";
        } else if (res.data.way == 4) {
          res.data.ways = "商铺";
        } else if (res.data.way == 5) {
          res.data.ways = "写字楼";
        } else if (res.data.way == 99) {
          res.data.ways = "其他";
        }
        if (res.data.modelSet) {
          this.modelImg = res.data.modelSet[0];
          this.modelArr.push(this.modelImg);
        }
        this.handler(res.data.latitude, res.data.longitude);
        let swipers = [
          { name: "客厅", imgarr: res.data.hallPictures },
          { name: "卧室", imgarr: res.data.roomPictures },
          { name: "厨房", imgarr: res.data.kitchenPictures },
          { name: "卫生间", imgarr: res.data.toiletPictures },
          { name: "户型图", imgarr: res.data.modelSet },
          { name: "小区图", imgarr: res.data.communitySet },
        ];
        var length = "";
        swipers.forEach((item) => {
          if (item.imgarr.length != 0) {
            length = Number(item.imgarr.length) + 1;
            this.swiper.push(item);
            this.swiperPictures = this.swiper[0].imgarr;
          }
        });
        if (Number(length) <= 1) {
          this.swiperPictures.push(this.houseInfo.cover);
        }
        if (Array.isArray(this.swiper) && this.swiper.length > 4) {
          this.swiperList = this.swiper.slice(0, 4);
        } else {
          this.swiperList = this.swiper;
        }
        this.community = res.data.community;
        this.recommendHouse = res.data.usedHouseRecommends;
        this.houseInfo.decorates1 = js.getDecorate(res.data.decorate);
        this.houseInfo.orientation1 = js.getDirection(res.data.orientation);
        this.houseInfo.showTimes = js.formatDateTime(res.data.showTime);
        this.houseInfo.actualitys = js.getActuality(res.data.actuality);
        res.data.lift == 0 ? (res.data.left = "无") : (res.data.left = "有");
        if (res.data.floor <= res.data.totalFloor / 3) {
          res.data.floorText = "低层";
        } else if (res.data.floor <= (res.data.totalFloor / 3) * 2) {
          res.data.floorText = "中层";
        } else {
          res.data.floorText = "高层";
        }
        this.swiperOptionTop.loopedSlides = res.data.hallPictures.length;
        this.swiperOptionThumbs.loopedSlides = res.data.hallPictures.length;
        this.loading = false;
        setTimeout(() => {
          this.infoList = JSON.parse(localStorage.getItem("arrList"));
        }, 1500);
      });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
  },
};
</script>
<style lang="less" scoped>
i {
  /* 用border值来控制箭头粗细 */
  border: 5px solid #fff;
  /* 上、右、下、左  四个边框的宽度 */
  border-width: 0px 1px 1px 0px;
  display: inline-block;
  /* padding值控制箭头大小 */
  padding: 4px;
}
// 右箭头
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
// 左箭头
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.navigation {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: #3d3333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.Box {
  padding: 30px 160px;
  box-sizing: border-box;
  background-color: #fff;
  .Header {
    display: flex;
    justify-content: space-between;
    position: relative;
    .phone {
      cursor: pointer;
      padding: 0 13px;
      line-height: 32px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #da9525;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #da9525;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }
    .seePhone {
      border-radius: 1px;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #ccc;
      display: none;
      position: absolute;
      right: -45px;
      top: 40px;
      img {
        width: 130px;
        height: 130px;
      }
    }
    .activePhone {
      display: block;
    }
  }
}
.parametersBox {
  display: flex;
  padding-top: 32px;
  box-sizing: border-box;
  .swiperDiv {
    width: 55%;
    position: relative;
    margin-right: 24px;
    .swiperBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;
      .btnLeft {
        width: 8px;
        height: 8px;
        border-width: 1px;
        border-color: #3d3d3d #3d3d3d transparent transparent;
        border-style: solid;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-top: 40px;
        margin-right: 20px;
        cursor: pointer;
      }
      .btnRight {
        width: 8px;
        height: 8px;
        border-width: 1px;
        border-color: #3d3d3d #3d3d3d transparent transparent;
        border-style: solid;
        transform: matrix(0.75, 0.75, -0.75, 0.75, 0, 0);
        margin-top: 40px;
        margin-left: 20px;
        cursor: pointer;
      }
      .tab_content {
        width: 100%;
        font-size: 12px;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: space-between;
        flex-wrap: no-wrap;
        overflow: hidden;
        .tab_item {
          font-size: 16px;
          text-align: center;
          margin-right: 8px;
          cursor: pointer;
          .imgBox {
            text-align: center;
            margin-bottom: 8px;
            img {
              width: 100%;
              height: 96px;
              object-fit: cover;
            }
          }
          p {
            line-height: 25px;
            margin-left: 12px;
            font-weight: 500;
          }
        }
      }
    }
    .swiper-button-prev {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
      cursor: pointer;
    }
    .swiper-button-next {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
      cursor: pointer;
    }
    .thumb-example {
      height: 580px;
      background-color: transparent;
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
      img {
        width: 100%;
        height: 394px;
        object-fit: cover;
      }
    }
    .gallery-top {
      width: 100%;
    }
    .gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    .gallery-thumbs .swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.4;
      img {
        width: 150px;
        height: 100%;
      }
    }
    .gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }
  .infoBox {
    .infoBoxs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .labelBox {
        img {
          width: 16px;
          height: 16px;
        }
        .collect {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          height: 50px;
          background: #fff7ea;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f7523c;
          padding: 16px;
          margin-bottom: 17px;
        }
        .look {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 150px;
          height: 50px;
          padding: 16px;
          background: #edf6ff;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2a90fb;
        }
      }
    }
    .name {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 16px;
    }
    .price {
      font-size: 38px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 500;
      color: #da9525;
      margin-bottom: 38px;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .area {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 20px;
    }
    .detailedInfo {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 20px;
      box-sizing: border-box;
      .property {
        color: #da9525;
      }
      .property:hover {
        font-weight: 600;
        cursor: pointer;
        color: #da9525;
      }
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 40px;
      }
    }
    .address {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 40px;
    }
  }
}
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 16px;
}
.houseDesc {
  padding-bottom: 56px;
  box-sizing: border-box;
  white-space: pre-wrap;
  .introduce {
    display: flex;
    .leftDesc {
      // width: 882px;
      // height: 260px;
      padding: 24px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 32px;
      .list {
        display: flex;
        margin-bottom: 24px;
        .typeTit {
          display: block;
          width: 80px;
          margin-right: 32px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
        span {
          display: inline-block;
          width: 90%;
          line-height: 25px;
        }
      }
    }
  }
}
.modelSet {
  margin-bottom: 56px;
  position: relative;
  img {
    height: 320px;
  }
}
.agentBox {
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 36px;
  box-sizing: border-box;
  .itemBox {
    width: 360px;
    height: 146px;
    display: flex;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #f9fafe;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .descBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
          color: #e9ac33;
          margin-right: 10px;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          line-height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
.community {
  margin-bottom: 56px;
  display: flex;
  img {
    width: 35%;
    height: 298px;
    margin-right: 24px;
  }
  .titleCom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;
    .name {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 10px;
    }
    .listing {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 10px;
    }
    .adress {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
    }
    .price {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }
    .btnRight {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-width: 1px;
      border-color: #3d3d3d #3d3d3d transparent transparent;
      border-style: solid;
      transform: matrix(0.75, 0.75, -0.75, 0.75, 0, 0);
      margin-top: 40px;
    }
    .moreDetails:hover {
      color: rgb(218, 149, 37);
      font-weight: 600;
    }
  }
  .communityInfo {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    width: 100%;
    div {
      line-height: 38px;
    }
  }
}
.mapBox {
  height: 388px;
  margin-bottom: 56px;
  position: relative;

  ::v-deep .anchorBL {
    display: none !important;
  }
  .bm-view {
    width: 100%;
    height: 388px;
  }
  .mapType {
    width: 358px;
    height: 372px;
    border-radius: 4px;
    padding: 26px 16px;
    box-sizing: border-box;
    position: absolute;
    right: 8px;
    top: 8px;
    background-color: #fff;
    .typeTitle {
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 17px;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 6px;
        cursor: pointer;
      }
      .tab-current {
        color: #da9525;
        font-weight: 600;
      }
    }
    .infocontent {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: top;
      div {
        .leftName {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 23px;
        }
        .leftBom {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
.recommendHouse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemHouse {
    width: 32%;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 24px;
    img {
      width: 100%;
      height: 213px;
      margin-bottom: 12px;
      object-fit: cover;
      cursor: pointer;
    }
    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      padding-left: 16px;
    }
    .area {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      margin-left: 16px;
    }
    .price {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #da9525;
    }
    .unit {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #da9525;
      padding-right: 16px;
    }
  }
}
</style>
