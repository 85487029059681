<template>
  <div class="botmBox">
    <div class="line"></div>
    <div class="bottomBox">
      <div class="classify">
        <div>
          <p style="opacity: 1; font-size: 16px">豪宅楼盘</p>
          <p
            v-for="item in communityList"
            :key="item.id"
            @click="getCommunityDetails(item.id)"
            style="cursor: pointer"
          >
            {{ item.name }}
          </p>
        </div>
        <div>
          <p style="opacity: 1; font-size: 16px">高端新房</p>
          <p
            v-for="item in newHouseList"
            :key="item.id"
            @click="getNewhouseDetails(item.id)"
            style="cursor: pointer"
          >
            {{ item.name }}
          </p>
        </div>
        <div>
          <p style="opacity: 1; font-size: 16px">企业介绍</p>
          <p
            v-for="(item, index) in typeList"
            :key="index"
            @click="getItems(item)"
            style="cursor: pointer"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <div class="appbox">
        <img class="logo" src="../img/home/logobom.png" alt="" />
        <p>联系电话： 021 5858 2731</p>
        <p>企业邮箱： 99999@baitinghz.com</p>
        <div class="ercode">
          <div>
            <img
              src="http://api.baitinghz.com/storage/344*326*ac8cc7b847ed1e8cdda65ca66b7f7915.jpg"
              alt=""
            />
            <p>小程序</p>
          </div>
          <!-- <div>
            <img
              src="http://api.baitinghz.com/storage/400*400*59bb1e75330571c47fd5795a5c91e2d5.jpg"
              alt=""
            />
            <p>APP</p>
          </div> -->
          <div>
            <img
              src="http://api.baitinghz.com/storage/243*241*79007a0d5240fb29948d09cf07204dc8.png"
              alt=""
            />
            <p>公众号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="descbom">
      上海百廷房地产经纪有限公司 @2020备案号 沪ICP18036812号-2
      <a href="https://beian.miit.gov.cn"
        ><img src="../img/public.png" alt="" />沪公网安备31011502010588号</a
      >
      <a
        href="http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020190114140142000003530518-SAIC_SHOW_310000-B62CFF11D4CD12ADE053614F1BAC9CAF049&signData=MEQCIHE+LpXNii6LWnyOZVI9kNe9LQQLI+/u9bJiOKD22ZthAiBf9kOZ6dTkJy5DBPDdom5mtbvE1RVhVEmWBVs6czkJFA=="
      >
        <img class="license" src="../img/license.jpg" alt="" />
      </a>
    </div>
  </div>
</template>
<script>
import { getCommunityPager, getHomeInfo } from "../api/api";
export default {
  name: "Footer",
  data() {
    return {
      communityList: [],
      newHouseList: [],
      typeList: ["发展历程", "门店分布", "员工风采", "招聘岗位"],
    };
  },
  mounted() {
    getHomeInfo().then((res) => {
      this.communityList = res.communityList;
      this.newHouseList = res.newHouseList;
      localStorage.setItem("agentList", JSON.stringify(res.agentList));
    });
  },
  methods: {
    getCommunityDetails(id) {
      // this.$message({
      //   showClose: true,
      //   message: "该页面暂未开放！",
      // });
      localStorage.setItem("ID", id);
      this.$router.push({ name: "CommunityDetails", params: { id } });
    },
    getNewhouseDetails(id) {
      // this.$message({
      //   showClose: true,
      //   message: "该页面暂未开放！",
      // });
      localStorage.setItem("ID", id);
      if (this.$route.name == "NewHouseDetails") {
        this.$emit("change", id, this.$route.name);
        console.log(id, this.$route.name);
      } else {
        this.$router.push({ name: "NewHouseDetails", params: { id } });
      }
    },
    getItems(item) {
      if (this.$route.name == "CompanyProfile") {
        this.$emit("change", item, this.$route.name);
      } else {
        this.$router.push({ name: "CompanyProfile", params: { item } });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.botmBox {
  width: 100vw;
  background: url("../img/home/bgcbom.png") no-repeat center;
  background-size: 100% 100%;
  padding: 30px 15%;
  box-sizing: border-box;
  .line {
    width: 100%;
    height: 1px;
    background: #ffffff;
    opacity: 0.2;
  }
  .bottomBox {
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding: 25px 0 25px 0;
    box-sizing: border-box;
    .classify {
      display: flex;
      div {
        padding-right: 50px;
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 300;
          color: #ffffff;
          line-height: 30px;
          opacity: 0.6;
          letter-spacing: 1px;
        }
      }
    }
    .appbox {
      .logo {
        width: 96px;
        height: 33px;
        margin-bottom: 14px;
      }
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 30px;
        opacity: 0.6;
      }
      .ercode {
        display: flex;
        align-items: center;
        padding-top: 20px;
        box-sizing: border-box;
        text-align: center;
        div {
          margin-right: 25px;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  .descbom {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    letter-spacing: 2px;
    padding-top: 14px;
    box-sizing: border-box;
    opacity: 0.6;
    a {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      text-decoration: none;
      vertical-align: middle;
      img {
        width: 12px;
        margin-right: 2px;
      }
    }
    .license {
      width: 20px;
      height: 20px;
      margin-left: 20px;
    }
  }
}
</style>
