<template>
  <div class="box">
    <ul class="img_list">
      <li
        v-for="(val, index) in banners"
        :key="index"
        :id="'last' + (index + 1)"
      >
        <img class="img" :src="val" alt="" />
      </li>

      <div class="span">
        <span
          v-for="(val, index) in banners"
          :key="index"
          @mouseover="mouse(index)"
          @mouseleave="leave()"
        ></span>
      </div>
    </ul>
  </div>
</template>

<script>
let li = [];
let time;
let index = 0;
import { getPictureList } from "@/api/api";
export default {
  name: "Swiper",
  data() {
    return {
      num: 0,
      elenent: [],
      banners: [],
      li1: [],
    };
  },
  mounted() {
    this.initImg();
  },
  beforeDestroy() {
    clearInterval(time);
  },
  methods: {
    //初始化图片
    initImg() {
      getPictureList({ bannerType: 9 }).then((res) => {
        res.data.forEach((item) => {
          this.banners.push(item.image);
        });
        for (var i = 0; i < this.banners.length; i++) {
          li[i] = "last" + (i + 1);
        }
        let arr = [];
        for (let i = 0; i < this.banners.length; i++) {
          this.li1.push(JSON.parse(JSON.stringify(li)));
          li.unshift(li.pop());
        }
        this.li1 = JSON.parse(JSON.stringify(arr));
        time = setInterval(this.right, 3000);
      });
    },
    right() {
      let libox = document.getElementsByClassName("img_list")[0].children;
      let span = document.getElementsByClassName("span")[0].children;
      //删除最后一张图片放到第一张
      //console.log(li,456)
      li.unshift(li.pop());
      for (let i = 0; i < span.length; i++) {
        span[i].style.background = "#e6e6e6";
      }
      index++;
      if (index > span.length - 1) {
        index = 0;
      }
      span[index].style.background = "#ec4141";

      for (let i = 0; i < li.length; i++) {
        libox[i].id = li[i];
      }
    },
    left() {
      let libox = document.getElementsByClassName("img_list")[0].children;
      let span = document.getElementsByClassName("span")[0].children;
      //删除最后一张图片放到第一张
      //li.push(li.shift());
      //console.log(li);
      //console.log(index,'zxc')
      for (let i = 0; i < span.length; i++) {
        span[i].style.background = "#e6e6e6";
      }
      if (index < 0) {
        index = 0;
      }
      span[index].style.background = "#ec4141";
      //index--
    },
    mouse(e) {
      let libox = document.getElementsByClassName("img_list")[0].children;
      clearInterval(time);
      console.log(JSON.stringify(this.li1[e]));
      // li = JSON.parse(JSON.stringify(this.li1[e]));
      //console.log(li,789)
      if (e > index) {
        index = e;
        for (let i = 0; i < li.length; i++) {
          libox[i].id = li[i];
        }
        this.right();
        this.num = e;
      }

      if (e < index) {
        index = e;
        for (let i = 0; i < li.length; i++) {
          libox[i].id = li[i];
        }
        this.left();
        this.num = e;
      }
    },
    leave() {
      clearInterval(time);
      //index = num
      time = setInterval(this.right, 3000);
    },
  },
};
</script>

<style scoped>
.img_list {
  width: 100%;
  height: 260px;
  text-align: center;
  position: relative;
}
.img_list li {
  float: left;
  position: absolute;
  left: 0;
  border-radius: 10px;
  transition: all 0.8s;
  list-style: none;
}
.img_list li img {
  width: 518px;
  height: 304px;
  border-radius: 6px;
}
#last1 {
  left: 0;
  z-index: 1;
}
#last2 {
  left: 30%;
  transform: scale(1.25);
  z-index: 99;
}
#last3 {
  left: 60%;
  z-index: 1;
}
#but {
  position: absolute;
  z-index: 999;
  bottom: 200px;
}
.span {
  width: auto;
  height: 20px;
  position: absolute;
  bottom: -5px;
  z-index: -99;
  /* left: 70%; */
  /* transform: translateX(-50%); */
}
.span span {
  width: 30px;
  height: 5px;
  text-align: center;
  display: inline-block;
  background-color: #e6e6e6;
  opacity: 0;
  margin-left: 20px;
  border-radius: 3px;
}
.span span:nth-child(1) {
  margin-left: 0px;
  /* background-color: #ec4141; */
}
</style>
