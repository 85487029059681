<template>
  <!-- 点击回顶部组件 -->
  <!-- 页面内容 -->
  <div class="back-to-top" ref="scrollTop" v-show="showBackToTop">
    <a class="top" @click="scrollToTop">
      <span>
        <i style="display: block; font-size: 12px" class="el-icon-arrow-up"></i>
        顶部
      </span>
    </a>
  </div>
</template>
<script>
export default {
  name: "scrollTop",
  data() {
    return {
      scrollY: 0, // 当前滚动距离
      showBackToTop: false, // 是否显示返回顶部按钮
      threshold: 300, // px 滚动距离阈值
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    // 计算属性，返回当前是否应该显示返回顶部按钮
    shouldShowBackToTop() {
      return this.scrollY >= this.threshold; // 假设滚动距离超过 500px 时显示按钮
    },
  },
  watch: {
    // 监听 scrollY 属性的变化，更新 showBackToTop 属性
    scrollY(newValue) {
      // console.log(newValue);
      if (this.showBackToTop && !this.shouldShowBackToTop) {
        //返回的按钮已经显示 并且 屏幕显示的距离快到顶部
        //添加向下的样式移除显示的效果
        this.$refs.scrollTop.classList.add("hideDownC");
        this.$refs.scrollTop.classList.remove("showTopC");
      } else {
        this.showBackToTop = this.shouldShowBackToTop;
        //添加向上的样式移除移除的效果
        this.$refs.scrollTop.classList.remove("hideDownC");
        this.$refs.scrollTop.classList.add("showTopC");
      }
    },
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="less" scoped>
//返回按钮在距离屏幕底部显示的位置
@bottom: 50px;
@animationSeconds: 0.5s;
.back-to-top {
  position: fixed;
  right: 10px;
  bottom: @bottom;
  width: 80px;
  z-index: 990;
  opacity: 0; /* 初始时设置透明度为 0 */
}
.showTopC {
  opacity: 1; /* 隐藏时设置透明度为 0 */
  animation: showUp @animationSeconds ease-in-out forwards; /* 添加动画 */
}
@keyframes showUp {
  from {
    opacity: 0;
    transform: translateY(@bottom); /* 初始位置在页面底部 */
  }

  to {
    opacity: 1;
    transform: none; /* 结束位置为默认位置，即垂直居中 */
  }
}

.hideDownC {
  opacity: 0; /* 隐藏时设置透明度为 0 */
  animation: hideDown @animationSeconds ease-in-out forwards; /* 添加动画 */
}
@keyframes hideDown {
  from {
    opacity: 1;
    transform: none; /* 初始位置为默认位置，即垂直居中 */
  }

  to {
    opacity: 0;
    transform: translateY(@bottom); /* 结束位置在页面底部 */
  }
}
.back-to-top .top {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  background: #fff;
  box-shadow: 0 0.04rem 0.16rem 0 rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  text-align: center;
  font-size: 0.22rem;
  cursor: pointer;
}
.back-to-top .top .i {
  display: block;
  font-size: 0.34rem;
}
.back-to-top:hover {
  transform: scale(1.1);
}
</style>
