<template>
  <div>
    <div class="about">
      <div class="bgc">
        <div class="navigation">
          <img class="logo" src="../img/home/logo1.png" alt="" />
          <el-menu
            :default-active="activeIndex2"
            mode="horizontal"
            @select="handleSelect"
            text-color="#fff"
            active-text-color="#ffd04b"
          >
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">二手房</el-menu-item>
            <el-menu-item index="3">新房</el-menu-item>
            <el-menu-item index="4">租房</el-menu-item>
            <el-menu-item index="5">海外房产</el-menu-item>
            <el-menu-item index="6">查楼盘</el-menu-item>
            <el-menu-item index="7">我要卖房</el-menu-item>
            <el-menu-item index="8">我要找房</el-menu-item>
            <el-menu-item index="9">企业介绍</el-menu-item>
          </el-menu>
        </div>
        <div class="guide">
          <img src="../img/about/about.png" alt="" />
        </div>
      </div>
      <!-- 导航 -->
      <div class="nav">
        <div class="wrap">
          <ul>
            <li @click="skipTo('#profile')">公司简介</li>
            <li @click="skipTo('#corporate-glory')">企业荣耀</li>
            <li @click="skipTo('#development-history')">发展历程</li>
            <li @click="skipTo('#employee-demeanor')">员工风采</li>
            <li @click="skipTo('#store-distribution')">门店分布</li>
            <li @click="skipTo('#recruitment-position')">招聘岗位</li>
          </ul>
        </div>
        <div style="clear: both"></div>
      </div>
      <!-- 锚点元素 -->
      <div class="about-content">
        <!-- 公司简介 -->
        <div class="profile" id="profile">
          <img src="../img/about/logo.png" alt="" />
          <div class="descbox">
            <img src="../img/about/one.png" alt="" />
            <div class="introduce">
              <p style="text-indent: 2em">
                百廷豪宅全称为上海百廷房地产经纪有限公司，主营上海高端房产交易服务，主要业务为二手房买卖/租赁、高端新房代理、海外房产置业等。
              </p>
              <p style="text-indent: 2em">
                 公司总部位于陆家嘴世茂大厦，门店主要分布于浦东陆家嘴、花木及浦西新天地、外滩、苏河湾、徐汇滨江等商圈，深入豪宅，精耕豪宅。
              </p>
              <p style="text-indent: 2em">
                “百”在中国文化中象征着美满、完美与自由，百廷引鉴莫比斯环结构，以字母“B”为参考设计企业LOGO，三层主体赋予二维空间创造错层感，新颖大方，勾勒建筑之美，以追求完美的态度致敬​匠心，反躬自省，向内而求。
              </p>
            </div>
          </div>
        </div>
        <!-- 企业荣耀 -->
        <div class="honourbox" id="corporate-glory">
          <img class="imgtitle" src="../img/about/two.png" alt="" />
          <div class="list">
            <div
              class="itemBox"
              v-for="(item, index) in honourList"
              :key="index"
              @click="enlargeImage(item.image)"
            >
              <div class="imgBox">
                <img :src="item.image" alt="" />
              </div>
            </div>
          </div>
          <el-dialog
            :visible.sync="dialogVisible"
            width="50%"
            height="50%"
            :before-close="handleClose"
            style="text-align: center"
          >
            <img
              style="height: 300px; object-fit: contain"
              :src="imgUrl"
              alt=""
            />
          </el-dialog>
          <img class="bgcJB" src="../img/about/jiangbei.png" alt="" />
        </div>
        <div id="development-history">
          <div class="development">
            <img class="imgtitle" src="../img/about/three.png" alt="" />
            <div
              class="contlist"
              ref="contlist"
              @mouseover="stopScroll"
              @mouseout="startScroll"
            >
              <img src="../img/about/licheng.png" alt="" />
            </div>
          </div>
        </div>
        <div class="employee" id="employee-demeanor">
          <img class="imgtitle" src="../img/about/four.png" alt="" />
          <!-- <swiper class="swiper" /> -->
          <el-carousel
            :interval="2000"
            type="card"
            indicator-position="none"
            height="304px"
          >
            <el-carousel-item v-for="(item, index) in banners" :key="index">
              <img
                style="width: 518px; height: 304px; border-radius: 6px"
                :src="item"
                alt
                class="circle"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div id="store-distribution">
          <div class="store">
            <img class="imgtitle" src="../img/about/five.png" alt="" />
          </div>

          <div class="storeAdd">
            <div
              class="itemStore"
              v-for="(item, index) in stroeList"
              :key="index"
            >
              <img :src="item.covers" alt="" />
              <div>
                <p class="name">{{ item.name }}</p>
                <p class="addRess">门店地址:{{ item.address }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="position" id="recruitment-position">
          <img class="imgtitle" src="../img/about/six.png" alt="" />
          <div class="postType">
            <div class="itemPost">
              <p class="postName">豪宅顾问</p>
              <span
                style="
                  display: block;
                  width: 6px;
                  height: 6px;
                  background: #da9525;
                  border-radius: 1px;
                  margin-bottom: 10px;
                "
              ></span>
              <p class="title">岗位要求</p>
              <p>1、学历：全日制大专及以上（有行业经验者适度放宽）；</p>
              <p>2、年龄：20-35岁；</p>
              <p>
                3、对销售工作持有高度热情，具有一定的市场洞察能力和分析能力，能精准的掌握客户需求；
              </p>
              <p>4、学习能力和服务意识强，认可公司企业文化。</p>
            </div>
            <div class="itemPost">
              <p class="postName">企划专员</p>
              <span
                style="
                  display: block;
                  width: 6px;
                  height: 6px;
                  background: #da9525;
                  border-radius: 1px;
                  margin-bottom: 10px;
                "
              ></span>
              <p class="title">岗位要求</p>
              <p>1、学历：全日制本科以上，女性；</p>
              <p>2、负责公司内部企业文化宣导、氛围营造；</p>
              <p>3、季会、年会等大型活动的组织编排；</p>
            </div>
            <div class="itemPost">
              <p class="postName">招聘专员</p>
              <span
                style="
                  display: block;
                  width: 6px;
                  height: 6px;
                  background: #da9525;
                  border-radius: 1px;
                  margin-bottom: 10px;
                "
              ></span>
              <p class="title">岗位要求</p>
              <p>1、学历：全日制大专及以上；</p>
              <p>2、年龄：20-35岁；</p>
              <p>
                3、具有招聘经验，熟悉主流招聘软件的使用，有房地产从业经验者优先。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 47px; background-color: #f0e9e9"></div>
      <Footer @change="getItems(arguments)" />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import swiper from "@/components/Swiper.vue";
import { getList, getPositionList, getPictureList } from "@/api/api";
export default {
  name: "CompanyProfile",
  components: { Footer, swiper },
  data() {
    return {
      scrollW: 0, //记录滚动到哪了
      content:
        "自2000年以来，Pearland的人口几乎翻了四番，从37,640人激增至2019年增至近122,149人，年龄中位数为34.7岁，\n\n皮尔兰家庭年收入中位数为$105,806美元，比美国平均水平$63,179美元高出67%，\n\n国家统计局数据显示，德州医疗中心40%的职员在皮尔兰居住置业。",
      navOffsetTop: 0,
      activeIndex2: "9",
      stroeList: [],
      positionList: [],
      honourList: [],
      staffList: [],
      banners: [],
      dialogVisible: false,
      imgUrl: "",
    };
  },
  filters: {
    lineFeed(str) {
      return str.replace(/\n\n/g, "\r\n");
    },
  },
  mounted() {
    let name = this.$route.params.item;
    if (name == "发展历程") {
      this.skipTo("#development-history");
    } else if (name == "员工风采") {
      this.skipTo("#employee-demeanor");
    } else if (name == "门店分布") {
      this.skipTo("#store-distribution");
    } else if (name == "招聘岗位") {
      this.skipTo("#recruitment-position");
    }
    getPictureList({ bannerType: 9 }).then((res) => {
      res.data.forEach((item) => {
        this.banners.push(item.image);
      });
    });
    this.scroll();
    // 监听滚动事件
    window.addEventListener("scroll", this.fiexdNav);
    this.getData();
    this.getList();
    this.getPositionList();
    this.getPictureList(8);
  },

  methods: {
    getItems(arg) {
      if (arg[0] == "发展历程") {
        this.skipTo("#development-history");
      } else if (arg[0] == "员工风采") {
        this.skipTo("#employee-demeanor");
      } else if (arg[0] == "门店分布") {
        this.skipTo("#store-distribution");
      } else if (arg[0] == "招聘岗位") {
        this.skipTo("#recruitment-position");
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 企业荣耀图片放大
    enlargeImage(image) {
      this.dialogVisible = true;
      this.imgUrl = image;
    },
    // 8企业荣耀 9员工风采
    getPictureList(bannerType) {
      getPictureList({ bannerType: bannerType }).then((res) => {
        if (bannerType == 8) {
          this.honourList = res.data;
        }
      });
    },
    // 招聘列表
    getPositionList() {
      getPositionList().then((res) => {
        // console.log(res);
        this.positionList = res.data;
      });
    },
    // 门店信息
    getList() {
      getList().then((res) => {
        // console.log(res);
        this.stroeList = res.data;
      });
    },
    stopScroll() {
      var target = this.$refs.contlist;
      clearInterval(this.scrollTime);
    },
    //鼠标移开 ，接着滚动
    startScroll() {
      var target = this.$refs.contlist;
      this.scrollW = target.offsetLeft; // 移开时记录向左移动的距离
      this.scroll();
    },
    //循环滚动
    scroll() {
      var that = this; //因为定时器里要使用vue对象时不能用this, 在定时器中的 this 代表
      var target = this.$refs.contlist;
      var initLeft = 0;
      if (this.scrollW < 0) {
        initLeft = this.scrollW * -1;
      }
      //定时器
      this.scrollTime = setInterval(function () {
        initLeft++;
        if (initLeft >= target.offsetWidth / 2) {
          initLeft = 0;
        }
        target.style.left = "-" + initLeft + "px"; //获取不到translateX的值改用 left

        //target.style = 'transform: translateX(-'+ initLeft +'px)';
      }, 16);
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/OverseasHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
    // handleSelect(e) {
    //   if (e == "1") {
    //     this.$router.push("/Home");
    //   } else {
    //     this.$message({
    //       showClose: true,
    //       message: "该页面暂未开放！",
    //     });
    //   }
    // },
    /** 设置导航条nav到达页面顶部时固定 **/
    // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
    getData() {
      this.navOffsetTop = document.querySelector(".nav").offsetTop;
    },
    fiexdNav() {
      // 2.获取当前页面的卷曲高度
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const nav = document.querySelector(".nav");
      // 3.判断卷曲高度是否大于等于导航条的offsetTop值
      if (scrollTop > this.navOffsetTop) {
        //   3.1若满足，则给nav导航添加固定样式
        nav.classList.add("fixedNav");
      } else {
        //   3.2若不满足，则删除nav导航的固定样式
        nav.classList.remove("fixedNav");
      }

      /** 当滚动到一定区域时给导航项添加选中样式 **/
      //  1.获取所有锚点元素
      const contents = document.querySelectorAll(".about-content>div");
      // 2.获取锚点元素的offsetTop值，并收集在一个数组
      const contentsOffsetTop = [];
      contents.forEach((item) => {
        contentsOffsetTop.push(item.offsetTop);
      });
      // 3.获取页面高度
      const pageHeight = window.innerHeight;
      // 4.获取nav的子元素
      const navChildren = document.querySelectorAll(".nav li");
      // 5.遍历锚点元素的offsetTop值
      for (let i = 0; i < contentsOffsetTop.length; i++) {
        // 5.1 设置第一项导航默认为选中状态
        if (i === 0) {
          navChildren[0].classList.add("active");
        } else if (scrollTop > contentsOffsetTop[i - 1] + pageHeight / 3) {
          // 排他思想
          for (let j = 0; j < contentsOffsetTop.length; j++) {
            navChildren[j].classList.remove("active");
            navChildren[i].classList.add("active");
          }
        } else {
          navChildren[i].classList.remove("active");
        }
      }
    },
    /**
     *设置点击导航跳转到指定选择器对应的锚点元素
     * @param {*} selector
     **/
    skipTo(selector) {
      const navHeight = document.querySelector(".nav").offsetHeight;
      // scrollIntoView() js原生方法，实现锚点滚动过渡
      const target = document.querySelector(selector);
      target.scrollIntoView({ behavior: "smooth" });
      // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
      window.scrollTo(0, target.offsetTop - navHeight);
    },
  },
  destroyed() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.fiexdNav);
  },
};
</script>
<style lang="less">
// li {
//   // text-indent: 2em;//首行缩进
//   line-height: 50px; //行间距
//   white-space: pre-wrap; //在文本标签上添加次样式
// }
.bgc {
  width: 100vw;
  height: 724px;
  background: url("../img/about/banner.png") no-repeat center;
  background-size: 100% 100%;
  object-fit: cover;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.guide {
  padding-top: 139px;
  padding-left: 169px;
  box-sizing: border-box;
  img {
    width: 320px;
    height: 154px;
  }
}
.nav {
  width: 100%;
  height: 100px;
  background: #fafafa;
  padding-right: 160px;
  ul {
    list-style: none;
    display: flex;
    float: right;
    padding-top: 65px;
    li {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-right: 24px;
      padding-bottom: 12px;
      cursor: pointer;
    }
  }
}
.about-content {
  .profile {
    width: 100%;
    height: 558px;
    background-color: #f0e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 11%;
    img {
      width: 25%;
      height: 307px;
    }
    .descbox {
      img {
        width: 158px;
        height: 58px;
        margin-left: 27px;
        margin-bottom: 16px;
      }
      .introduce {
        width: 80%;
        height: 230px;
        padding: 15px 30px;
        box-sizing: border-box;
        background: #ffffff;
        border: 2px solid #da9525;
        border-left: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        overflow: scroll;
      }
    }
  }
  .honourbox {
    width: 100%;
    padding: 38px 10%;
    box-sizing: border-box;
    position: relative;
    .imgtitle {
      width: 162px;
      height: 58px;
      margin-bottom: 44px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .itemBox {
        width: 20%;
        margin-right: 50px;
        margin-bottom: 25px;
        border: 2px solid #da9525;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        .imgBox {
          width: 100%;
          height: 129px;
          background-color: #eee;
          box-sizing: border-box;
          padding-top: 15px;
          box-sizing: border-box;
          img {
            height: 100px;
            background-color: #eee;
          }
        }
        span {
          display: block;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 10px;
        }
      }
    }
    .bgcJB {
      width: 213px;
      height: 236px;
      position: absolute;
      bottom: 52px;
      right: 20px;
      z-index: -1;
    }
    .el-dialog {
      background-color: #dadada;
    }
  }
  .development {
    background: url("../img/about/bgc.png") no-repeat center;
    background-size: 100% 100%;
    height: 620px;
    padding: 80px 160px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    .imgtitle {
      width: 192px;
      height: 58px;
    }
    .contlist {
      position: absolute;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      top: 200px;
      left: 160px;

      img {
        width: 2718px;
        height: 250px;
        object-fit: contain;
      }
    }
  }
  .employee {
    width: 100%;
    padding: 90px 11%;
    box-sizing: border-box;
    height: 690px;
    .imgtitle {
      width: 158px;
      height: 58px;
      margin-bottom: 100px;
    }
    .el-carousel__container {
      text-align: center;
      overflow: hidden;
    }
    .carousel_image_type {
      width: 100%;
    }
    .swiper {
      overflow: visible;
    }
  }
  .store {
    background: url("../img/about/addresstop.png") no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: 264px;
    .imgtitle {
      width: 172px;
      height: 58px;
      margin-left: 11%;
      margin-top: 98px;
    }
  }
  .storeAdd {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: url("../img/about/addressbgc.png") no-repeat center;
    background-size: 100% 100%;
    padding: 63px 11%;
    .itemStore:nth-child(odd) {
      margin-right: 8%;
    }
    .itemStore {
      display: flex;
      width: 45%;
      background: linear-gradient(180deg, #ffffff 0%, #fefefe 100%);
      border-radius: 4px;
      border: 1px solid #ffcb77;
      padding: 6px 34px 6px 6px;
      margin-bottom: 30px;
      img {
        width: 40%;
        height: 140px;
        margin-right: 15px;
      }
      .name {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 50px;
        padding-bottom: 20px;
      }
      .addRess {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
    }
  }
  .position {
    padding: 7% 11%;
    box-sizing: border-box;
    .imgtitle {
      width: 158px;
      height: 58px;
      margin-bottom: 48px;
      box-sizing: border-box;
    }
    .postType {
      display: flex;
      justify-content: space-between;
      .itemPost {
        border-radius: 4px;
        border: 1px solid #e9c07e;
        padding: 40px;
        width: 324px;
        .postName {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 560;
          color: rgba(0, 0, 0, 0.8);
          margin-bottom: 9px;
        }
        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 560;
          color: #000000;
          padding-bottom: 10px;
          padding-top: 20px;
        }
        p {
          line-height: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          letter-spacing: 2px;
        }
      }
    }
  }
}

.fixedNav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.active {
  color: #da9525;
  border-bottom: 2px solid #da9525;
}
</style>
