<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="navigation">
      <img class="logo" src="../img/home/logo1.png" alt="" />
      <el-menu
        :default-active="activeIndex2"
        mode="horizontal"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">二手房</el-menu-item>
        <el-menu-item index="3">新房</el-menu-item>
        <el-menu-item index="4">租房</el-menu-item>
        <el-menu-item index="5">海外房产</el-menu-item>
        <el-menu-item index="6">查楼盘</el-menu-item>
        <el-menu-item index="7">我要卖房</el-menu-item>
        <el-menu-item index="8">我要找房</el-menu-item>
        <el-menu-item index="9">企业介绍</el-menu-item>
      </el-menu>
    </div>
    <div class="Box">
      <div class="Header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/NewHouse' }"
            >新房列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>新房详情页</el-breadcrumb-item>
        </el-breadcrumb>
        <div>
          <div
            class="phone"
            @mouseenter="getContactSee(1)"
            @mouseleave="getLeavesSee(0)"
          >
            <img src="../img/phone.png" alt="" />手机看
          </div>
          <div class="seePhone" :class="{ activePhone: phoneindex == 1 }">
            <img :src="houseInfo.wxHomeImg" alt="" />
          </div>
        </div>
      </div>
      <div class="parametersBox">
        <div class="swiperDiv">
          <div class="thumb-example">
            <!-- 大轮播图 -->
            <swiper
              class="swiper gallery-top"
              :options="swiperOptionTop"
              ref="swiperTop"
            >
              <swiper-slide
                class="slide"
                v-for="(item, index) in swiperPictures"
                :key="index"
              >
                <img :src="item" alt="" />
                <!-- 图片放大 -->
                <div class="demo-image__preview">
                  <el-image
                    style="
                      width: 100%;
                      height: 394px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: ;
                    "
                    :src="item"
                    :preview-src-list="swiperPictures"
                  >
                  </el-image>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-next">
              <i class="left"></i>
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="right"></i>
            </div>
            <div class="swiperBox">
              <div class="btnLeft" @click.stop="lefts"></div>
              <div class="tab_content">
                <div
                  class="tab_item"
                  v-for="(item, index) in swiperList"
                  :key="index"
                  @click.stop="handleChangeTab(index, item)"
                >
                  <div class="imgBox">
                    <img :src="item.imgarr[0]" alt="" />
                  </div>
                  <p style="color: #000">
                    {{ item.name }}({{ item.imgarr.length }})
                  </p>
                </div>
              </div>
              <div class="btnRight" @click.stop="rights"></div>
            </div>
            <!-- 小缩略图 -->
            <!-- <swiper
              class="swiper gallery-thumbs"
              :options="swiperOptionThumbs"
              ref="swiperThumbs"
            >
              <swiper-slide
                class="slide"
                v-for="(item, index) in houseInfo.itemPictures"
                :key="index"
              >
                <img :src="item" alt=""
              /></swiper-slide>
            </swiper> -->
          </div>
        </div>
        <div class="infoBox">
          <p class="name">
            {{ houseInfo.name }}
            <span
              style="background-color: #deba77"
              v-if="houseInfo.progress == 1"
              >预售</span
            ><span
              style="background-color: #f56d40"
              v-if="houseInfo.progress == 2"
              >待开盘</span
            ><span
              style="background-color: #f56d40"
              v-if="houseInfo.progress == 3"
              >在售</span
            ><span
              style="background-color: #dddddd"
              v-if="houseInfo.progress == 4"
              >售罄</span
            >
          </p>
          <div class="newHouse">
            <p class="area">
              <span
                style="
                  font-size: 22px;
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.8);
                "
                >{{ houseInfo.minBuildArea }}~{{
                  houseInfo.maxBuildArea
                }}m²</span
              >
              <span> 建面 </span>
            </p>
            <p class="price">
              <span style="font-size: 22px; font-weight: 500; color: #da9525">
                {{ houseInfo.price1 }} - {{ houseInfo.price2 }}
              </span>
              <span> 总价 </span>
            </p>
            <p class="averagePrice">
              <span style="font-size: 20px; font-weight: 500; color: #da9525">
                {{ houseInfo.perPrice }}元/m²
              </span>
              <span> 参考均价 </span>
            </p>
          </div>
          <div class="detailedInfo">
            <div>
              <p>
                <span class="duan"
                  >房型结构:
                  {{ houseInfo.structure ? houseInfo.structure : "-" }}</span
                >
              </p>
              <p>
                <span>产权性质：</span>
                <span>{{ houseInfo.way ? houseInfo.way : "-" }}</span>
              </p>
              <p>
                <span>建筑面积：</span>
                <span>{{
                  houseInfo.buildArea ? houseInfo.buildArea + "m²" : "-"
                }}</span>
              </p>
              <p>
                <span>容积率：</span>
                <span>{{
                  houseInfo.plotRatio ? houseInfo.plotRatio : "-"
                }}</span>
              </p>
              <p>
                <span>绿化率：</span>
                <span>{{
                  houseInfo.greeningRate ? houseInfo.greeningRate + "%" : "-"
                }}</span>
              </p>
              <p>
                <span>物业公司：</span>
                <span>{{
                  houseInfo.propertyCompany ? houseInfo.propertyCompany : "-"
                }}</span>
              </p>
            </div>
            <div>
              <p>
                <span>所属商圈：</span>
                <span>{{
                  houseInfo.businessArea ? houseInfo.businessArea.name : "-"
                }}</span>
              </p>
              <p>
                <span>产权年限：</span>
                <span>{{
                  houseInfo.propertyYear ? houseInfo.propertyYear + "年" : "-"
                }}</span>
              </p>
              <p>
                <span>占地面积：</span>
                <span>{{
                  houseInfo.coverArea ? houseInfo.coverArea + "m²" : "-"
                }}</span>
              </p>
              <p>
                <span>项目均价：</span>
                <span>{{
                  houseInfo.perPrice ? houseInfo.perPrice + "元/m²" : "-"
                }}</span>
              </p>
              <p>
                <span>车位总数：</span>
                <span>{{ houseInfo.carNum ? houseInfo.carNum : "-" }}</span>
              </p>
              <p>
                <span>车位配比：</span>
                <span>{{
                  houseInfo.parkSpaceRatio ? houseInfo.parkSpaceRatio : "-"
                }}</span>
              </p>
            </div>
          </div>
          <p class="address">
            开发商: {{ houseInfo.developer ? houseInfo.developer : "-" }}
          </p>
        </div>
      </div>
      <p class="title" v-if="houseInfo.speakVideos != ''">视频讲房</p>
      <div class="video-container" v-if="houseInfo.speakVideos != ''">
        <video
          id="myPlayer"
          ref="myPlayer"
          :destroyOnClose="true"
          class="vjs-default-skin vjs-big-play-centered vjs-16-9 video-js"
          loop
          :autoplay="true"
          muted
          controls
          preload="auto"
        >
          <!-- @play="videoIsPlay = true"
          @pause="videoIsPlay = false"
          @ended="videoIsPlay = false" -->
          >
        </video>
        <!-- <span
          class="start-play"
          v-show="!videoIsPlay"
          @click="changeVideoStatus()"
        ></span> -->
      </div>
      <p class="title" v-if="tab != ''">在售户型</p>
      <div class="tab" v-if="tab != ''">
        <div class="btnLeft" @click.stop="left"></div>
        <div class="tab_content">
          <div class="tab_item" v-for="(item, index) in tab" :key="index">
            <div class="imgBox" style="position: relative">
              <img :src="item.imgs[0]" alt="" />
              <!-- 图片放大 -->
              <div class="demo-image__preview" style="width: ">
                <el-image
                  style="
                    width: 99%;
                    height: 100%;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    z-index: 0;
                  "
                  :src="item.imgs[0]"
                  :preview-src-list="arr"
                >
                </el-image>
              </div>
            </div>
            <p>{{ item.room }}室{{ item.hall }}厅{{ item.toilet }}卫</p>
            <p
              style="
                display: flex;
                justify-content: space-between;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              {{ item.area }}m²
              <span style="color: #da9525">{{ item.price }}万</span>
            </p>
          </div>
        </div>
        <div class="btnRight" @click.stop="right"></div>
      </div>
      <p class="title">楼盘参数</p>
      <div class="propertyBox">
        <div>
          <p>推广名称: {{ houseInfo.promoteName }}</p>
          <p>开盘时间: {{ houseInfo.startTimes.slice(0, 7) }}</p>
          <p>交房时间: {{ houseInfo.makeTimes.slice(0, 7) }}</p>
          <p>交付标准: {{ houseInfo.standard1 }}</p>
        </div>
        <div>
          <p>楼栋总数: {{ houseInfo.buildingNum }}</p>
          <p>规划户数: {{ houseInfo.familyNum }}</p>
          <p>建筑层数: {{ houseInfo.floorNum }}</p>
          <p>供水类型: {{ houseInfo.waters }}</p>
        </div>
        <div>
          <p>有无燃气: {{ houseInfo.gas1 }}</p>
          <p>供电类型: {{ houseInfo.electricitys }}</p>
          <p>供暖类型: {{ houseInfo.heating1 }}</p>
        </div>
      </div>
      <p class="title">推荐顾问</p>
      <div class="agentBox">
        <div
          class="itemBox"
          v-for="(item, index) in houseInfo.agentRecommends"
          :key="index"
        >
          <img class="icon" :src="item.icon" alt="" />
          <div class="adescBox">
            <p class="name">
              {{ item.name }} <span>{{ item.count }}分</span>
            </p>
            <p class="department">{{ item.departmentName }}</p>
            <div class="label">
              <span class="leftbtn">{{ item.job }}</span>
              <span
                class="code"
                @mouseenter="getContact(index)"
                @mouseleave="getLeave()"
                >扫码联系
                <img class="codeImg" src="../img/home/code.png" alt=""
              /></span>
            </div>
            <div
              class="contactCode"
              :class="{ activePhone: index == currentIndexs }"
            >
              <img :src="item.wxHomeImg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <p class="title" v-if="houseInfo.desc">项目描述</p>
      <div v-if="houseInfo.desc" class="descBox">
        {{ houseInfo.desc }}
      </div>
      <p class="title" v-if="houseInfo.roundIntroduct">项目周边</p>
      <div v-if="houseInfo.desc" class="descBox">
        {{ houseInfo.roundIntroduct }}
      </div>
      <p class="title">周边配套</p>
      <div class="mapBox">
        <baidu-map
          class="bm-view"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="false"
          @ready="handler"
        >
          <bm-marker
            :position="{ lng: center.lng, lat: center.lat }"
            :dragging="true"
          >
          </bm-marker>
          <bm-local-search
            :keyword="keyword"
            :nearby="{ center, radius: 4000 }"
            :auto-viewport="true"
            :panel="false"
            @searchcomplete="search"
          ></bm-local-search>
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
          ></bm-geolocation>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation
        ></baidu-map>
        <div class="mapType">
          <div class="typeTitle">
            <span
              :class="{ 'tab-current': index == currentIndex }"
              v-for="(item, index) in typeList"
              :key="index"
              @click="clickTab(index, item)"
              >{{ item }}</span
            >
          </div>
          <div
            v-for="(item, index) in infoList"
            :key="index"
            class="infocontent"
          >
            <div>
              <p class="leftName">{{ item.title }}</p>
              <p class="leftBom">
                {{ item.address }}
              </p>
            </div>
            <p
              style="
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
              "
            >
              {{ item.long }}KM
            </p>
          </div>
        </div>
      </div>
      <p class="title">更多新房</p>
      <div class="moreNewHouse">
        <div
          class="itemMhouse"
          v-for="(item, index) in houseInfo.newHouseRecommends"
          :key="index"
          @click="getMoreNewhouseDetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <div>
            <p class="name">{{ item.name }}</p>
            <p class="area">
              {{ item.minBuildArea }} - {{ item.maxBuildArea }}m²
            </p>
            <p class="price">{{ item.price1 }}万 - {{ item.price2 }}万</p>
          </div>
        </div>
      </div>
      <p class="title">海外新房</p>
      <div class="moreOverHouse">
        <div
          class="itemMhouse"
          v-for="(item, index) in houseInfo.overseaHouseRecommends"
          :key="index"
          @click="getOverHouseDetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <div>
            <p class="name">
              <span style="font-size: 18px">{{ item.name }}</span>
              <span
                ><img src="../img/home/place.png" alt="" />{{
                  item.city.name
                }}</span
              >
            </p>
            <p class="area">
              {{ item.minBuildArea }} - {{ item.maxBuildArea }}m²
            </p>
            <p class="price">{{ item.price1 }}万 - {{ item.price2 }}万</p>
          </div>
        </div>
      </div>
    </div>
    <Footer @change="getRouteName(arguments)" />
  </div>
</template>
<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
import js from "@/utils/utils";
import Footer from "@/components/Footer.vue";
import { getLoadNewhouse } from "../api/api";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import {
  BmGeolocation,
  BmLocalSearch,
  BmNavigation,
  BmMarker,
} from "vue-baidu-map";
export default {
  name: "NewHouseDetails",
  components: {
    Footer,
    BaiduMap,
    // 手动定位控件
    BmGeolocation,
    // 检索控件
    BmLocalSearch,
    // 地图放大缩小控件
    BmNavigation,
    // marker控件
    BmMarker,
  },
  data() {
    return {
      loading: true,
      activeIndex2: "0",
      houseInfo: "",
      swiperOptionTop: {
        loop: false,
        loopedSlides: 0,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 0,
        spaceBetween: 10,
        // centeredSlides: true,
        slidesPerView: "auto",
        // touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      swiperPictures: [],
      swiperList: [],
      swiper: [],
      numIndexs: 3,
      numIndex: 3,
      tabList: [],
      tab: [],
      type: [],
      center: { lng: 0, lat: 0 },
      zoom: 10,
      map: null,
      BMap: null,
      keyword: "地铁",
      typeList: ["地铁", "商场", "医疗", "休闲", "教育"],
      currentIndex: 0,
      infoList: [],
      currentIndexs: -1,
      videoIsPlay: false,
      arr: [],
      phoneindex: -1,
    };
  },
  mounted() {
    this.getNewhouseDetails();
    setTimeout(() => {
      this.infoList = JSON.parse(localStorage.getItem("arrList"));
    }, 1500);
    this.$nextTick(() => {
      if (this.houseInfo.speakVideos != "") {
        this.init();
      } else {
        return;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    // console.log(to);
    to.meta.keepAlive = true;
    next();
  },
  watch: {
    // 被侦听的变量keyword
    keyword() {
      setTimeout(() => {
        this.infoList = JSON.parse(localStorage.getItem("arrList"));
      }, 1500);
    },
    // router: {
    //   handler() {},
    //   deep: true,
    // },
  },
  methods: {
    changeVideoStatus() {
      const video = this.$refs.videoRef;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
    getRouteName(arg) {
      if (arg[1] == "NewHouseDetails") {
        this.getNewhouseDetails(arg[0]);
      }
    },
    getContact(index) {
      this.currentIndexs = index;
    },
    getLeave() {
      this.currentIndexs = -1;
    },
    getContactSee() {
      this.phoneindex = 1;
    },
    getLeavesSee() {
      this.phoneindex = 0;
    },
    // 初始化video
    init() {
      let dom_id = "myPlayer";
      this.myPlayerHls = videojs(dom_id, {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        sources: [
          {
            type: "application/x-mpegURL",
            src:
              this.houseInfo.speakVideos != ""
                ? this.houseInfo.speakVideos[0].url
                : "",
          },
        ],
      });
    },
    // 计算距离
    GetDistance(lat1, lng1, lat2, lng2) {
      let EARTH_RADIUS = 6378.137;
      let radLat1 = this.rad(lat1);
      let radLat2 = this.rad(lat2);
      let a = radLat1 - radLat2;
      let b = this.rad(lng1) - this.rad(lng2);
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      //乘以1000是换算成米
      return s;
    },
    rad(d) {
      return (d * Math.PI) / 180.0;
    },
    // 搜索房源周围信息
    search(arr) {
      let arrList = arr.Yr.slice(0, 5);
      arrList.forEach((item) => {
        let lng = item.point.lat;
        let lat = item.point.lng;
        item.long = this.GetDistance(
          Number(lng),
          Number(lat),
          Number(this.houseInfo.latitude),
          Number(this.houseInfo.longitude)
        ).toFixed(1);
      });
      localStorage.setItem("arrList", JSON.stringify(arrList));
    },
    clickTab(index, name) {
      this.keyword = name;
      this.currentIndex = index;
    },
    handler({ BMap, map, lat, lng }) {
      this.map = map;
      this.BMap = BMap;
      this.center.lng = this.houseInfo.longitude;
      this.center.lat = this.houseInfo.latitude;
      this.zoom = 15;
    },
    //左箭头按钮
    lefts() {
      if (this.numIndexs > 3) {
        if (this.swiper[0].id == this.swiperList[0].id) {
          this.swiperList.unshift(this.swiper[this.numIndexs - 4]); //前面添加一个
          this.swiperList.pop(this.swiperList[this.numIndexs]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
          this.numIndexs--;
        } else {
          return;
        }
      }
    },
    //右箭头按钮
    rights() {
      if (
        this.swiper[this.swiper.length - 1] ==
        this.swiperList[this.swiperList.length - 1]
      ) {
        return;
      } else {
        this.swiperList.shift(this.swiperList[this.numIndexs - 3]); //前面删除一个
        this.swiperList.push(this.swiper[this.numIndexs + 1]); //后面添加一个
        this.numIndexs++;
      }
    },
    handleChangeTab(index, item) {
      this.swiperPictures = item.imgarr;
    },
    handleGetTabList() {
      this.tabList = this.houseInfo.models;
      if (Array.isArray(this.tabList) && this.tabList.length > 4) {
        this.tab = this.tabList.slice(0, 4);
      } else {
        this.tab = this.tabList;
      }
      this.tab.forEach((item, index) => {
        this.arr.push(item.imgs[0]);
      });
    },
    //左箭头按钮
    left() {
      if (this.numIndex > 3) {
        if (this.tabList[0].id == this.tab[0].id) {
          return;
        } else {
          this.tab.unshift(this.tabList[this.numIndex - 4]); //前面添加一个
          this.tab.pop(this.tab[this.numIndex]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
          this.numIndex--;
        }
      }
    },
    //右箭头按钮
    right() {
      if (
        this.tabList[this.tabList.length - 1].id ==
        this.tab[this.tab.length - 1].id
      ) {
        return;
      } else {
        this.tab.shift(this.tab[this.numIndex - 3]); //前面删除一个
        this.tab.push(this.tabList[this.numIndex + 1]); //后面添加一个
        this.numIndex++;
      }
    },
    getMoreNewhouseDetails(id) {
      localStorage.setItem("ID", id);
      this.getNewhouseDetails();
      setTimeout(() => {
        this.infoList = JSON.parse(localStorage.getItem("arrList"));
      }, 2000);
    },
    getNewhouseDetails() {
      document.documentElement.scrollTop = 0;
      // 13
      getLoadNewhouse({ id: localStorage.getItem("ID") }).then((res) => {
        // console.log(res);
        if (res.success == true) {
          this.loading = false;
        }
        this.houseInfo = res.data;
        this.swiperList = [];
        this.swiper = [];
        let swipers = [
          { name: "效果图", imgarr: res.data.effectPictures },
          { name: "实景图", imgarr: res.data.realPictures },
          { name: "样板间", imgarr: res.data.exampleRooms },
          { name: "小区景观", imgarr: res.data.communitySet },
        ];
        swipers.forEach((item) => {
          if (item.imgarr.length != 0) {
            this.swiper.push(item);
          }
        });
        this.swiperPictures = this.swiper[0].imgarr;
        if (Array.isArray(this.swiper) && this.swiper.length > 4) {
          this.swiperList = this.swiper.slice(0, 4);
        } else {
          this.swiperList = this.swiper;
        }
        res.data.startTimes = js.formatDateTime(res.data.startTime);
        res.data.makeTimes = js.formatDateTime(res.data.makeTime);
        res.data.price1 = js.formatNumber(res.data.minPrice);
        res.data.price2 = js.formatNumber(res.data.maxPrice);
        // 更多新房
        res.data.newHouseRecommends.forEach((item) => {
          item.price1 = js.formatNumber(item.minPrice);
          item.price2 = js.formatNumber(item.maxPrice);
        });
        // 海外新房
        res.data.overseaHouseRecommends.forEach((item) => {
          item.price1 = js.formatNumber(item.minPriceCNY);
          item.price2 = js.formatNumber(item.maxPriceCNY);
        });
        res.data.water == 1
          ? (res.data.waters = "民用")
          : (res.data.waters = "商用");
        res.data.standard == 1
          ? (res.data.standard1 = "毛坯")
          : (res.data.standard1 = "带装修");
        res.data.gas == 0 ? (res.data.gas1 = "无") : (res.data.gas1 = "有");
        res.data.electricity == 1
          ? (res.data.electricitys = "民用")
          : (res.data.electricitys = "商用");

        switch (res.data.heating) {
          case 0:
            res.data.heating1 = "无";
            break;
          case 1:
            res.data.heating1 = "集中供暖";
            break;
          case 2:
            res.data.heating1 = "分散供暖";
            break;
          default:
        }
        switch (res.data.type) {
          case 1:
            res.data.way = "住宅";
            break;
          case 2:
            res.data.way = "商住";
            break;
          case 3:
            res.data.way = "商铺";
            break;
          case 4:
            res.data.way = "写字楼";
            break;
          case 5:
            res.data.way = "别墅";
            break;
          case 99:
            res.data.way = "其他";
            break;
          default:
        }
        this.swiperOptionTop.loopedSlides = res.data.itemPictures.length;
        this.swiperOptionThumbs.loopedSlides = res.data.itemPictures.length;
        this.handleGetTabList();
        this.handler(res.data.latitude, res.data.longitude);
        setTimeout(() => {
          this.infoList = JSON.parse(localStorage.getItem("arrList"));
        }, 1000);
        this.$nextTick(() => {
          if (this.houseInfo.speakVideos != "") {
            this.init();
          } else {
            return;
          }
        });
      });
    },
    // 跳转海外房产详情页
    getOverHouseDetails(id) {
      localStorage.setItem("ID", id);
      this.$router.push({ name: "OverSeaHouseDetails", params: { id } });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // console.log(to);
    // if()
    // to.meta.keepAlive = true;
    next();
  },
  beforeDestroy() {
    localStorage.removeItem("arrList");
    if (this.myPlayerHls) {
      const myPlayerHls = this.$refs.myPlayer; // 不能用document 获取节点
      videojs(myPlayerHls).dispose(); // 销毁video实例，避免出现节点不存在
    }
  },
};
</script>
<style lang="less" scoped>
.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  .btnLeft {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #000 #000 transparent transparent;
    border-style: solid;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-right: 26px;
    cursor: pointer;
  }
  .btnRight {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #000 #000 transparent transparent;
    border-style: solid;
    transform: matrix(0.75, 0.75, -0.75, 0.75, 0, 0);
    cursor: pointer;
  }
  .tab_content {
    width: 100%;
    font-size: 12px;
    color: rgb(255, 255, 255);
    display: flex;
    flex-wrap: no-wrap;
    overflow: hidden;
    .tab_item {
      width: 22%;
      height: 285px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 26px;
      font-size: 16px;
      padding-top: 16px;
      box-sizing: border-box;
      cursor: pointer;
      .imgBox {
        text-align: center;
        height: 196px;
        line-height: 196px;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 8px;
        img {
          width: 100%;
          height: 165px;
          object-fit: cover;
        }
      }
      p {
        line-height: 25px;
        margin-left: 12px;
        margin-right: 12px;
        font-weight: 500;
      }
    }
  }
}
i {
  /* 用border值来控制箭头粗细 */
  border: 5px solid #fff;
  /* 上、右、下、左  四个边框的宽度 */
  border-width: 0px 1px 1px 0px;
  display: inline-block;
  /* padding值控制箭头大小 */
  padding: 4px;
}
// 右箭头
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
// 左箭头
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.navigation {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: #3d3333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.Box {
  padding: 30px 160px;
  box-sizing: border-box;
  background-color: #fff;
  .Header {
    display: flex;
    justify-content: space-between;
    position: relative;
    .phone {
      cursor: pointer;
      padding: 0 13px;
      line-height: 32px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #da9525;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #da9525;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }
    .seePhone {
      border-radius: 1px;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #ccc;
      display: none;
      position: absolute;
      right: -45px;
      top: 40px;
      img {
        width: 130px;
        height: 130px;
      }
    }
    .activePhone {
      display: block;
    }
  }
}
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 16px;
}
.parametersBox {
  display: flex;
  padding-top: 32px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 12px;
  .swiperDiv {
    width: 55%;
    position: relative;
    margin-right: 24px;
    .swiperBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;
      .btnLeft {
        width: 8px;
        height: 8px;
        border-width: 1px;
        border-color: #3d3d3d #3d3d3d transparent transparent;
        border-style: solid;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-top: 40px;
        margin-right: 20px;
        cursor: pointer;
      }
      .btnRight {
        width: 8px;
        height: 8px;
        border-width: 1px;
        border-color: #3d3d3d #3d3d3d transparent transparent;
        border-style: solid;
        transform: matrix(0.75, 0.75, -0.75, 0.75, 0, 0);
        margin-top: 40px;
        margin-left: 20px;
        cursor: pointer;
      }
      .tab_content {
        width: 100%;
        font-size: 12px;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: flex-start;
        flex-wrap: no-wrap;
        overflow: hidden;
        .tab_item {
          width: 23%;
          font-size: 16px;
          text-align: center;
          margin-right: 8px;
          cursor: pointer;
          .imgBox {
            text-align: center;
            margin-bottom: 8px;
            img {
              width: 100%;
              height: 96px;
              object-fit: cover;
            }
          }
          p {
            line-height: 25px;
            margin-left: 12px;
            font-weight: 500;
          }
        }
      }
    }
    .swiper-button-prev {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
      cursor: pointer;
    }
    .swiper-button-next {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
      cursor: pointer;
    }
    .thumb-example {
      height: 580px;
      background-color: transparent;
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
      img {
        width: 100%;
        height: 394px;
        // object-fit: cover;
        margin-bottom: 12px;
      }
    }
    .gallery-top {
      width: 100%;
    }
    .gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    .gallery-thumbs .swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.4;
      img {
        width: 150px;
        height: 100%;
      }
    }
    .gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }
  .infoBox {
    width: 40%;
    .newHouse {
      display: flex;
      justify-content: space-between;
      p {
        span {
          display: block;
        }
      }
    }
    .name {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 54px;
      span {
        display: inline-block;
        padding: 0 5px;
        box-sizing: border-box;
        height: 22px;
        text-align: center;
        line-height: 22px;
        margin-left: 6px;
        font-size: 13px;
        color: #fff;
      }
    }
    .price {
      font-size: 38px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 500;
      color: #da9525;
      margin-bottom: 38px;
    }
    .area {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 20px;
    }
    .detailedInfo {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 20px;
      box-sizing: border-box;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 40px;
      }
    }
    .address {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 40px;
    }
  }
}
.video-container {
  width: 80%;
  margin-bottom: 56px;
  position: relative;
  .start-play {
    z-index: 99;
    cursor: pointer;
    width: 63px;
    height: 63px;
    background: linear-gradient(135deg, #7f8081 0%, #28282c 100%);
    opacity: 0.7;
    border-radius: 50%;
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border: 13px solid transparent;
      border-left: 19px solid white;
      position: absolute;
      top: 32%;
      left: 40%;
    }
  }
}
.zs-adv {
  margin: 50px auto 0;
  width: 1272px;
  height: 302px;
  vertical-align: middle;

  a {
    margin-top: 58px;
    width: 16px;
    height: 24px;
    opacity: 0.8;
  }

  a:hover {
    opacity: 1;
  }

  .adv-pre {
    float: left;
    margin-right: 20px;
  }

  .adv-next {
    float: right;
  }
  #adv-pad-scroll {
    float: left;
    width: 1200px;
    overflow: hidden;
    .adv-pad {
      width: 2400px;
      height: 120px;
      .adv-pad-item {
        padding: 20px 10px 0px 10px;
        width: 400px;
        height: 100px;
        cursor: pointer;
        animation: all 1.5s;
      }
      .adv-pad-item:hover {
        padding: 10px 5px 0px 10px;
      }
    }
  }
}
.propertyBox {
  width: 100%;
  height: 176px;
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
  padding-left: 24px;
  box-sizing: border-box;
  margin-bottom: 56px;
  border-radius: 4px;
  p {
    line-height: 32px;
  }
}
.agentBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 36px;
  box-sizing: border-box;
  .itemBox {
    width: 360px;
    display: flex;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #f9fafe;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .adescBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e9ac33;
          margin-right: 10px;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          line-height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          box-sizing: border-box;
          cursor: pointer;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
.descBox {
  width: 100%;
  padding: 22px 24px;
  box-sizing: border-box;
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 30px;
  margin-bottom: 56px;
  white-space: pre-wrap;
  border-radius: 4px;
}
.mapBox {
  height: 388px;
  margin-bottom: 56px;
  position: relative;

  ::v-deep .anchorBL {
    display: none !important;
  }
  .bm-view {
    width: 100%;
    height: 388px;
  }
  .mapType {
    width: 358px;
    height: 372px;
    border-radius: 4px;
    padding: 26px 16px;
    box-sizing: border-box;
    position: absolute;
    right: 8px;
    top: 8px;
    background-color: #fff;
    .typeTitle {
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 17px;
      span {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 6px;
      }
      .tab-current {
        color: #da9525;
        font-weight: 500;
      }
    }
    .infocontent {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: top;
      div {
        .leftName {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
        }
        .leftBom {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
.moreNewHouse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  .itemMhouse {
    width: 30%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    img {
      width: 100%;
      height: 213px;
      margin-bottom: 12px;
      cursor: pointer;
    }
    div {
      padding-left: 16px;
      box-sizing: border-box;
      .name {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
      .area {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 10px;
      }
      .price {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #da9525;
        padding-bottom: 15px;
      }
    }
  }
}
.moreOverHouse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  .itemMhouse {
    width: 30%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    img {
      width: 100%;
      height: 213px;
      margin-bottom: 12px;
      cursor: pointer;
    }
    div {
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      .name {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: space-between;
        img {
          width: 13px;
          height: 13px;
          margin-right: 3px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .area {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 10px;
      }
      .price {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #da9525;
        padding-bottom: 15px;
      }
    }
  }
}
</style>
