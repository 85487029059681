<template>
  <div
    class="box"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="navigation">
      <img class="logo" src="../img/home/logo1.png" alt="" />
      <el-menu
        :default-active="activeIndex2"
        mode="horizontal"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">二手房</el-menu-item>
        <el-menu-item index="3">新房</el-menu-item>
        <el-menu-item index="4">租房</el-menu-item>
        <el-menu-item index="5">海外房产</el-menu-item>
        <el-menu-item index="6">查楼盘</el-menu-item>
        <el-menu-item index="7">我要卖房</el-menu-item>
        <el-menu-item index="8">我要找房</el-menu-item>
        <el-menu-item index="9">企业介绍</el-menu-item>
      </el-menu>
    </div>
    <div class="containbox">
      <div class="areaSearch">
        <div class="searchbox">
          <input
            class="searchinput"
            v-model="word"
            @keyup.enter="getSearchItems"
            @input="getSearchItems"
          />
          <span class="searchbtn" @click="getSearchItems()">搜索</span>
          <el-card v-if="searchItems.length" class="box-cards">
            <div
              v-for="item in searchItems"
              :key="item"
              @click="clickItem(item)"
              class="searchitem"
            >
              {{ item }}
            </div>
          </el-card>
        </div>
        <div>
          <div class="row">
            <div class="name">区域：</div>
            <div class="content">
              <div
                v-for="(item, index) in PlatformList"
                :key="index"
                @click="getPlace(item)"
                :class="['item', PlatformVar == item.name ? 'actvCss' : '']"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="name">面积：</div>
            <div class="content">
              <div
                v-for="(item, index) in AreaList"
                :key="index"
                @click="getArea(item)"
                :class="['item', AreaVar == item ? 'actvCss' : '']"
              >
                {{ item }}
              </div>
              <el-form style="display: flex; align-items: center" size="mini">
                <el-form-item>
                  <el-input v-model="minAreas" width="50px"></el-input>
                </el-form-item>
                <span
                  class="line"
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 2px;
                    background: #ccc;
                    margin-left: 5px;
                    margin-right: 5px;
                  "
                ></span>
                <el-form-item>
                  <el-input v-model="maxAreas"></el-input>
                </el-form-item>
                <span
                  style="
                    display: inline-block;
                    margin-left: 10px;
                    width: 48px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    background: #e9c562;
                    border-radius: 2px;
                    color: #fff;
                    font-size: 14px;
                    cursor: pointer;
                  "
                  @click="getWriteArea"
                  >确定</span
                >
              </el-form>
            </div>
          </div>
          <div class="row">
            <div class="name">售价：</div>
            <div class="content">
              <div
                v-for="(item, index) in SellingPrice"
                :key="index"
                @click="getPrice(item)"
                :class="['item', SellingPriceVal == item ? 'actvCss' : '']"
              >
                {{ item }}
              </div>
              <el-form style="display: flex; align-items: center" size="mini">
                <el-form-item>
                  <el-input v-model="minPrices" width="50px"></el-input>
                </el-form-item>
                <span
                  class="line"
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 2px;
                    background: #ccc;
                    margin-left: 5px;
                    margin-right: 5px;
                  "
                ></span>
                <el-form-item>
                  <el-input v-model="maxPrices"></el-input>
                </el-form-item>
                <span
                  style="
                    display: inline-block;
                    margin-left: 10px;
                    width: 48px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    background: #e9c562;
                    border-radius: 2px;
                    color: #fff;
                    font-size: 14px;
                    cursor: pointer;
                  "
                  @click="getWritePrice"
                  >确定</span
                >
              </el-form>
            </div>
          </div>
          <div class="row">
            <div class="name">居室：</div>
            <div class="content">
              <div
                v-for="(item, index) in RoomTypeList"
                :key="index"
                @click="getRooms(item)"
                :class="['item', RoomVar == item ? 'actvCss' : '']"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sortBox">
        <div class="sortLeft">
          <span>新房0佣金</span>
        </div>
        <div class="sortRight">
          <div
            v-for="(item, index) in sortList"
            :key="index"
            v-bind:class="{ colorChange: index == dynamic }"
            @click="getSort(item, index)"
          >
            {{ item }}
            <img
              v-if="
                (item == '售价' && index == dynamic && isShow == true) ||
                (item == '面积' && index == dynamic && isShow == true)
              "
              src="../img/home/up.png"
              alt=""
            />
            <img
              v-if="
                (item == '售价' && index == dynamic && isShow == !true) ||
                (item == '面积' && index == dynamic && isShow == !true)
              "
              src="../img/home/done.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="dataListBox" v-if="DataList.length">
        <div
          class="list"
          v-for="(item, index) in DataList"
          :key="index"
          @click="getNewHouseDetails(item.id)"
        >
          <img class="imgleft" :src="item.cover" alt="" />
          <div style="width: 70%">
            <p class="title">{{ item.name }}</p>
            <div class="areabox">
              <img
                style="width: 16px; height: 16px"
                src="../img/home/house.png"
                alt=""
              />&nbsp; <span>{{ item.minBuildArea }}m²</span>-<span
                >{{ item.maxBuildArea }}m²</span
              >&nbsp; |&nbsp; <span>&nbsp;{{ item.structure }}</span>
            </div>
            <div class="dressbox">
              <div style="display: flex; align-items: center">
                <img
                  style="width: 16px; height: 16px"
                  src="../img/home/place.png"
                  alt=""
                />&nbsp;
                <div>
                  <span>{{ item.regionName }}</span>
                  <span v-if="item.businessArea"
                    >·{{ item.businessArea.name }}</span
                  >
                </div>
              </div>
              <div class="prices">
                <span>{{ item.minprices }}</span
                >万-<span>{{ item.maxprices }}</span
                >万
              </div>
            </div>
            <div class="labelBox" v-if="item.labels.length >= 2">
              <span>{{ item.labelOne }}</span>
              <span>{{ item.labelTwo }}</span>
            </div>
            <div class="labelBox" v-if="item.labels.length == 1">
              <span>{{ item.labelOne }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="nullCss">暂无相关房源~</div>
      <scrollTop></scrollTop>
      <el-pagination
        style="float: right"
        v-if="DataList.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="20"
        layout=" prev, pager, next"
        :total="total"
        background
      >
      </el-pagination>
      <div style="clear: both"></div>
      <div class="agentBox">
        <div class="itemBox" v-for="(item, index) in agentList" :key="index">
          <img class="icon" :src="item.icon" alt="" />
          <div class="descBox">
            <p class="name">
              {{ item.name }} <span>{{ item.count }}分</span>
            </p>
            <p class="department">{{ item.departmentName }}</p>
            <div class="label">
              <span class="leftbtn">{{ item.job }}</span>
              <span
                class="code"
                @mouseenter="getContact(index)"
                @mouseleave="getLeave()"
                >扫码联系
                <img class="codeImg" src="../img/home/code.png" alt=""
              /></span>
            </div>
            <div
              class="contactCode"
              :class="{ activePhone: index == currentIndex }"
            >
              <img :src="item.wxHomeImg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <el-card class="box-card" v-if="isShowCard">
        <div class="header">
          <div>
            <p class="titleTop">没有合适的?试试</p>
            <p class="titleBom">人工找房</p>
          </div>
          <i @click="isShowCard = false" class="el-icon-close"></i>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item prop="name">
            <el-input
              placeholder="请输入您的姓名"
              v-model="ruleForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sex">
            <el-select v-model="ruleForm.sex" placeholder="请选择您的性别">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="phone" class="codeBox">
            <el-input placeholder="手机号" v-model="ruleForm.phone"> </el-input>
            <span @click.capture="getCode" v-if="show" class="codeBtn"
              >获取验证码</span
            >
            <span class="codeBtn" v-if="!show">{{ count }}s后面获取</span>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              placeholder="请输入验证码"
              v-model="ruleForm.code"
            ></el-input>
          </el-form-item>
          <el-form-item prop="type">
            <el-select v-model="ruleForm.type" placeholder="请选择您的需求类型">
              <el-option label="购房 — 二手房" value="1"></el-option>
              <el-option label="购房 — 新房" value="2"></el-option>
              <el-option label="租房" value="3"></el-option>
              <el-option label="海外房产" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="请输入您的意向区域"
              v-model="ruleForm.beat"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="请输入您的意向小区"
              v-model="ruleForm.village"
            ></el-input>
          </el-form-item>
          <el-form-item prop="area">
            <el-input
              placeholder="请输入您的意向面积"
              v-model="ruleForm.area"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="请输入您的意向房型"
              v-model="ruleForm.apartment"
            ></el-input>
          </el-form-item>
          <el-form-item prop="price">
            <el-input
              placeholder="请输入您的预算价格"
              v-model="ruleForm.price"
            ></el-input>
          </el-form-item>
          <div class="submitBtn" @click="getSubmit('ruleForm')">提交</div>
        </el-form>
      </el-card>
    </div>
    <Footer />
  </div>
</template>
<script>
import js from "@/utils/utils";
import scrollTop from "@/components/Scrolltop.vue";
import pagination from "@/components/Pagination.vue";
import Footer from "@/components/Footer.vue";
import {
  getAreaInfo,
  searchThinkItems,
  getPager,
  getHome,
  getDemandCode,
} from "../api/api";
export default {
  name: "NewHouse",
  components: { pagination, Footer, scrollTop },
  data() {
    return {
      activeIndex2: "3",
      word: "",
      searchItems: [],
      regionId: 0,
      minArea: "",
      maxArea: "",
      minAreas: "",
      maxAreas: "",
      minPrice: "",
      maxPrice: "",
      minPrices: "",
      maxPrices: "",
      room: "",
      PlatformVar: "不限", // 表示当前选中的区域
      AreaVar: "不限", // 表示当前选中的面积
      RoomVar: "不限", // 表示当前选中的类型
      SellingPriceVal: "不限", // 表示当前选中的价格
      searchWord: "",
      PlatformList: [], // 平台
      AreaList: [
        "不限",
        "100m²以下",
        "100~150m²",
        "150~200m²",
        "200~250m²",
        "250~300m²",
        "300m²以上",
      ], // 面积
      SellingPrice: [
        "不限",
        "1000万以下",
        "1000~2000万",
        "2000~3000万",
        "3000~5000万",
        "5000~8000万",
        "8000万以上",
      ], // 售价
      RoomTypeList: ["不限", "一室", "二室", "三室", "四室", "五室以上"], // 居室
      DataList: [], //列表数据
      loading: true,
      total: 0, //总条目数
      currentPage: 1, //当前页数
      pageNumber: 1, // 当前页 默认设值1
      pageSize: 20, // 每页显示条目 默认设置5
      sortList: ["综合", "最新", "售价", "面积"], //综合hot 最新utime 售价minPrice,maxPrice 面积minBuildArea,maxBuildArea
      sortType: "",
      sort: "desc", //正序 asc (越来越高) 倒序 desc (越来越低)
      dynamic: 0,
      agentList: "",
      isShow: true,
      isShowCard: true,
      ruleForm: {
        name: "",
        sex: "",
        phone: "",
        code: "",
        type: "",
        beat: "",
        village: "",
        area: "",
        apartment: "",
        price: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        code: [{ required: true, message: "请输入校验码", trigger: "blur" }],
        type: [
          { required: true, message: "请选择您的需求类型", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请输入您的意向面积", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入您的预算价格", trigger: "blur" },
        ],
      },
      show: true,
      timer: null,
      count: "",
      currentIndex: -1,
    };
  },
  mounted() {
    this.getAreaInfo();
    this.getSellPager(1);
    this.agentList = JSON.parse(localStorage.getItem("agentList"));
  },
  methods: {
    // 提交
    getSubmit(formName) {
      // console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          getHome(this.ruleForm).then((res) => {
            // console.log(res);
            this.ruleForm = {};
            this.$alert("提交成功，请留意我司经纪人来电", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
          });
        } else {
          return false;
        }
      });
    },
    getCode() {
      var regex =
        /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/;
      if (!regex.test(this.ruleForm.phone)) {
        this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
      } else {
        getDemandCode({ phone: this.ruleForm.phone }).then((res) => {
          console.log(res);
          if (res.success == true) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      }
    },
    getContact(index) {
      this.currentIndex = index;
    },
    getLeave() {
      this.currentIndex = -1;
    },
    // 综合hot 最新utime 售价minPrice,maxPrice 面积minBuildArea,maxBuildArea
    getSort(item, index) {
      this.loading = true;
      this.sort = "desc";
      if (item == "面积") {
        this.sort = "desc";
        this.isShow = !this.isShow;
        if (this.isShow == true) {
          this.sortType = "maxBuildArea";
          this.sort = "desc";
        } else {
          this.sort = "asc";
          this.sortType = "minBuildArea";
        }
      } else if (item == "最新") {
        this.sortType = "utime";
      } else if (item == "售价") {
        this.sort = "desc";
        if (this.isShow == true) {
          this.sortType = "maxPrice";
          this.sort = "desc";
        } else {
          this.sortType = "minPrice";
          this.sort = "asc";
        }
        this.isShow = !this.isShow;
      } else {
        this.sortType = "score";
      }
      this.dynamic = index;
      this.getSellPager(1);
    },
    // 区域查询
    getPlace(item) {
      this.regionId = item.id;
      this.PlatformVar = item.name;
      this.getSellPager(1);
    },
    // 面积查询
    getArea(item) {
      if (item == "100m²以下") {
        this.minArea = 0;
        this.maxArea = 100;
      } else if (item == "100~150m²") {
        this.minArea = 100;
        this.maxArea = 150;
      } else if (item == "150~200m²") {
        this.minArea = 150;
        this.maxArea = 200;
      } else if (item == "200~250m²") {
        this.minArea = 200;
        this.maxArea = 250;
      } else if (item == "250~300m²") {
        this.minArea = 250;
        this.maxArea = 300;
      } else if (item == "300m²") {
        this.minArea = 300;
        this.maxArea = 0;
      } else {
        this.minArea = "";
        this.maxArea = "";
      }
      this.AreaVar = item;
      this.minAreas = "";
      this.maxAreas = "";
      this.getSellPager(this.pageNumber);
    },
    getWriteArea() {
      this.minArea = this.minAreas;
      this.maxArea = this.maxAreas;
      this.AreaVar = "";
      this.getSellPager(1);
    },
    // 售价查询
    getPrice(item) {
      if (item == "1000万以下") {
        this.minPrice = 0;
        this.maxPrice = 1000;
      } else if (item == "1000~2000万") {
        this.minPrice = 1000;
        this.maxPrice = 2000;
      } else if (item == "2000~3000万") {
        this.minPrice = 2000;
        this.maxPrice = 3000;
      } else if (item == "3000~5000万") {
        this.minPrice = 3000;
        this.maxPrice = 5000;
      } else if (item == "5000~8000万") {
        this.minPrice = 5000;
        this.maxPrice = 8000;
      } else if (item == "8000万以上") {
        this.minPrice = 8000;
        this.maxPrice = 0;
      } else {
        this.minPrice = "";
        this.maxPrice = "";
      }
      this.SellingPriceVal = item;
      this.minPrices = "";
      this.maxPrices = "";
      this.getSellPager(1);
    },
    getWritePrice() {
      this.minPrice = this.minPrices;
      this.maxPrice = this.maxPrices;
      this.SellingPriceVal = "";
      this.getSellPager(1);
    },
    getRooms(item) {
      this.RoomVar = item;
      this.room = js.getRoom(item);
      this.getSellPager(1);
    },
    // 区域获取
    getAreaInfo() {
      getAreaInfo({ type: 3 }).then((res) => {
        res.data.unshift({ id: 0, name: "不限" });
        this.PlatformList = res.data;
      });
    },
    // 模糊搜索
    getSearchItems(e) {
      searchThinkItems({ word: this.word }).then((res) => {
        this.searchItems = res.data;
        if (res.data.length == 0) {
          this.getSellPager(1);
        }
      });
    },
    clickItem(item) {
      this.word = item;
      this.searchItems = [];
      this.getSellPager(1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSellPager(this.pageNumber);
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNumber = val;
      this.getSellPager(this.pageNumber);
      // console.log(`当前页: ${val}`);
    },
    getSellPager(pageNumber) {
      getPager({
        searchWord: this.word,
        pageNumber: pageNumber,
        regionId: this.regionId,
        minArea: this.minArea,
        maxArea: this.maxArea,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        room: this.room,
        sortType: this.sortType,
        sort: this.sort,
      }).then((res) => {
        // console.log(res);
        res.data.forEach((item) => {
          item.orientations = js.getDirection(item.orientation);
          item.decorates = js.getDecorate(item.decorate);
          item.minprices = js.formatNumber(item.minPrice);
          item.maxprices = js.formatNumber(item.maxPrice);
          if (item.labels.length >= 2) {
            item.labelOne = item.labels[0].name;
            item.labelTwo = item.labels[1].name;
          } else if (item.labels.length == 1) {
            item.labelOne = item.labels[0].name;
          }
        });
        this.loading = false;
        this.DataList = res.data;
        this.total = res.totalData;
      });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
    // 跳转新房详情页
    getNewHouseDetails(id) {
      localStorage.setItem("ID", id);
      this.$router.push({ name: "NewHouseDetails", params: { id } });
    },
  },
  destroyed() {
    window.onscroll = null;
  },
};
</script>
<style lang="less" scoped>
.box {
  // padding: 50px 100px;
  box-sizing: border-box;
}
.box-card {
  position: fixed;
  right: 8px;
  bottom: 8px;
  width: 360px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(141, 94, 1, 0.1);
  border-radius: 8px;
  border: 1px solid #e9b869;
  z-index: 99;
  height: 75%;
  overflow: hidden;
  overflow-y: scroll;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .titleTop {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
    }
    .titleBom {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
  /deep/.el-input_inner {
    border: 0 none;
  }
  /deep/.el-input__inner {
    width: 312px;
    height: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .codeBox {
    position: relative;
    .codeBtn {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #da9525;
      position: absolute;
      right: 16px;
      cursor: pointer;
    }
  }
  .submitBtn {
    width: 312px;
    height: 40px;
    line-height: 40px;
    background: #da9525;
    border-radius: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}
.navigation {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #3d3333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.containbox {
  padding: 3.5% 11%;
  box-sizing: border-box;
  .areaSearch {
    width: 100%;
    background: #fbfbfb;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    padding: 25px 55px;
    box-sizing: border-box;
  }
  .sortBox {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    padding-top: 40px;
    padding-bottom: 7px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    .sortLeft {
      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        letter-spacing: 3px;
      }
    }
    .sortRight {
      display: flex;
      div {
        margin-left: 24px;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 10px;
          height: 18px;
          margin-left: 3px;
          vertical-align: middle;
          padding-top: 2px;
        }
      }
      .colorChange {
        color: #d4a523;
      }
    }
  }
  .dataListBox {
    padding-bottom: 15px;
    .list {
      display: flex;
      padding: 32px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      .imgleft {
        width: 241px;
        height: 176px;
        margin-right: 24px;
      }
      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 550;
        color: #000000;
        padding-bottom: 16px;
      }
      .areabox {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        box-sizing: border-box;
      }
      .dressbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 38px;
        box-sizing: border-box;
      }
      .prices {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #e49700;
        span {
          font-size: 24px;
          color: #e49700;
          font-weight: 500;
        }
      }
      .labelBox {
        span {
          display: inline-block;
          width: 96px;
          height: 32px;
          line-height: 32px;
          background: #f5dad6;
          border-radius: 2px;
          color: #ff2700;
          text-align: center;
          margin-right: 16px;
        }
      }
    }
  }
}
.searchbox {
  float: right;
  width: 560px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  .searchinput {
    outline: none;
    border: none;
    width: 465px;
    height: 38px;
    margin-right: 10px;
    padding-left: 5px;
    box-sizing: border-box;
  }
  .searchbtn {
    display: inline-block;
    width: 82px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    background: #e9c562;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
  }
  .el-card {
    width: 559px;
    position: absolute;
    top: 40px;
    z-index: 99;
    .searchitem {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}
.row {
  height: 100%;
  padding: 10px;
}
.row .content {
  // flex: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
}
.row .name {
  font-size: 14px;
  font-weight: 600;
}
.row .content .item {
  background: none;
  font-size: 14px;
}

.row .content .actvCss {
  background: none;
  color: #dbb03a;
  font-weight: 600;
  font-size: 14px;
}
.el-input {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.el-form-item--mini.el-form-item {
  margin-bottom: 0;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #e9ac33;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e9ac33;
  color: #fff;
}
.agentBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 45px;
  box-sizing: border-box;
  .itemBox {
    display: flex;
    width: 360px;
    height: 146px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .descBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
          color: #e9ac33;
          margin-right: 10px;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          line-height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          cursor: pointer;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
</style>
