<template>
  <div class="box">
    <video class="video" autoplay muted loop src="../img/video.mp4"></video>
    <img class="logo" src="../img/logo.png" alt="" />
    <img class="bast" src="../img/bester.png" alt="" />
    <img class="dec" src="../img/dec.png" alt="" />
    <div class="tohome" @click="toHome">进入首页</div>
    <div class="typeBox">
      <div class="itemBox">
        <div class="items">
          <div class="item" style="width: 80px">
            <CountNumber :number="83"></CountNumber> <span>个</span>
          </div>
          <p>主营楼盘</p>
        </div>
        <div class="items">
          <div class="item" style="width: 80px">
            <CountNumber :number="9"></CountNumber> <span>家</span>
          </div>
          <p>实体门店</p>
        </div>
        <div class="items">
          <div class="item" style="width: 120px">
            <CountNumber :number="300"></CountNumber> <span>+</span>
          </div>
          <p>置业顾问</p>
        </div>
        <div class="items">
          <div class="item" style="width: 160px">
            <CountNumber :number="1500"></CountNumber> <span>+</span>
          </div>
          <p>成交案例</p>
        </div>
      </div>
    </div>
    <div class="record">
      上海百廷房地产经纪有限公司 @2020备案号 沪ICP18036812号-2
      沪公网安备31011502010588号
    </div>
  </div>
</template>
<script>
import CountNumber from "@/components/CountNumber.vue";
export default {
  components: { CountNumber },
  data() {
    return {};
  },
  methods: {
    define(e) {},
    toHome() {
      this.$router.push("/Home");
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  .video {
    width: 100vw;
    height: 100vh;
    // background: url("../img/背景视频.mp4") no-repeat center;
    // background-size: 100% 100%;
    position: relative;
    object-fit: fill;
  }
  .logo {
    width: 132px;
    height: 43px;
    position: absolute;
    top: 40px;
    left: 56px;
  }
  .bast {
    width: 156px;
    height: 16px;
    position: absolute;
    top: 106px;
    left: 50%;
    transform: translateX(-50%);
  }
  .dec {
    width: 681px;
    height: 145px;
    position: absolute;
    top: 148px;
    left: 50%;
    transform: translateX(-50%);
  }
  .tohome {
    cursor: pointer;
    width: 160px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 16px;
    font-family: SimSong-Bold, SimSong;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 335px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .typeBox {
    position: absolute;
    bottom: 99px;
    width: 100%;
    padding-left: 18%;
    padding-right: 18%;
    box-sizing: border-box;
    .itemBox {
      display: flex;
      justify-content: space-around;
      .items {
        margin-right: 10%;
      }
      p {
        color: #fff;
        text-align: center;
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: "苹方-简 常规体";
          color: #fff;
        }
      }
    }
  }
  .record {
    padding-top: 59px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.3;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.qrcode {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .item {
    text-align: center;
    img {
      width: 100px;
      height: 100px;
    }
    .big {
      width: 200px;
      height: 200px;
      position: absolute;
      bottom: -30px;
    }
  }
}
</style>
