<template>
  <div
    class="box"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div style="width: 100%; height: 800px">
      <div class="navigation" ref="navBox">
        <img class="logo" src="../img/home/logo1.png" alt="" />
        <el-menu
          :default-active="activeIndex2"
          mode="horizontal"
          @select="handleSelect"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">二手房</el-menu-item>
          <el-menu-item index="3">新房</el-menu-item>
          <el-menu-item index="4">租房</el-menu-item>
          <el-menu-item index="5">海外房产</el-menu-item>
          <el-menu-item index="6">查楼盘</el-menu-item>
          <el-menu-item index="7">我要卖房</el-menu-item>
          <el-menu-item index="8">我要找房</el-menu-item>
          <el-menu-item index="9">企业介绍</el-menu-item>
        </el-menu>
      </div>
      <div class="typeBox">
        <div class="typeBtn">
          <span @click="prev" :class="ruleForm.type == 1 ? 'activated' : ''"
            >出售</span
          >
          <span @click="next" :class="ruleForm.type == 2 ? 'activated' : ''"
            >出租</span
          >
        </div>
        <el-card
          class="box-card"
          :class="ruleForm.type == 1 ? 'active' : 'noactive'"
        >
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
          >
            <el-form-item prop="name">
              <span>您的称呼</span>
              <el-input
                placeholder="请输入您的姓名"
                v-model="ruleForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item prop="sex">
              <span style="">性别</span>
              <el-select v-model="ruleForm.sex" placeholder="请选择您的性别">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="phone" class="codeBox">
              <span>手机号</span>
              <el-input placeholder="请输入手机号" v-model="ruleForm.phone">
              </el-input>
              <span
                style="cursor: pointer"
                @click.capture="getCode"
                v-if="show"
                class="codeBtn"
                >获取验证码</span
              >
              <span class="codeBtn" v-if="!show">{{ count }}s后获取</span>
            </el-form-item>
            <el-form-item prop="code">
              <span>验证码</span>
              <el-input
                placeholder="请输入验证码"
                v-model="ruleForm.code"
              ></el-input>
            </el-form-item>
            <el-form-item prop="uptown">
              <span>小区名称</span>
              <el-input
                placeholder="请输入您的小区名称"
                v-model="ruleForm.uptown"
              ></el-input>
            </el-form-item>
            <el-form-item prop="area">
              <span>房源面积</span>
              <el-input
                placeholder="请输入您的意向面积"
                v-model="ruleForm.area"
              ></el-input>
            </el-form-item>
            <el-form-item prop="price" style="position: relative">
              <span>拟定售价</span>
              <el-input
                placeholder="请输入您的预算价格"
                v-model="ruleForm.price"
              ></el-input>
              <span
                class="codeBtn"
                style="position: absolute; top: 0; right: 5px"
                >万元</span
              >
            </el-form-item>
            <div class="submitBtn" @click="getSubmit('ruleForm')">提交</div>
          </el-form>
        </el-card>
        <el-card
          class="box-card"
          :class="ruleForm.type == 2 ? 'active' : 'noactive'"
        >
          <el-form
            :model="ruleFormRent"
            :rules="rulesRent"
            ref="ruleFormRent"
            label-width="100px"
          >
            <el-form-item prop="name">
              <span>您的称呼</span>
              <el-input
                placeholder="请输入您的姓名"
                v-model="ruleFormRent.name"
              ></el-input>
            </el-form-item>
            <el-form-item prop="sex">
              <span style="">性别</span>
              <el-select
                v-model="ruleFormRent.sex"
                placeholder="请选择您的性别"
              >
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="phone" class="codeBox">
              <span>手机号</span>
              <el-input placeholder="请输入手机号" v-model="ruleFormRent.phone">
              </el-input>
              <span @click.capture="getCode" v-if="show" class="codeBtn"
                >获取验证码</span
              >
              <span class="codeBtn" v-if="!show">{{ count }}s后获取</span>
            </el-form-item>
            <el-form-item prop="code">
              <span>验证码</span>
              <el-input
                placeholder="请输入验证码"
                v-model="ruleFormRent.code"
              ></el-input>
            </el-form-item>
            <el-form-item prop="uptown">
              <span>小区名称</span>
              <el-input
                placeholder="请输入您的小区名称"
                v-model="ruleFormRent.uptown"
              ></el-input>
            </el-form-item>
            <el-form-item prop="area">
              <span>房源面积</span>
              <el-input
                placeholder="请输入您的意向面积"
                v-model="ruleFormRent.area"
              ></el-input>
            </el-form-item>
            <el-form-item prop="price" style="position: relative">
              <span>拟定租金</span>
              <el-input
                placeholder="请输入您的预算价格"
                v-model="ruleFormRent.price"
              ></el-input>
              <span
                class="codeBtn"
                style="position: absolute; top: 0; right: 5px"
                >元/月</span
              >
            </el-form-item>
            <div class="submitBtn" @click="getSubmit('ruleFormRent')">提交</div>
          </el-form>
        </el-card>
      </div>
    </div>
    <div class="agentBox">
      <div class="itemBox" v-for="(item, index) in agentList" :key="index">
        <img class="icon" :src="item.icon" alt="" />
        <div class="descBox">
          <p class="name">
            {{ item.name }} <span>{{ item.count }}分</span>
          </p>
          <p class="department">{{ item.departmentName }}</p>
          <div class="label">
            <span class="leftbtn">{{ item.job }}</span>
            <span
              class="code"
              @mouseenter="getContact(index)"
              @mouseleave="getLeave()"
              >扫码联系 <img class="codeImg" src="../img/home/code.png" alt=""
            /></span>
          </div>
          <div
            class="contactCode"
            :class="{ activePhone: index == currentIndex }"
          >
            <img :src="item.wxHomeImg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import js from "@/utils/utils";
import Footer from "@/components/Footer.vue";
import { getSellCode, getSellHome } from "../api/api";
let elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: "",
  components: { Footer },
  props: {
    // 显示几列
    column: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 滚动了几列
      roll: 0,
      // 每一列的宽度
      widthItem: 0,
      // 盒子总宽度
      widthBox: 0,

      activeIndex2: "7",
      loading: false,
      agentList: [],
      ruleForm: {
        name: "",
        sex: "",
        phone: "",
        code: "",
        type: 1,
        uptown: "",
        area: "",
        price: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        code: [{ required: true, message: "请输入校验码", trigger: "blur" }],
        uptown: [
          { required: true, message: "请输入小区名称", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请输入您的意向面积", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入您的预算价格", trigger: "blur" },
        ],
      },
      ruleFormRent: {
        name: "",
        sex: "",
        phone: "",
        code: "",
        type: 2,
        uptown: "",
        area: "",
        price: "",
      },
      rulesRent: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        code: [{ required: true, message: "请输入校验码", trigger: "blur" }],
        uptown: [
          { required: true, message: "请输入小区名称", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请输入您的意向面积", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入您的预算价格", trigger: "blur" },
        ],
      },
      show: true,
      timer: null,
      count: "",
      scrollTop: "",
      currentIndex: -1,
      transitionName: "transitionLeft",
    };
  },
  mounted() {
    // let erd = elementResizeDetectorMaker();
    // let _this = this;
    // // 监听box元素宽度改变
    // erd.listenTo(this.$refs.box, function (element) {
    //   _this.widthBox = element.offsetWidth;
    //   // 计算每一列占多少行
    //   _this.widthItem = element.offsetWidth / _this.column;
    // });

    window.document.body.onscroll = () => {
      this.scrollTop = document.documentElement.scrollTop;
      if (document.documentElement.scrollTop < 100) {
        this.$refs.navBox.style.backgroundColor = "transparent";
      } else {
        this.$refs.navBox.style.backgroundColor = "#333";
      }
    };
    this.agentList = JSON.parse(localStorage.getItem("agentList"));
  },
  computed: {
    scrollX() {
      // flex布局时计算宽度，某些情况下会出现小数点，对比宽度比总宽度小1，使右滑出问题
      return Math.ceil(this.roll * this.widthItem);
    },
  },
  methods: {
    next() {
      this.show = true;
      this.ruleForm.type = 2;
    },
    prev() {
      this.show = true;
      this.ruleForm.type = 1;
      this.roll -= 1;
    },
    getContact(index) {
      this.currentIndex = index;
    },
    getLeave() {
      this.currentIndex = -1;
    },
    testPhone() {
      var phone = $("#phone").val();
      if (!regex.test(phone)) {
        alert("请录入正确的手机号码！");
        $("#phone").focus();
        return false;
      }
    },
    getCode() {
      var regex =
        /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/;
      if (!regex.test(this.ruleForm.phone)) {
        this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
      } else {
        getSellCode({ phone: this.ruleForm.phone }).then((res) => {
          if (res.success == true) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      }
    },
    getSubmit(formName) {
      // 校验表单
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交表单
          getSellHome(this.ruleForm).then((res) => {
            console.log(res);
            if (res.success == true) {
              this.$alert("提交成功，请留意我司经纪人来电", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
              this.$refs[formName].resetFields();
              this.show = true;
              clearInterval(this.timer);
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  width: 100%;
  text-align: center;
  margin: 20px auto;
  border: none;
  .box {
    flex: 1;
    overflow: hidden;
    .wrap {
      display: inline-block;
      white-space: nowrap;
      transition: transform 0.6s;
      .item {
        display: inline-block;
        height: 100%;
        line-height: 60px;
        text-align: center;
      }
    }
  }
}
.active {
  animation: fadenum 2s 1;
}

.noactive {
  display: none;
}
@keyframes fadenum {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navigation {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  // background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    // border-bottom: none;
  }
  // .el-select .el-input.is-focus .el-input__inner {
  //   border-color: none;
  // }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // border-bottom: none;
    text-decoration: none;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.activated {
  color: #da9525;
  font-weight: 500;
}
.typeBox {
  padding-top: 33px;
  padding-bottom: 80px;
  background: url("../img/sellimg.png") no-repeat center;
  background-size: 100% 100%;
  padding-top: 120px;
  box-sizing: border-box;
  text-align: center;
  .typeBtn {
    margin-bottom: 20px;
    text-align: center;
    span {
      cursor: pointer;
      display: inline-block;
      width: 88px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #ffffff;
      border-radius: 20px;
      margin-right: 8px;
    }
  }
  .box-card {
    width: 560px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e9b869;
    padding: 40px;
    margin: 0 auto;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .titleTop {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
      }
      .titleBom {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    /deep/.el-form-item__content {
      margin-left: 0 !important;
      display: flex;
      span {
        display: block;
        width: 100px;
      }
    }
    /deep/.el-input_inner {
      border: 0 none;
    }
    /deep/.el-input__inner {
      width: 312px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .codeBox {
      position: relative;
      .codeBtn {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #da9525;
        position: absolute;
        right: 32px;
      }
    }
    .submitBtn {
      cursor: pointer;
      width: 312px;
      height: 40px;
      line-height: 40px;
      background: #da9525;
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin: auto;
    }
  }
}
.agentBox {
  padding: 90px 154px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 36px;
  box-sizing: border-box;
  .itemBox {
    width: 360px;
    height: 146px;
    display: flex;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #f9fafe;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .descBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e9ac33;
          margin-right: 10px;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          cursor: pointer;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
</style>
