<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="navigation">
      <img class="logo" src="../img/home/logo1.png" alt="" />
      <el-menu
        :default-active="activeIndex2"
        mode="horizontal"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">二手房</el-menu-item>
        <el-menu-item index="3">新房</el-menu-item>
        <el-menu-item index="4">租房</el-menu-item>
        <el-menu-item index="5">海外房产</el-menu-item>
        <el-menu-item index="6">查楼盘</el-menu-item>
        <el-menu-item index="7">我要卖房</el-menu-item>
        <el-menu-item index="8">我要找房</el-menu-item>
        <el-menu-item index="9">企业介绍</el-menu-item>
      </el-menu>
    </div>
    <div class="Box">
      <div class="Header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/SearchFloortray' }"
            >楼盘列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>楼盘详情页</el-breadcrumb-item>
        </el-breadcrumb>
        <div>
          <div
            class="phone"
            @mouseenter="getContactSee(1)"
            @mouseleave="getLeavesSee(0)"
          >
            <img src="../img/phone.png" alt="" />手机看
          </div>
          <div class="seePhone" :class="{ activePhone: phoneindex == 1 }">
            <img :src="houseInfo.wxHomeImg" alt="" />
          </div>
        </div>
      </div>
      <div class="parametersBox">
        <div class="swiperDiv">
          <div class="thumb-example">
            <!-- 大轮播图 -->
            <swiper
              class="swiper gallery-top"
              :options="swiperOptionTop"
              ref="swiperTop"
              :initialSlide="2"
            >
              <swiper-slide
                class="slide"
                v-for="(item, index) in swiperPictures"
                :key="index"
              >
                <img :src="item" alt="" />
                <!-- 图片放大 -->
                <div class="demo-image__preview">
                  <el-image
                    style="
                      width: 100%;
                      height: 394px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: ;
                    "
                    :src="item"
                    :preview-src-list="swiperPictures"
                  >
                  </el-image>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-next">
              <i class="left"></i>
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="right"></i>
            </div>
          </div>
        </div>
        <div class="infoBox">
          <p class="name">{{ houseInfo.name }}</p>
          <div class="newHouse">
            <p class="price">
              <span
                style="
                  font-size: 42px;
                  font-weight: 500;
                  color: #da9525;
                  margin-right: 5px;
                "
              >
                {{ houseInfo.averagePrice }}元/<span
                  style="
                    display: inline-block;
                    font-size: 24px;
                    font-weight: 500;
                    color: #da9525;
                  "
                  >m²</span
                >
              </span>
              <span
                style="
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.6);
                  line-height: 20px;
                "
              >
                参考均价
              </span>
            </p>
          </div>
          <div class="detailedInfo">
            <div>
              <p>在售房源: {{ houseInfo.sellNum ? houseInfo.sellNum : "-" }}</p>
              <p>在租房源：{{ houseInfo.rentNum ? houseInfo.rentNum : "-" }}</p>
              <p>
                近90日成交:
                {{ houseInfo.lastMonthDeal ? houseInfo.lastMonthDeal : "-" }}
              </p>
              <p>
                开发商: {{ houseInfo.developer ? houseInfo.developer : "-" }}
              </p>
              <p>
                物业公司:
                {{
                  houseInfo.propertyCompany ? houseInfo.propertyCompany : "-"
                }}
              </p>
              <p>楼盘地址: {{ houseInfo.address ? houseInfo.address : "-" }}</p>
            </div>
          </div>
        </div>
      </div>
      <p class="title">楼盘信息</p>
      <div class="propertyBox">
        <div>
          <p>楼盘名称: {{ houseInfo.name ? houseInfo.name : "-" }}</p>
          <p>建筑年代: {{ houseInfo.buildTime ? houseInfo.buildTime : "-" }}</p>
          <p>
            占地面积:
            {{ houseInfo.coverArea ? houseInfo.coverArea + "m²" : "-" }}
          </p>
          <p>
            建筑面积:
            {{ houseInfo.buildArea ? houseInfo.buildArea + "m²" : "-" }}
          </p>
          <p>容积率: {{ houseInfo.plotRatio ? houseInfo.plotRatio : "-" }}</p>
          <p>
            绿化率:
            {{ houseInfo.greeningRate ? houseInfo.greeningRate + "%" : "-" }}
          </p>
        </div>
        <div>
          <p>
            得房率:
            {{ houseInfo.occupancyRate ? houseInfo.occupancyRate + "%" : "-" }}
          </p>
          <p>
            物业费:
            {{
              houseInfo.propertyFee ? houseInfo.propertyFee + "元/㎡/月" : "-"
            }}
          </p>
          <p>
            物业公司:
            {{ houseInfo.propertyCompany ? houseInfo.propertyCompany : "-" }}
          </p>
          <p>开发商: {{ houseInfo.developer ? houseInfo.developer : "-" }}</p>
          <p>车位配比: {{ houseInfo.carRatio ? houseInfo.carRatio : "-" }}</p>
        </div>
        <div>
          <p>总户数: {{ houseInfo.familyNum ? houseInfo.familyNum : "-" }}</p>
          <p>
            所属商圈:
            {{ houseInfo.businessArea ? houseInfo.businessArea : "-" }}
          </p>
          <p>建筑类型: {{ houseInfo.way ? houseInfo.way : "-" }}</p>
          <p>
            楼栋总数: {{ houseInfo.buildingNum ? houseInfo.buildingNum : "-" }}
          </p>
          <p>人车分流: {{ houseInfo.shunts ? houseInfo.shunts : "-" }}</p>
        </div>
      </div>
      <p class="title" v-if="this.houseInfo.clientSpeakVideos">视频讲房</p>
      <div class="video-container" v-if="this.houseInfo.clientSpeakVideos">
        <video
          id="myPlayer"
          ref="myPlayer"
          :destroyOnClose="true"
          class="vjs-default-skin vjs-big-play-centered vjs-16-9 video-js"
          loop
          :autoplay="true"
          muted
          controls
          preload="auto"
        ></video>
      </div>
      <p class="title">楼栋分布</p>
      <div class="buildImg">
        <!-- 图片放大 -->
        <div class="demo-image__preview">
          <el-image
            style="width: 60%; height: 394px"
            :src="buildDistribution"
            :preview-src-list="buildImgarr"
          >
          </el-image>
        </div>
        <!-- <img :src="buildDistribution" @click="handleOpen" alt="" /> -->
      </div>
      <p class="title" v-if="roomList != ''">户型鉴赏</p>
      <p class="roomTypes" v-if="roomList">
        <span
          v-for="(item, index) in roomList"
          :key="index"
          @click="changeType(index, item.room)"
          :class="isclick == index ? 'isclick' : ''"
          >{{ item.rooms }}({{ item.num }})</span
        >
      </p>
      <div class="tab" v-if="roomList != ''">
        <div class="btnLeft" @click.stop="left"></div>
        <div class="tab_content">
          <div class="tab_item" v-for="(item, index) in tab" :key="index">
            <div class="imgBox">
              <img :src="item.imgs[0]" alt="" />
              <!-- 图片放大 -->
              <div class="demo-image__preview">
                <el-image
                  style="
                    width: 99%;
                    height: 100%;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    z-index: 0;
                  "
                  :src="tab[index].imgs[0]"
                  :preview-src-list="arr"
                >
                </el-image>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <p>{{ item.room }}室{{ item.hall }}厅{{ item.toilet }}卫</p>
              <p style="color: rgba(0, 0, 0, 0.6); font-size: 14px">
                {{ item.area }}m²
              </p>
            </div>
          </div>
        </div>
        <div class="btnRight" @click.stop="right"></div>
      </div>
      <p class="title" v-if="houseInfo.buildingDesc">楼盘介绍</p>
      <div v-if="houseInfo.buildingDesc" class="descBox">
        {{ houseInfo.buildingDesc }}
      </div>
      <p class="title" v-if="houseInfo.neighborhood">商圈介绍</p>
      <div v-if="houseInfo.neighborhood" class="descBox">
        {{ houseInfo.neighborhood }}
      </div>
      <p class="title" v-if="houseInfo.otherDesc">特色卖点</p>
      <div v-if="houseInfo.otherDesc" class="descBox">
        {{ houseInfo.otherDesc }}
      </div>
      <p class="title">推荐顾问</p>
      <div class="agentBox">
        <div
          class="itemBox"
          v-for="(item, index) in agentRecommends"
          :key="index"
        >
          <img class="icon" :src="item.icon" alt="" />
          <div class="adescBox">
            <p class="name">
              {{ item.name }}
              <span style="color: #e9ac33; margin-left: 16px"
                >{{ item.count }}分</span
              >
            </p>
            <p class="department">{{ item.departmentName }}</p>
            <div class="label">
              <span class="leftbtn">{{ item.job }}</span>
              <span
                class="code"
                @mouseenter="getContact(index)"
                @mouseleave="getLeave()"
                >扫码联系
                <img class="codeImg" src="../img/home/code.png" alt=""
              /></span>
            </div>
            <div
              class="contactCode"
              :class="{ activePhone: index == currentIndex }"
            >
              <img :src="item.wxHomeImg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <p class="title" v-if="usedHouseDealList.length != 0">成交历史</p>
      <p
        style="
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
        "
        v-if="usedHouseDealList.length != 0"
      >
        <span
          style="
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
          "
        >
          {{ houseInfo.name }}</span
        >
        <span
          style="
            font-size: 14px;
            margin-left: 15px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.7);
            margin-bottom: 24px;
          "
          >{{ houseInfo.uTime }}更新</span
        >
      </p>
      <!-- <div style="position: relative">
        <el-table
          style="width: 100%"
          border
          :data="usedHouseDealList"
          :cell-style="cellStyle"
          stripe
        >
          <el-table-column prop="area" label="面积(m²)" width="180">
          </el-table-column>
          <el-table-column prop="type" label="类型" width="180">
          </el-table-column>
          <el-table-column prop="unitPrice" label="成交单价(元/m²)">
          </el-table-column>
          <el-table-column prop="price" label="成交价格(万)" width="180">
          </el-table-column>
          <el-table-column prop="date" label="签约日期"> </el-table-column>
        </el-table>
      </div> -->
      <div v-if="usedHouseDealList.length != 0" class="class-table">
        <div class="table-wrapper">
          <div class="tabel-container">
            <table>
              <thead>
                <tr>
                  <th
                    v-for="(weekNum, weekIndex) in classTableData.lessons"
                    :key="weekIndex"
                  >
                    {{ weekNum }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lesson, lessonIndex) in 10" :key="lessonIndex">
                  <td
                    v-for="(course, courseIndex) in classTableData.courses"
                    :key="courseIndex"
                  >
                    {{ classTableData.courses[courseIndex][lessonIndex] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mapBox">
        <baidu-map
          class="bm-view"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="false"
          @ready="handler"
        >
          <bm-marker
            :position="{ lng: center.lng, lat: center.lat }"
            :dragging="true"
          >
          </bm-marker>
          <bm-local-search
            :keyword="keyword"
            :nearby="nearby"
            :auto-viewport="true"
            :panel="false"
            @searchcomplete="search"
          ></bm-local-search>
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
          ></bm-geolocation>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation
        ></baidu-map>
        <div class="mapType">
          <div class="typeTitle">
            <span
              :class="{ 'tab-current': index == currentIndexs }"
              v-for="(item, index) in typeList"
              :key="index"
              @click="clickTabs(index, item)"
              >{{ item }}</span
            >
          </div>
          <div
            v-for="(item, index) in infoList"
            :key="index"
            class="infocontent"
          >
            <div>
              <p class="leftName">{{ item.title }}</p>
              <p class="leftBom">
                {{ item.address }}
              </p>
            </div>
            <p
              style="
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
              "
            >
              {{ item.long }}KM
            </p>
          </div>
        </div>
      </div>
      <ul class="titleType">
        <span class="line" :style="{ left: slideLeft }"></span>
        <li
          v-for="(item, index) in titleType"
          :key="index"
          class="currentIndex"
          :class="{ 'tab-currents': index == current_Index }"
          @click="clickTab(index)"
        >
          {{ item }}
        </li>
      </ul>
      <div class="moreNewHouse">
        <div
          class="itemMhouse"
          style="margin-right: 5%"
          v-for="(item, index) in houseTypeList"
          :key="index"
          @click="getHouseDetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <div>
            <p class="name">{{ item.name }}</p>
            <p class="area">
              <span style="font-weight: 500; font-size: 16px">
                {{ item.area }}m²</span
              >
              |
              <span style="font-weight: 500; font-size: 16px">
                {{ item.room }}室{{ item.hall }}厅{{ item.toilet }}卫</span
              >
            </p>
            <p class="price" v-if="current_Index == 0">￥{{ item.price1 }}万</p>
            <p class="price" v-if="current_Index == 1">
              ￥{{ item.rent }}元/月
            </p>
          </div>
        </div>
      </div>
      <p
        v-if="houseTypeList.length == 0"
        style="text-align: center; font-size: 17px; margin-bottom: 30px"
      >
        暂无房源~
      </p>

      <p class="title">更多楼盘</p>
      <div class="moreOverHouse">
        <div
          class="itemMhouse"
          v-for="(item, index) in houseInfo.communityRecommends"
          :key="index"
          @click="getHousedetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <div class="infos">
            <p class="name">{{ item.name }}</p>
            <div style="display: flex; justify-content: space-between">
              <p class="price">
                <span>挂牌均价：</span>
                <span style="font-size: 20px; color: #da9525">{{
                  item.averagePrice == 0
                    ? "暂无数据"
                    : item.averagePrice + "元/平"
                }}</span>
              </p>
              <p class="nums">
                <span>近90日成交: </span>
                <span style="font-size: 20px; color: #da9525">{{
                  item.lastMonthDeal
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="title">沪上新房</p>
      <div class="moreNewHouse" style="justify-content: space-between">
        <div
          class="itemMhouse"
          v-for="(item, index) in houseInfo.newHouseRecommends"
          :key="index"
          @click="getNewHouseDetails(item.id)"
        >
          <img :src="item.cover" alt="" />
          <div>
            <p class="name">{{ item.name }}</p>
            <p class="area">
              {{ item.minBuildArea }} - {{ item.maxBuildArea }}m²
            </p>
            <p class="price">{{ item.price1 }}万 - {{ item.price2 }}万</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
import js from "@/utils/utils";
import Footer from "@/components/Footer.vue";
import { getHousecommunity, getCommunityModelList } from "../api/api";
import {
  BaiduMap,
  BmGeolocation,
  BmLocalSearch,
  BmNavigation,
  BmMarker,
} from "vue-baidu-map";
export default {
  name: "CommunityDetails",
  components: {
    Footer,
    BaiduMap,
    // 手动定位控件
    BmGeolocation,
    // 检索控件
    BmLocalSearch,
    // 地图放大缩小控件
    BmNavigation,
    // marker控件
    BmMarker,
  },
  data() {
    return {
      classTableData: {
        lessons: [
          "面积(m²)",
          "类型",
          "成交单价(元/m²)",
          "成交价格(万)",
          "签约日期",
        ],
        courses: [],
      },
      loading: true,
      cid: "",
      activeIndex2: "0",
      houseInfo: "",
      agentRecommend: "",
      agentRecommends: "",
      cityName: "",
      swiperOptionTop: {
        loop: false,
        loopedSlides: 0,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperPictures: [],
      buildDistribution: "",
      buildImgarr: [],
      numIndex: 3,
      tabList: [],
      tab: [],
      roomList: [],
      isclick: 0,
      titleType: ["在售房源", "在租房源"],
      tabLen: "",
      current_Index: 0,
      slideLeft: "",
      houseTypeList: [],
      usedHouseDealList: [],
      rules: {
        area: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        type: [{ required: true, message: "请选择性别", trigger: "change" }],
        unitPrice: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入校验码", trigger: "blur" }],
        date: [{ required: true, message: "请输入小区名称", trigger: "blur" }],
      },
      center: { lng: 0, lat: 0 },
      nearby: {},
      zoom: 10,
      map: null,
      BMap: null,
      keyword: "地铁",
      typeList: ["地铁", "商业", "医疗", "休闲", "教育"],
      currentIndexs: 0,
      infoList: [],
      currentIndex: -1,
      arr: [],
      phoneindex: -1,
      myPlayerHls: "",
    };
  },
  mounted() {
    this.cid = this.$route.params.id;
    this.getCommunityDetails(this.$route.params.id);
    setTimeout(() => {
      this.infoList = JSON.parse(localStorage.getItem("arrList"));
    }, 4000);
    this.slideLineLeft();
  },
  watch: {
    // 被侦听的变量keyword
    keyword() {
      setTimeout(() => {
        this.infoList = JSON.parse(localStorage.getItem("arrList"));
      }, 1000);
    },
  },
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row, columnIndex);
      if (row.type == "公寓" && columnIndex > 0) {
        return { color: "red" }; //方法1
      }
    },
    // 更多楼盘
    getHousedetails(id) {
      this.loading = true;
      localStorage.setItem("ID", id);
      this.getCommunityDetails();
      setTimeout(() => {
        this.infoList = JSON.parse(localStorage.getItem("arrList"));
        console.log(this.infoList);
      }, 4000);
    },
    getContactSee() {
      this.phoneindex = 1;
    },
    getLeavesSee() {
      this.phoneindex = 0;
    },
    getContact(index) {
      this.currentIndex = index;
    },
    getLeave() {
      this.currentIndex = -1;
    },
    init() {
      let dom_id = "myPlayer";
      this.myPlayerHls = videojs(dom_id, {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        sources: [
          {
            type: "application/x-mpegURL",
            src: this.houseInfo.clientSpeakVideos
              ? this.houseInfo.clientSpeakVideos[0].url
              : "",
          },
        ],
      });
    },
    // 计算距离
    GetDistance(lat1, lng1, lat2, lng2) {
      let EARTH_RADIUS = 6378.137;
      let radLat1 = this.rad(lat1);
      let radLat2 = this.rad(lat2);
      let a = radLat1 - radLat2;
      let b = this.rad(lng1) - this.rad(lng2);
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * EARTH_RADIUS;
      s = Math.round(s);
      //乘以1000是换算成米
      return s;
    },
    rad(d) {
      return (d * Math.PI) / 180.0;
    },
    // 搜索房源周围信息
    search(arr) {
      let arrList = arr.Yr.slice(0, 5);
      arrList.forEach((item) => {
        let lng = item.point.lat;
        let lat = item.point.lng;
        item.long = this.GetDistance(
          Number(lng),
          Number(lat),
          Number(this.houseInfo.latitude),
          Number(this.houseInfo.longitude)
        ).toFixed(1);
      });
      localStorage.setItem("arrList", JSON.stringify(arrList));
    },

    clickTabs(index, name) {
      this.keyword = name;
      this.currentIndexs = index;
    },
    handler({ BMap, map, lat, lng }) {
      this.map = map;
      this.BMap = BMap;
      this.center.lng = this.houseInfo.longitude;
      this.center.lat = this.houseInfo.latitude;
      this.zoom = 15;
    },
    // 跳转新房详情页
    getNewHouseDetails(id) {
      localStorage.setItem("ID", id);
      this.$router.push({ name: "NewHouseDetails", params: { id } });
    },
    clickTab(index) {
      this.current_Index = index;
      if (index == 0) {
        this.houseTypeList = this.houseInfo.sellUsedHouses;
      } else {
        this.houseTypeList = this.houseInfo.rentUsedHouses;
      }
      const left = 100 / Number(this.tabLen) / 2;
      this.slideLeft = left * (index * 2.4) + "%";
    },
    // 跳转房源详情页面
    getHouseDetails(id) {
      localStorage.setItem("ID", id);
      localStorage.getItem("ID");
      if (this.current_Index == 0) {
        this.$router.push({ name: "SecondHouseDetails", params: { id } });
      } else {
        this.$router.push({ name: "RentHouseDetails", params: { id } });
      }
    },
    // 刚进入页面没有点击时的下划线设置
    slideLineLeft() {
      this.tabLen = this.titleType.length;
      const left = 100 / this.tabLen / 2;
      // this.slideLeft = left * (this.currentIndex * 2) + "%";
      this.slideLeft = 0;
    },
    //左箭头按钮
    lefts() {
      if (this.numIndexs > 3) {
        if (this.swiper[0].id == this.swiperList[0].id) {
          this.swiperList.unshift(this.swiper[this.numIndexs - 4]); //前面添加一个
          this.swiperList.pop(this.swiperList[this.numIndexs]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
          this.numIndexs--;
        } else {
          return;
        }
      }
    },
    //右箭头按钮
    rights() {
      if (
        this.swiper[this.swiper.length - 1] ==
        this.swiperList[this.swiperList.length - 1]
      ) {
        return;
      } else {
        this.swiperList.shift(this.swiperList[this.numIndexs - 3]); //前面删除一个
        this.swiperList.push(this.swiper[this.numIndexs + 1]); //后面添加一个
        this.numIndexs++;
      }
    },

    handleGetTabList() {
      this.tabList = this.houseInfo.usedHouseCommunityModels;
      if (Array.isArray(this.tabList) && this.tabList.length > 4) {
        this.tab = this.tabList.slice(0, 4);
      } else {
        this.tab = this.tabList;
      }
    },
    //左箭头按钮
    left() {
      if (this.numIndex > 3) {
        if (this.tabList[0].id == this.tab[0].id) {
          return;
        } else {
          this.tab.unshift(this.tabList[this.numIndex - 4]); //前面添加一个
          this.tab.pop(this.tab[this.numIndex]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
          this.numIndex--;
        }
      }
    },
    //右箭头按钮
    right() {
      if (
        this.tabList[this.tabList.length - 1].id ==
        this.tab[this.tab.length - 1].id
      ) {
        return;
      } else {
        this.tab.shift(this.tab[this.numIndex - 3]); //前面删除一个
        this.tab.push(this.tabList[this.numIndex + 1]); //后面添加一个
        this.numIndex++;
      }
    },
    changeType(index, room) {
      this.isclick = index;
      getCommunityModelList({
        // cid: this.cid,
        cid: this.cid,
        room: room.slice(0, room.length - 1),
      }).then((res) => {
        // console.log(res);
        this.tabList = res;
        if (Array.isArray(this.tabList) && this.tabList.length > 4) {
          this.tab = this.tabList.slice(0, 4);
        } else {
          this.tab = this.tabList;
        }
        this.tab.forEach((item, index) => {
          // console.log(item);
          this.arr.push(item.imgs[0]);
        });
      });
    },
    getCommunityDetails() {
      document.documentElement.scrollTop = 0;
      getHousecommunity({ id: localStorage.getItem("ID") }).then((res) => {
        // console.log(res);
        this.houseInfo = res.data;
        this.cid = this.houseInfo.id;
        this.houseInfo.businessArea = this.houseInfo.businessArea.name;
        this.nearby = {
          center: { lng: res.data.longitude, lat: res.data.latitude },
          radius: 3000,
        };
        this.handler(res.data.latitude, res.data.longitude);
        // 成交历史
        this.classTableData.courses = [];
        this.usedHouseDealList = res.data.usedHouseDealList.slice(0, 10);
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        this.usedHouseDealList.forEach((item, index) => {
          arr1.push(item.area);
          arr2.push(item.type);
          arr3.push(item.unitPrice);
          arr4.push(item.price);
          arr5.push(item.date);
        });
        this.classTableData.courses.push(arr1, arr2, arr3, arr4, arr5);
        let time = res.data.usedHouseDealList[0];
        if (res.data.usedHouseDealList.length != 0) {
          this.houseInfo.uTime = js.formatDateTime(
            res.data.usedHouseDealList[0].ctime
          );
        }
        res.data.sellUsedHouses.forEach((item) => {
          item.price1 = js.formatNumber(item.price);
        });
        this.houseTypeList = res.data.sellUsedHouses;
        this.buildDistribution = res.data.buildDistribution[0];
        this.buildImgarr.push(res.data.buildDistribution[0]);
        this.swiperPictures = res.data.imgs;
        this.agentRecommends = res.data.agentRecommends;
        this.agentRecommend = res.data.agentRecommend;
        switch (res.data.type) {
          case 1:
            res.data.way = "住宅";
            break;
          case 2:
            res.data.way = "商住";
            break;
          case 3:
            res.data.way = "商铺";
            break;
          case 4:
            res.data.way = "写字楼";
            break;
          case 5:
            res.data.way = "别墅";
            break;
          case 99:
            res.data.way = "其他";
            break;
          default:
        }
        switch (res.data.shunt) {
          case 1:
            res.data.shunts = "是";
            break;
          case 0:
            res.data.shunts = "否";
            break;
          default:
        }
        res.data.roomList.forEach((item) => {
          if (item.room == "1房") {
            item.rooms = "一居室";
          } else if (item.room == "2房") {
            item.rooms = "二居室";
          } else if (item.room == "3房") {
            item.rooms = "三居室";
          } else if (item.room == "4房") {
            item.rooms = "四居室";
          } else if (item.room == "5房") {
            item.rooms = "五居室";
          } else if (item.room == "6房") {
            item.rooms = "六居室";
          } else if (item.room == "7房") {
            item.rooms = "七居室";
          } else if (item.room == "8房") {
            item.rooms = "八居室";
          } else if (item.room == "9房") {
            item.rooms = "九居室";
          } else if (item.room == "10房") {
            item.rooms = "十居室";
          } else {
            item.rooms = "更多居室";
          }
        });
        this.roomList = res.data.roomList;
        res.data.usedHouseCommunityModels.forEach((item) => {
          this.arr.push(item.imgs);
        });
        if (this.roomList.length != 0) {
          this.roomList.unshift({
            num: res.data.usedHouseCommunityModels.length,
            rooms: "全部户型",
            room: "",
          });
        } else {
          this.roomList = [];
        }
        // 更多楼盘
        res.data.communityRecommends.forEach((item) => {
          item.price1 = js.formatNumber(item.minPrice);
          item.price2 = js.formatNumber(item.maxPrice);
        });
        // 更多新房
        res.data.newHouseRecommends.forEach((item) => {
          item.price1 = js.formatNumber(item.minPrice);
          item.price2 = js.formatNumber(item.maxPrice);
        });

        this.swiperOptionTop.loopedSlides = res.data.itemPictures.length;
        this.handleGetTabList();
        this.loading = false;
        setTimeout(() => {
          console.log(this.houseInfo.speakVideos);
          if (this.houseInfo.speakVideos == "") {
            return;
          } else {
            this.init();
          }
        }, 3000);
      });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
  },
  beforeDestroy() {
    localStorage.removeItem("arrList");
    if (this.myPlayerHls) {
      const myPlayerHls = this.$refs.myPlayer; // 不能用document 获取节点
      videojs(myPlayerHls).dispose(); // 销毁video实例，避免出现节点不存在
    }
  },
};
</script>
<style lang="less" scoped>
.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  .btnLeft {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #000 #000 transparent transparent;
    border-style: solid;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-right: 26px;
  }
  .btnRight {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #000 #000 transparent transparent;
    border-style: solid;
    transform: matrix(0.75, 0.75, -0.75, 0.75, 0, 0);
  }
  .tab_content {
    width: 100%;
    font-size: 12px;
    color: rgb(255, 255, 255);
    display: flex;
    flex-wrap: no-wrap;
    overflow: hidden;
    .tab_item {
      width: 22%;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 26px;
      font-size: 16px;
      padding-top: 16px;
      box-sizing: border-box;
      .imgBox {
        text-align: center;
        height: 196px;
        line-height: 196px;
        border-bottom: 1px solid #cccccc;
        position: relative;
        img {
          width: 90%;
          height: 165px;
          object-fit: cover;
        }
      }
      p {
        line-height: 25px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
i {
  /* 用border值来控制箭头粗细 */
  border: 5px solid #fff;
  /* 上、右、下、左  四个边框的宽度 */
  border-width: 0px 1px 1px 0px;
  display: inline-block;
  /* padding值控制箭头大小 */
  padding: 4px;
}
// 右箭头
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
// 左箭头
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.navigation {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: #3d3333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.Box {
  padding: 40px 160px;
  box-sizing: border-box;
  background-color: #fff;
  .Header {
    display: flex;
    justify-content: space-between;
    position: relative;
    .phone {
      cursor: pointer;
      padding: 0 13px;
      line-height: 32px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #da9525;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #da9525;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }
    .seePhone {
      border-radius: 1px;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #ccc;
      display: none;
      position: absolute;
      top: 40px;
      img {
        width: 130px;
        height: 130px;
      }
    }
    .activePhone {
      display: block;
    }
  }
}
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 16px;
}
.parametersBox {
  display: flex;
  padding-top: 32px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
  .swiperDiv {
    width: 60%;
    position: relative;
    margin-right: 24px;
    .swiper-button-prev {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
    }
    .swiper-button-next {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 10px;
      top: 181px;
      z-index: 98;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 50%;
      opacity: 0.5;
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
      img {
        width: 100%;
        height: 394px;
        object-fit: cover;
      }
    }
  }
  .infoBox {
    width: 50%;
    .newHouse {
      display: flex;
      justify-content: space-between;
    }
    .name {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 32px;
    }
    .price {
      font-size: 38px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 500;
      color: #da9525;
      margin-bottom: 25px;
    }
    .area {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 20px;
    }
    .detailedInfo {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 20px;
      box-sizing: border-box;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 35px;
      }
      p {
        line-height: 35px;
      }
    }
  }
}
.propertyBox {
  width: 100%;
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 56px;
  border-radius: 5px;
  p {
    line-height: 32px;
  }
}
.video-container {
  width: 80%;
  margin-bottom: 56px;
}
.buildImg {
  margin-bottom: 56px;
  img {
    width: 60%;
  }
}
.roomTypes {
  padding-left: 40px;
  padding-bottom: 25px;
  padding-top: 10px;
  box-sizing: border-box;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  span {
    margin-right: 56px;
    cursor: pointer;
  }
  .isclick {
    color: #da9525;
    font-weight: 500;
  }
}
.agentBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 36px;
  box-sizing: border-box;
  .itemBox {
    display: flex;
    width: 360px;
    height: 146px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #f9fafe;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .adescBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e9ac33;
          margin-right: 10px;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          line-height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          cursor: pointer;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
.descBox {
  width: 100%;
  padding: 22px 24px;
  box-sizing: border-box;
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 30px;
  margin-bottom: 56px;
  white-space: pre-wrap;
  border-radius: 5px;
}
.moreNewHouse {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 56px;
  .itemMhouse {
    width: 30%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    img {
      width: 100%;
      height: 213px;
      margin-bottom: 12px;
    }
    div {
      padding-left: 16px;
      box-sizing: border-box;
      .name {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        padding-bottom: 15px;
      }
      .area {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 10px;
      }
      .price {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #da9525;
        padding-bottom: 15px;
      }
    }
  }
}
.el-table {
  margin-bottom: 56px;
}
.mapBox {
  height: 388px;
  margin-bottom: 56px;
  position: relative;

  ::v-deep .anchorBL {
    display: none !important;
  }
  .bm-view {
    width: 100%;
    height: 388px;
  }
  .mapType {
    width: 358px;
    height: 372px;
    border-radius: 4px;
    padding: 26px 16px;
    box-sizing: border-box;
    position: absolute;
    right: 8px;
    top: 8px;
    background-color: #fff;
    .typeTitle {
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 17px;
      span {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 6px;
      }
      .tab-current {
        color: #da9525;
        font-weight: 600;
      }
    }
    .infocontent {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: top;
      div {
        .leftName {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
        }
        .leftBom {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
.titleType {
  width: 240px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  position: relative;
  color: #c3c6cd;
  margin-bottom: 24px;
  cursor: pointer;
  .currentIndex {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    line-height: 40px;
    list-style: none;
    color: rgba(0, 0, 0, 0.6);
  }
  .tab-currents {
    color: #da9525;
    font-weight: 500;
  }
  .line {
    position: absolute;
    bottom: 0;
    width: 96px;
    transition: all 0.3s;
    height: 2px;
    background: #da9525;
  }
}
.moreOverHouse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  .itemMhouse {
    width: 30%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    img {
      width: 100%;
      height: 213px;
      margin-bottom: 12px;
    }
    .infos {
      padding: 12px 16px;
      box-sizing: border-box;
      .name {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        display: flex;
        padding-bottom: 12px;
        box-sizing: border-box;
        justify-content: space-between;
        img {
          width: 13px;
          height: 13px;
          margin-right: 3px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .price {
        span {
          display: block;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .nums {
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          display: block;
        }
      }
    }
  }
}

.class-table {
  margin-bottom: 30px;
  .table-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .tabel-container {
    margin: 7px;

    table {
      box-shadow: 3px 3px 10px #b1afaf;
      background-image: url("https://bthz.oss-cn-beijing.aliyuncs.com/%E6%B0%B4%E5%8D%B0.png?versionId=CAEQKRiBgIDoz4j.hxgiIGY5MGU2OGVkNWVkYjRlNmY5NGQwMDNjMWZlNTJjYWUw");
      background-size: contain;
      table-layout: fixed;
      width: 100%;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: #ebeef5;
      thead {
        th {
          color: #000;
          line-height: 17px;
          font-weight: normal;
          width: 20%;
          text-align: center;
          // border-bottom: 1px solid #ebeef5;
          font-size: 15px;
          font-style: 600;
        }
      }
      tbody {
        td {
          color: #000;
          line-height: 12px;
        }
      }
      th,
      td {
        // border-bottom: 1px solid #ebeef5;
        width: 60px;
        padding: 12px 2px;
        font-size: 12px;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
