import { render, staticRenderFns } from "./CommunityDetails.vue?vue&type=template&id=1cb23306&scoped=true&"
import script from "./CommunityDetails.vue?vue&type=script&lang=js&"
export * from "./CommunityDetails.vue?vue&type=script&lang=js&"
import style0 from "./CommunityDetails.vue?vue&type=style&index=0&id=1cb23306&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb23306",
  null
  
)

export default component.exports