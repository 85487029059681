import { render, staticRenderFns } from "./BuyHouse.vue?vue&type=template&id=87db7048&scoped=true&"
import script from "./BuyHouse.vue?vue&type=script&lang=js&"
export * from "./BuyHouse.vue?vue&type=script&lang=js&"
import style0 from "./BuyHouse.vue?vue&type=style&index=0&id=87db7048&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87db7048",
  null
  
)

export default component.exports