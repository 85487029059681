import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import ElementUI from 'element-ui'
import BaiduMap from 'vue-baidu-map'
import 'element-ui/lib/theme-chalk/index.css';
//动画 animate__repeat-2：默认迭代次数；animate__slow：默认速度时间2sanimate__delay-2s：默认延迟时间2s
import animate from "animate.css" 
import "swiper/swiper.min.css"
import VueAwesomeSwiper from 'vue-awesome-swiper';
// import 'swiper/swiper.css';
Vue.use(BaiduMap, { ak: 'AVdQZs5uCDj660dCRyO7yxVrrbqmigXh' });
import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video  // 在vue的原生里添加了Video这个标签，增强了vue的功能性
Vue.use(Vuex)
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI)
Vue.config.productionTip = false //提示

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

new Vue({
  router,
  animate,
  render: h => h(App),
}).$mount('#app')
