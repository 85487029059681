import Vue from "vue";
import VueRouter from 'vue-router'

// 引入组件
import Home from '../views/Home.vue'
import GuidePage from '../views/GuidePage.vue'
import SecondhandHouse from '../views/SecondhandHouse.vue'
import SecondHouseDetails from '../views/SecondHouseDetails.vue'
import NewHouse from '../views/NewHouse.vue'
import NewHouseDetails from '../views/NewHouseDetails.vue'
import OverseasHouse from '../views/OverseasHouse.vue'
import OverSeaHouseDetails from '../views/OverSeaHouseDetails.vue'
import RentHouse from '../views/RentHouse.vue'
import RentHouseDetails from '../views/RentHouseDetails.vue'
import SellHouse from '../views/SellHouse.vue'
import BuyHouse from '../views/BuyHouse.vue'
import SearchFloortray from '../views/SearchFloortray.vue'
import CompanyProfile from '../views/CompanyProfile.vue'
import CommunityDetails from '../views/CommunityDetails.vue'

// import Login from '../views/Login.vue'
Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    {
      path: '/', redirect: '/GuidePage'
    },
    {
      name: 'GuidePage',
      path: '/GuidePage',
      component: GuidePage,
      meta: { title: '引导页' },  
    },
    {
      name: 'Home',
      path: '/Home',
      component: Home,
      meta: { title: '首页' },  
    },
    {
      name: 'SecondhandHouse',
      path: '/SecondhandHouse',
      component: SecondhandHouse,
      meta: { title: '二手房',},  
    },
    {
      name: 'SecondHouseDetails',
      path: '/SecondHouseDetails',
      component: SecondHouseDetails,
      meta: { title: '二手房详情',keepAlive: true  },  
    },
    {
      name: 'NewHouse',
      path: '/NewHouse',
      component: NewHouse,
      meta: { title: '新房' },  
    },
    {
      name: 'NewHouseDetails',
      path: '/NewHouseDetails',
      component: NewHouseDetails,
      meta: { title: '新房详情',keepAlive: true},  
    },
    
    {
      name: 'OverseasHouse',
      path: '/OverseasHouse',
      component: OverseasHouse,
      meta: { title: '海外房产' },  
    },
    {
      name: 'OverSeaHouseDetails',
      path: '/OverSeaHouseDetails',
      component: OverSeaHouseDetails,
      meta: { title: '海外房产详情',keepAlive: true },  
    },
    {
      name: 'RentHouse',
      path: '/RentHouse',
      component: RentHouse,
      meta: { title: '租房' },  
    },
    {
      name: 'RentHouseDetails',
      path: '/RentHouseDetails',
      component: RentHouseDetails,
      meta: { title: '租房详情',keepAlive: true},  
    },
    
    {
      name: 'SellHouse',
      path: '/SellHouse',
      component: SellHouse,
      meta: { title: '卖房' },  
    },
    {
      name: 'BuyHouse',
      path: '/BuyHouse',
      component: BuyHouse,
      meta: { title: '买房' },  
    },
    {
      name: 'SearchFloortray',
      path: '/SearchFloortray',
      component: SearchFloortray,
      meta: { title: '查找楼盘' },  
    },
    {
      name: 'CommunityDetails',
      path: '/CommunityDetails',
      component: CommunityDetails,
      meta: { title: '小区详情',},  
    },
    {
      name: 'CompanyProfile',
      path: '/CompanyProfile',
      component: CompanyProfile,
      meta: { title: '公司简介' },  
    },
  ]
})
export default router
