<template>
  <div>
    <div class="theSwiper">
      <swiper :options="swiperOption">
        <swiper-slide
          ><img src="../img/home/banner1.jpg" alt=""
        /></swiper-slide>
        <swiper-slide
          ><img src="../img/home/banner2.png" alt=""
        /></swiper-slide>
        <swiper-slide
          ><img src="../img/home/banner3.png" alt=""
        /></swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div class="bgcBox">
        <div style="position: fixed; top: 0; z-index: 99">
          <div class="navigation" ref="navBox">
            <img class="logo" src="../img/home/logo1.png" alt="" />
            <el-menu
              :default-active="activeIndex2"
              mode="horizontal"
              @select="handleSelect"
              text-color="#fff"
              active-text-color="#ffd04b"
            >
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">二手房</el-menu-item>
              <el-menu-item index="3">新房</el-menu-item>
              <el-menu-item index="4">租房</el-menu-item>
              <el-menu-item index="5">海外房产</el-menu-item>
              <el-menu-item index="6">查楼盘</el-menu-item>
              <el-menu-item index="7">我要卖房</el-menu-item>
              <el-menu-item index="8">我要找房</el-menu-item>
              <el-menu-item index="9">企业介绍</el-menu-item>
            </el-menu>
          </div>
        </div>
        <div class="guide animate__animated animate__fadeInUp">
          <p class="title">做不动产与美好生活的</p>
          <p class="title">坚实桥梁</p>
          <span class="findHome" @click="handleSelect(2)">找房源</span>
          <span @click="toCompanyProfile" class="introduce">了解百廷</span>
        </div>
      </div>
    </div>
    <div>
      <div class="houseType animate__animated animate__fadeInUp">
        <div @click="handleSelect(3)">
          <img src="../img/home/new.png" alt="" />
          <div>新房</div>
        </div>
        <div @click="handleSelect(4)">
          <img src="../img/home/rent.png" alt="" />
          <div>租房</div>
        </div>
        <div @click="handleSelect(2)">
          <img src="../img/home/second.png" alt="" />
          <div>二手房</div>
        </div>
        <div @click="handleSelect(7)">
          <img src="../img/home/sell.png" alt="" />
          <div>卖房</div>
        </div>
        <div @click="handleSelect(5)">
          <img src="../img/home/oversea.png" alt="" />
          <div>海外房产</div>
        </div>
        <div @click="handleSelect(8)">
          <img src="../img/home/find.png" alt="" />
          <div>找房</div>
        </div>
      </div>

      <div v-if="isShow" class="slogan animate__animated animate__backInLeft">
        <img class="desc" src="../img/home/dec.png" alt="" />
      </div>
    </div>
    <div v-if="isShows" class="aim animate__animated animate__backInRight">
      <img src="../img/home/culture.png" alt="" />
      <img
        class="phone animate_animated animate_heartBeat"
        src="../img/home/phone.png"
        alt=""
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import Swiper from "@/components/Swiper copy.vue";
export default {
  components: { Footer },
  name: "Home",
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      activeIndex2: "1",
      scrollTop: "",
      isShow: false,
      isShows: false,
    };
  },
  methods: {
    toCompanyProfile() {
      this.$router.push("/CompanyProfile");
    },
    handleSelect(e) {
      if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
    // handleSelect(e) {
    //   if (e == "9") {
    //     this.$router.push("/CompanyProfile");
    //   } else {
    //     this.$message({
    //       showClose: true,
    //       message: "该页面暂未开放！",
    //     });
    //   }
    // },
  },
  mounted() {
    window.document.body.onscroll = () => {
      this.scrollTop = document.documentElement.scrollTop;
      if (document.documentElement.scrollTop < 100) {
        this.$refs.navBox.style.backgroundColor = "transparent";
        this.isShow = true;
        this.isShows = false;
      } else {
        if (650 < document.documentElement.scrollTop < 700) {
          this.isShows = true;
        }
        this.$refs.navBox.style.backgroundColor = "#3d3333";
      }
    };
  },
  destroyed() {
    window.onscroll = null;
  },
};
</script>
<style lang="less" scoped>
.theSwiper {
  width: 100vw;
  height: 724px;
  position: relative;
  .swiper-container {
    position: relative;
    height: 200px;
    background: rgba(0, 0, 0, 0.137);
    border: 1px solid white;
    .swiper-slide {
      img {
        width: 100%;
        height: 724px;
        object-fit: cover;
      }
    }
  }
  .bgcBox {
    width: 100vw;
    .navigation {
      width: 100vw;
      position: fixed;
      top: 0;
      z-index: 990;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 11%;
      box-sizing: border-box;
      .el-menu.el-menu--horizontal {
        border-bottom: none;
      }
      .el-menu:hover .el-menu-item {
        background-color: transparent !important;
      }
      .logo {
        width: 96px;
        height: 32px;
        margin-right: 30px;
      }
      .el-menu {
        background: none;
      }
    }
    .guide {
      animation-duration: 2s;
      position: absolute;
      top: 0px;
      z-index: 10;
      padding-left: 169px;
      box-sizing: border-box;
      .title {
        font-size: 60px;
        color: #fff;
        font-weight: 500;
        font-family: PingFangSC-Regular, PingFang SC;
      }
      .findHome {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        width: 148px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        border: 1px solid #fff;
        font-size: 14px;
        font-family: SimSong-Bold;
        color: #fff;
        margin-right: 24px;
        margin-top: 30px;
      }
      .introduce {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        width: 148px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        border: 1px solid #ffc939;
        font-size: 14px;
        font-family: SimSong-Bold;
        color: #ffc939;
      }
    }
  }
}
.houseType {
  animation-duration: 2s;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 80px 13%;
  box-sizing: border-box;
  div {
    cursor: pointer;
  }
  img {
    width: 54px;
    height: 54px;
    margin-bottom: 8px;
  }
}
.slogan {
  animation-duration: 2s;
  width: 100%;
  background: #f6f6f6;
  padding-top: 77px;
  padding-bottom: 89px;
  padding-right: 220px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  .desc {
    width: 100%;
    object-fit: contain;
  }
}
.aim {
  animation-duration: 2s;
  width: 100vw;
  height: 664px;
  padding: 64px 160px 0 160px;
  box-sizing: border-box;
  background: url("../img/home/bgc2.png") no-repeat center;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  img {
    width: 378px;
    height: 445px;
  }
  .phone {
    width: 675px;
    height: 649px;
  }
}

/* 向下淡出  向上淡入动画函数 */
@-webkit-keyframes fadeInDown2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown2 {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown2 {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown2 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
