<template>
  <div class="container">
    <div class="CationBox">
      <div class="row">
        <div class="name">平台：</div>
        <div class="content">
          <div
            v-for="(item, index) in PlatformList"
            :key="index"
            @click="PlatformVar = item"
            :class="['item', PlatformVar == item ? 'actvCss' : '']"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="name">类型：</div>
        <div class="content">
          <div
            v-for="(item, index) in TypeList"
            :key="index"
            @click="TypeVar = item"
            :class="['item', TypeVar == item ? 'actvCss' : '']"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="name">成色：</div>
        <div class="content">
          <div
            v-for="(item, index) in ConditionList"
            :key="index"
            @click="ConditionVar = item"
            :class="['item', ConditionVar == item ? 'actvCss' : '']"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- 商品展示 -->
    <div class="ContentBox">
      <div class="DataListBox" v-if="ComDataList.length">
        <div class="item" v-for="(item, index) in ComDataList" :key="index">
          <div>{{ item.Platform }}</div>
          <div>{{ item.Title }}</div>
          <div>{{ item.Condition }}</div>
        </div>
      </div>
      <div v-else class="nullCss">暂无相关商品~</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      isShow: false,
      PlatformVar: "全部", // 表示当前选中的平台
      TypeVar: "全部", // 表示当前选中的类型
      ConditionVar: "全部", // 表示当前选中的成色
      PlatformList: ["全部", "淘宝", "天猫", "京东", "闲鱼", "转转"], // 平台
      TypeList: ["全部", "CPU", "显卡", "内存条", "硬盘"], // 类型
      ConditionList: ["全部", "全新", "打折", "二手", "停售"], // 成色
      DataList: [
        // 模拟接口返回的数据结构
        {
          id: 1,
          Title: "i9 13900k",
          Platform: "淘宝",
          Type: "CPU",
          Condition: "全新",
        },
        {
          id: 2,
          Title: "i9 9900ks",
          Platform: "闲鱼",
          Type: "CPU",
          Condition: "停售",
        },
        {
          id: 3,
          Title: "i9 11900k",
          Platform: "天猫",
          Type: "CPU",
          Condition: "全新",
        },
        {
          id: 4,
          Title: "i5 13600k",
          Platform: "转转",
          Type: "CPU",
          Condition: "停售",
        },
        {
          id: 5,
          Title: "i5 10400f",
          Platform: "京东",
          Type: "CPU",
          Condition: "全新",
        },
        {
          id: 6,
          Title: "i5 10400f",
          Platform: "淘宝",
          Type: "CPU",
          Condition: "二手",
        },
        {
          id: 7,
          Title: "i3 12100f",
          Platform: "闲鱼",
          Type: "CPU",
          Condition: "打折",
        },
        {
          id: 8,
          Title: "i3 10105f",
          Platform: "淘宝",
          Type: "CPU",
          Condition: "二手",
        },
        {
          id: 9,
          Title: "i3 10100f",
          Platform: "京东",
          Type: "CPU",
          Condition: "全新",
        },
        {
          id: 10,
          Title: "Rtx 2060",
          Platform: "闲鱼",
          Type: "显卡",
          Condition: "二手",
        },
        {
          id: 11,
          Title: "Rtx 2070",
          Platform: "转转",
          Type: "显卡",
          Condition: "二手",
        },
        {
          id: 12,
          Title: "Rtx 2080",
          Platform: "淘宝",
          Type: "显卡",
          Condition: "打折",
        },
        {
          id: 13,
          Title: "Gtx 1060",
          Platform: "淘宝",
          Type: "显卡",
          Condition: "全新",
        },
        {
          id: 14,
          Title: "Gtx 1070",
          Platform: "闲鱼",
          Type: "显卡",
          Condition: "二手",
        },
        {
          id: 15,
          Title: "Gtx 1080",
          Platform: "京东",
          Type: "显卡",
          Condition: "全新",
        },
        {
          id: 16,
          Title: "Rtx 3060",
          Platform: "淘宝",
          Type: "显卡",
          Condition: "全新",
        },
        {
          id: 17,
          Title: "Rtx 3070",
          Platform: "淘宝",
          Type: "显卡",
          Condition: "全新",
        },
        {
          id: 18,
          Title: "Rtx 3090",
          Platform: "京东",
          Type: "显卡",
          Condition: "全新",
        },
        {
          id: 19,
          Title: "爱国者 ddr4 2666",
          Platform: "京东",
          Type: "内存条",
          Condition: "全新",
        },
        {
          id: 20,
          Title: "爱国者 ddr4 3000",
          Platform: "闲鱼",
          Type: "内存条",
          Condition: "二手",
        },
        {
          id: 21,
          Title: "爱国者 ddr4 3200",
          Platform: "淘宝",
          Type: "内存条",
          Condition: "打折",
        },
        {
          id: 22,
          Title: "英睿达 ddr4 2666",
          Platform: "淘宝",
          Type: "内存条",
          Condition: "停售",
        },
        {
          id: 23,
          Title: "英睿达 ddr4 3000",
          Platform: "闲鱼",
          Type: "内存条",
          Condition: "二手",
        },
        {
          id: 24,
          Title: "英睿达 ddr4 3200",
          Platform: "天猫",
          Type: "内存条",
          Condition: "全新",
        },
        {
          id: 25,
          Title: "金士顿 ddr4 2666",
          Platform: "淘宝",
          Type: "内存条",
          Condition: "停售",
        },
        {
          id: 26,
          Title: "金士顿 ddr4 3000",
          Platform: "闲鱼",
          Type: "内存条",
          Condition: "二手",
        },
        {
          id: 27,
          Title: "金士顿 ddr4 3200",
          Platform: "淘宝",
          Type: "内存条",
          Condition: "打折",
        },
        {
          id: 28,
          Title: "三星 512Gb",
          Platform: "天猫",
          Type: "硬盘",
          Condition: "全新",
        },
        {
          id: 29,
          Title: "三星 256Gb",
          Platform: "闲鱼",
          Type: "硬盘",
          Condition: "二手",
        },
        {
          id: 30,
          Title: "三星 128Gb",
          Platform: "淘宝",
          Type: "硬盘",
          Condition: "打折",
        },
        {
          id: 31,
          Title: "七彩虹 512Gb",
          Platform: "闲鱼",
          Type: "硬盘",
          Condition: "二手",
        },
        {
          id: 32,
          Title: "七彩虹 256Gb",
          Platform: "京东",
          Type: "硬盘",
          Condition: "全新",
        },
        {
          id: 33,
          Title: "七彩虹 128Gb",
          Platform: "淘宝",
          Type: "硬盘",
          Condition: "全新",
        },
        {
          id: 34,
          Title: "东芝 512Gb",
          Platform: "转转",
          Type: "硬盘",
          Condition: "全新",
        },
        {
          id: 35,
          Title: "东芝 256Gb",
          Platform: "淘宝",
          Type: "硬盘",
          Condition: "二手",
        },
        {
          id: 36,
          Title: "东芝 128Gb",
          Platform: "京东",
          Type: "硬盘",
          Condition: "停售",
        },
      ],
    };
  },
  computed: {
    ComDataList() {
      return this.DataList.filter((item) => {
        // console.log(
        //   this.PlatformVar,
        //   this.TypeVar,
        //   this.ConditionVar,
        //   item,
        //   item.Platform
        // );
        if (
          (this.PlatformVar == "全部" || this.PlatformVar == item.Platform) &&
          (this.TypeVar == "全部" || this.TypeVar == item.Type) &&
          (this.ConditionVar == "全部" || this.ConditionVar == item.Condition)
        ) {
          return item; // 把符合条件的数据返回,模板中通过this.ComDataList可直接动态读取数据
        }
      });
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style>
* {
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}
.container {
  margin: 100px auto;
  width: 700px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.CationBox {
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.row {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.row > div {
  flex-shrink: 0;
}
.row .content {
  flex: 1;
  display: flex;
  align-items: center;
}
.row .content .item {
  border-radius: 3px;
  color: #666;
  padding: 5px 12px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
}
.row .content .actvCss {
  background: #39b8ff;
  color: #fff;
}
.ContentBox {
  width: 100%;
  height: 450px;
  overflow: auto;
  padding: 10px;
}
.DataListBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.DataListBox .item {
  padding: 2px 4px;
  flex-shrink: 0;
  width: 19%;
  height: 60px;
  color: #666;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 1.25%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.DataListBox .item div {
  font-size: 12px;
}
.DataListBox .item:nth-child(5n) {
  /* 需要一行显示几个商品,nth-child(5n)里的数字就设置成几n */
  margin-right: 0px;
}
.nullCss {
  margin: 60px auto;
  font-size: 14px;
  text-align: center;
  color: #999;
}
</style>

<!-- <template>
  <div>
    <dl v-for="(listItem, listIndex) in listData" :key="listItem.id">
      <div class="flex-box">
        <dt>
          {{ listItem.title }}
        </dt>
        <dd
          v-for="(minItem, minIndex) in listItem.list"
          :key="minItem"
          :class="minIndex == listItem.index ? 'active' : ''"
          @click="handClick(minItem, listIndex, listItem, minIndex)"
        >
          {{ minItem }}
        </dd>
      </div>
    </dl>
    <div class="list-box">
      <h4>你选择的属性:</h4>
      <p v-if="!Object.keys(chooseList).length">暂无选择</p>
      <p class="chooseItem" v-for="(value, key) in chooseList" :key="key">
        {{ value }}<span @click="handleDel(key)">x</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: [
        {
          title: "品牌",
          id: 1,
          list: ["美的", "海尔", "格力", "三星", "惠普", "长虹"],
        },
        {
          title: "价格",
          id: 2,
          list: [
            "2000-3000",
            "2100-2500",
            "3500-6000",
            "1000-2000",
            "1500-4000",
            "6000-7000",
          ],
        },
        {
          title: "制冷面积",
          id: 3,
          list: [
            "120平方米",
            "130平方米",
            "140平方米",
            "150平方米",
            "160平方米",
            "170平方米",
          ],
        },
      ],
      chooseList: {
        // 0:'美的',
        //1:'2000-300'，
      },
    };
  },
  methods: {
    handClick(minItem, listIndex, listItem, minIndex) {
      listItem.index = minIndex;
      console.log(minItem, listIndex, listItem);
      this.$set(this.chooseList, listIndex, minItem);
    },
    handleDel(key) {
      console.log(key);
      this.$delete(this.chooseList, key);
      this.listData[key].index = null;
      console.log(this.listData);
    },
  },
};
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
}
dl,
dd,
dt {
  cursor: pointer;
}
dl {
  border-bottom: 1px dashed #ccc;
}
dd {
  margin-left: 10px;
}
dt {
  background: gray;
  padding: 4px 10px;
  color: #fff;
}
.list-box {
  display: flex;
  align-items: center;
  h4 {
    margin-right: 15px;
  }
  p {
    color: #ccc;
    margin-right: 10px;
  }
  .chooseItem {
    border: 1px solid red;
    padding: 4px 10px;
    color: red;
    span {
      padding-left: 10px;
      cursor: pointer;
    }
  }
}
.active {
  color: red;
}
</style> -->
