<template>
  <div
    class="box"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div>
      <div class="navigation" ref="navBox">
        <img class="logo" src="../img/home/logo1.png" alt="" />
        <el-menu
          :default-active="activeIndex2"
          mode="horizontal"
          @select="handleSelect"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">二手房</el-menu-item>
          <el-menu-item index="3">新房</el-menu-item>
          <el-menu-item index="4">租房</el-menu-item>
          <el-menu-item index="5">海外房产</el-menu-item>
          <el-menu-item index="6">查楼盘</el-menu-item>
          <el-menu-item index="7">我要卖房</el-menu-item>
          <el-menu-item index="8">我要找房</el-menu-item>
          <el-menu-item index="9">企业介绍</el-menu-item>
        </el-menu>
      </div>
      <div class="typeBox">
        <span class="typeBtn">全城找房</span>
        <el-card class="box-card">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item prop="name">
              <span>您的姓名</span>
              <el-input
                placeholder="请输入您的姓名"
                v-model="ruleForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item prop="sex">
              <span style="width: 80px">性别</span>
              <el-select v-model="ruleForm.sex" placeholder="请选择您的性别">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="phone" class="codeBox">
              <span>手机号</span>
              <el-input placeholder="手机号" v-model="ruleForm.phone">
              </el-input>
              <span
                style="cursor: pointer"
                @click.capture="getCode"
                v-if="show"
                class="codeBtn"
                >获取验证码</span
              >
              <span class="codeBtn" v-if="!show">{{ count }}s后获取</span>
            </el-form-item>
            <el-form-item prop="code">
              <span>验证码</span>
              <el-input
                placeholder="请输入验证码"
                v-model="ruleForm.code"
              ></el-input>
            </el-form-item>
            <el-form-item prop="type">
              <span style="width: 80px">需求类型</span>
              <el-select
                v-model="ruleForm.type"
                placeholder="请选择您的需求类型"
              >
                <el-option label="购房 — 二手房" value="1"></el-option>
                <el-option label="购房 — 新房" value="2"></el-option>
                <el-option label="租房" value="3"></el-option>
                <el-option label="海外房产" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="region">
              <span>意向区域</span>
              <el-input
                placeholder="请输入您的意向区域"
                v-model="ruleForm.region"
              ></el-input>
            </el-form-item>
            <el-form-item prop="village">
              <span>意向小区</span>
              <el-input
                placeholder="请输入您的意向小区"
                v-model="ruleForm.village"
              ></el-input>
            </el-form-item>
            <el-form-item prop="area">
              <span>意向面积</span>
              <el-input
                placeholder="请输入您的意向面积"
                v-model="ruleForm.area"
              ></el-input>
            </el-form-item>
            <el-form-item prop="houseType">
              <span>意向房型</span>
              <el-input
                placeholder="请输入您的意向房型"
                v-model="ruleForm.houseType"
              ></el-input>
            </el-form-item>
            <el-form-item prop="price">
              <span>预算价格</span>
              <el-input
                placeholder="请输入您的预算价格"
                v-model="ruleForm.price"
              ></el-input>
            </el-form-item>
            <div class="submitBtn" @click="getSubmit('ruleForm')">提交</div>
          </el-form>
        </el-card>
      </div>
    </div>
    <div class="agentBox">
      <div class="itemBox" v-for="(item, index) in agentList" :key="index">
        <img class="icon" :src="item.icon" alt="" />
        <div class="descBox">
          <p class="name">
            {{ item.name }} <span>{{ item.count }}分</span>
          </p>
          <p class="department">{{ item.departmentName }}</p>
          <div class="label">
            <span class="leftbtn">{{ item.job }}</span>
            <span
              class="code"
              @mouseenter="getContact(index)"
              @mouseleave="getLeave()"
              >扫码联系 <img class="codeImg" src="../img/home/code.png" alt=""
            /></span>
          </div>
          <div
            class="contactCode"
            :class="{ activePhone: index == currentIndex }"
          >
            <img :src="item.wxHomeImg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import js from "@/utils/utils";
import Footer from "@/components/Footer.vue";
import { getDemandCode, getHome } from "../api/api";
export default {
  name: "",
  components: { Footer },
  data() {
    return {
      activeIndex2: "8",
      loading: false,
      agentList: [],
      ruleForm: {
        name: "",
        sex: "",
        phone: "",
        code: "",
        type: "",
        region: "",
        area: "",
        village: "",
        houseType: "",
        price: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        code: [{ required: true, message: "请输入校验码", trigger: "blur" }],
        type: [{ required: true, message: "请选择需求类型", trigger: "blur" }],
        region: [
          { required: true, message: "请选择意向区域", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请输入您的意向面积", trigger: "blur" },
        ],
        village: [
          { required: true, message: "请输入您的意向小区", trigger: "blur" },
        ],
        houseType: [
          { required: true, message: "请输入您的意向房型", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入您的预算价格", trigger: "blur" },
        ],
      },
      show: true,
      timer: null,
      count: "",
      scrollTop: "",
      currentIndex: -1,
    };
  },
  mounted() {
    window.document.body.onscroll = () => {
      this.scrollTop = document.documentElement.scrollTop;
      if (document.documentElement.scrollTop < 100) {
        this.$refs.navBox.style.backgroundColor = "transparent";
      } else {
        this.$refs.navBox.style.backgroundColor = "#3d3333";
      }
    };
    this.agentList = JSON.parse(localStorage.getItem("agentList"));
  },
  methods: {
    getContact(index) {
      this.currentIndex = index;
    },
    getLeave() {
      this.currentIndex = -1;
    },
    testPhone() {
      var phone = $("#phone").val();
      if (!regex.test(phone)) {
        alert("请录入正确的手机号码！");
        $("#phone").focus();
        return false;
      }
    },
    getCode() {
      var regex =
        /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/;
      if (!regex.test(this.ruleForm.phone)) {
        this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
      } else {
        getDemandCode({ phone: this.ruleForm.phone }).then((res) => {
          // console.log(res);
          if (res.success == true) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      }
    },
    getSubmit(formName) {
      // console.log(this.ruleForm);
      // 校验表单
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交表单
          getHome(this.ruleForm).then((res) => {
            // console.log(res);
            if (res.success == true) {
              this.ruleForm = "";
              this.show = true;
              this.$alert("提交成功，请留意我司经纪人来电", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleSelect(e) {
      if (e == "1") {
        this.$router.push("/Home");
      } else if (e == "2") {
        this.$router.push("/SecondhandHouse");
      } else if (e == "3") {
        this.$router.push("/NewHouse");
      } else if (e == "4") {
        this.$router.push("/RentHouse");
      } else if (e == "5") {
        this.$router.push("/OverseasHouse");
      } else if (e == "6") {
        this.$router.push("/SearchFloortray");
      } else if (e == "7") {
        this.$router.push("/SellHouse");
      } else if (e == "8") {
        this.$router.push("/BuyHouse");
      } else if (e == "9") {
        this.$router.push("/CompanyProfile");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.navigation {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  box-sizing: border-box;
  .el-menu.el-menu--horizontal {
    // border-bottom: none;
  }
  .el-menu:hover .el-menu-item {
    background-color: transparent !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // border-bottom: none;
    // text-decoration: none;
  }
  .logo {
    width: 96px;
    height: 32px;
    margin-right: 30px;
  }
  .el-menu {
    background: none;
  }
}
.activated {
  color: #c7c3be;
  font-weight: 500;
}
.typeBox {
  padding-top: 130px;
  padding-bottom: 80px;
  background: url("../img/buyimg.png") no-repeat center;
  background-size: 100% 100%;
  .typeBtn {
    display: inline-block;
    margin-left: 18%;
    width: 88px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #ffffff;
    border-radius: 20px;
    margin-right: 8px;
    color: #da9525;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .box-card {
    width: 560px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e9b869;
    padding: 40px;
    margin: 0 auto;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .titleTop {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
      }
      .titleBom {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    /deep/.el-form-item__content {
      margin-left: 0 !important;
      display: flex;
      span {
        display: block;
        width: 100px;
      }
    }
    /deep/.el-input_inner {
      border: 0 none;
    }
    /deep/.el-input__inner {
      width: 312px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .codeBox {
      position: relative;
      .codeBtn {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #da9525;
        position: absolute;
        right: 32px;
      }
    }
    .submitBtn {
      width: 312px;
      height: 40px;
      line-height: 40px;
      background: #da9525;
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin: auto;
      cursor: pointer;
    }
  }
}
.agentBox {
  background-color: #fff;
  padding: 90px 154px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 36px;
  box-sizing: border-box;
  .itemBox {
    width: 360px;
    height: 146px;
    display: flex;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #f9fafe;
    .icon {
      width: 130px;
      height: 130px;
      margin-right: 16px;
    }
    .descBox {
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-bottom: 16px;
        span {
          font-weight: 400;
          color: #e9ac33;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .department {
        padding-bottom: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      .label {
        display: flex;
        align-items: center;
        .leftbtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          background: rgb(245, 226, 189);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e9ac33;
          margin-right: 10px;
          padding-left: 7px;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .code {
          display: inline-block;
          width: 88px;
          height: 32px;
          background: #f5f7fa;
          border-radius: 2px;
          border: 1px solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          cursor: pointer;
          .codeImg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .contactCode {
        border-radius: 1px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: 5px;
        top: -50px;
        display: none;
        .el-icon-close {
          float: right;
        }
        img {
          width: 130px;
          height: 130px;
        }
      }
      .activePhone {
        display: block;
      }
    }
  }
}
</style>
