import { post, get, upload } from '@/utils/request'
// import { getToken } from '@/utils/auth'
// import config from '@/config/config'

// 首页底部数据
export const getHomeInfo = data => {
  return post('/home/usedhouse/getHomeInfo', data)
}
// 区域查询
export const getAreaInfo = data => {
  return post('/home/area/getAreaInfo', data)
}
// 二手房搜索联想词
export const searchSellThinkItems = data => {
  return post('/home/usedhouse/searchSellThinkItems', data)
}
// 查询二手房列表
export const getSellPager = data => {
  return post('/home/usedhouse/getSellPager', data)
}
// 查询二手房详情
export const SecondhandHouseDetails = data => {
  return post('/home/usedhouse/loadSell', data)
}
// 租房搜索联想词
export const searchRentThinkItems = data => {
  return post('/home/usedhouse/searchRentThinkItems', data)
}
// 查询租房列表
export const getRentPager = data => {
  return post('/home/usedhouse/getRentPager', data)
}
// 查询租房详情
export const getLoadRent = data => {
  return post('/home/usedhouse/loadRent', data)
}
// 新房搜索联想词
export const searchThinkItems = data => {
  return post('/home/newhouse/searchThinkItems', data)
}
// 查询新房列表
export const getPager = data => {
  return post('/home/newhouse/getPager', data)
}
// 查询新房详情
export const getLoadNewhouse = data => {
  return post('/home/newhouse/load', data)
}
// 海外房产搜索联想词
export const searchOverItems = data => {
  return post('/home/overseahouse/searchThinkItems', data)
}
// 查询海外房产列表
export const getOverseahouse = data => {
  return post('/home/overseahouse/getPager', data)
}
// 查询海外房产详情
export const getLoadOverhouse = data => {
  return post('/home/overseahouse/load', data)
}
// 查询海外房产户型图
export const getOverModelList = data => {
  return post('/home/overseahousemodel/getCommunityModelList', data)
}
// 查询企业介绍-门店列表
export const getList = data => {
  return post('/home/subordinate/getList', data)
}
// 企业介绍-招聘列表
export const getPositionList = data => {
  return post('/home/recruit/getList', data)
}
// 企业介绍-8企业荣耀 9员工风采
export const getPictureList = data => {
  return post('/home/banner/getPictureList', data)
}
// 发送我要找房验证码
export const getDemandCode = data => {
  return post('/home/demand/getDemandCode', data)
}
// 我要找房
export const getHome = data => {
  return post('/home/demand/add', data)
}
// 发送我要卖房验证码
export const getSellCode = data => {
  return post('/home/delegation/getDelegationCode', data)
}
// 我要卖房
export const getSellHome = data => {
  return post('/home/delegation/add', data)
}
// 获取小区列表
export const getCommunityPager = data => {
  return post('/home/usedhousecommunity/getCommunityPager', data)
}
// 小区搜索联想词
export const getsearchThinkItems = data => {
  return post('/home/usedhousecommunity/searchThinkItems', data)
}
// 小区详情
export const getHousecommunity = data => {
  return post('/home/usedhousecommunity/load', data)
}
// 小区详情户型图
export const getCommunityModelList = data => {
  return post('/home/usedhousecommunitymodel/getCommunityModelList', data)
}


export const ServeGetArticleDetail = data => {
  return get('/api/v1/note/article/detail', data)
}

// 下载笔记附件服务接口
export const ServeDownloadAnnex = annex_id => {
  let api = config.BASE_API_URL
  try {
    let link = document.createElement('a')
    link.target = "_blank"
    link.href = `${api}/api/v1/note/annex/download?annex_id=${annex_id}&token=${getToken()}`
    link.click()
  } catch (e) {
    console.error(e)
  }
}

