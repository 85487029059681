<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- <router-view /> -->
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    this.resizeFun();
  },
  mounted() {
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是hashchange事件
    window.addEventListener(
      "hashchange",
      () => {
        let currentPath = window.location.hash.slice(1);
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath);
        }
      },
      false
    );
  },
  // 路由拦截
  beforeRouteEnter(to, from, next) {
    console.log(from.path);
  },
  watch: {
    // 浏览器返回按钮的监听
    $route(to, from) {
      if (to.name === "SecondhandHouse" && from.name == "SecondHouseDetails") {
        this.$router.go(0);
      }
      if (to.name === "NewHouse" && from.name == "NewHouseDetails") {
        this.$router.go(0);
      }
      if (to.name === "RentHouse" && from.name == "RentHouseDetails") {
        this.$router.go(0);
      }
      if (to.name === "OverseasHouse" && from.name == "OverSeaHouseDetails") {
        this.$router.go(0);
      }
      if (to.name === "CommunityDetails" && from.name == "SecondHouseDetails") {
        this.$router.go(0);
      }
    },
  },
  methods: {
    resizeFun() {
      let docEle = document.documentElement,
        resizeEvt =
          "orientationchange" in window ? "orientationchange" : "resize",
        resizeFun = () => {
          let clientWidth = docEle.clientWidth;
          if (!clientWidth) return;
          // 100是换算系数，相当于1rem=100px
          //1920是作为设计图的基准，我这是按照1920*1080的分辨率，可自行修改
          docEle.style.fontSize = 100 * (clientWidth / 1440) + "px";
        };
      if (!document.addEventListener) return;
      window.addEventListener(resizeEvt, resizeFun, false);
      window.addEventListener("DOMContentLoaded", resizeFun, false);
      resizeFun();
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* margin-top: 60px; */
}
* {
  padding: 0;
  margin: 0;
}
</style>
